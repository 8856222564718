import {
  Params,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from "@angular/router";
import * as fromFiles from "../../modules/file/store/file.reducers";
import * as fromDataset from "../../modules/dataset/store/dataset.reducers";
import * as fromRouter from "@ngrx/router-store/";
import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";

export interface AppState {
  files: fromFiles.State;
}

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
}

export interface RouteState {
  routerReducer: fromRouter.RouterReducerState<RouterStateUrl>;
}
/** Feature Reducers Import */
export const reducers: ActionReducerMap<AppState> = {
  files: fromFiles.reducer
};

/** Router Reducers Import */

export const storeReducers: ActionReducerMap<RouteState> = {
  routerReducer: fromRouter.routerReducer
};

export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<RouterStateUrl>
>("routerReducer");

export class CustomSerializer
  implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params } = state;

    return { url, queryParams, params };
  }
}
