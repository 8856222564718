<h3 matdialogtitle>{{data.title}}</h3>
<mat-dialog-content class="mt-3">
  <div *ngIf="this.data.errors && this.data.errors.length > 0" class="alert alert-danger" role="danger">
    <h4 class="alert-heading">Error</h4>
    <ul *ngFor="let error of this.data.errors">
      <li>{{error}}</li>
    </ul>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="close()" class="mat-raised-button mr-3 close-button" matdialogclose="cancel">
    <em class="fa fa-close"></em> Close
  </button>
  <button class="btn-success" (click)="close()" [routerLink]=this.data.redirectRoute mat-button><em
      class="fa fa-warning"></em> Solve</button>
</mat-dialog-actions>
