import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
    DynamicFormControlComponent,
    DynamicFormControlCustomEvent,
    DynamicFormLayout,
    DynamicFormLayoutService,
    DynamicFormValidationService,
    DynamicSelectModel,
    DynamicFormControlLayout,
} from "@ng-dynamic-forms/core";
import { DynamicMaterialSelectComponent } from '@ng-dynamic-forms/ui-material';
import { DynamicSelectFromTableModel } from './select-from-table-model';

@Component({
  selector: 'app-select-from-table-form-control',
  templateUrl: './select-from-table-form-control.component.html',
  styleUrls: ['./select-from-table-form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFromTableFormControlComponent extends DynamicFormControlComponent {
  @Input() group: FormGroup;
  @Input() layout: DynamicFormControlLayout;
  @Input() model: DynamicSelectFromTableModel<string>;

  @Output() blur: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
  @Output() focus: EventEmitter<any> = new EventEmitter();

  constructor(protected layoutService: DynamicFormLayoutService,
              protected validationService: DynamicFormValidationService) {

      super(layoutService, validationService);
  }

}
