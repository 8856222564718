import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FileComponent } from "./file.component";
import { InspectComponent } from "./components/inspect/inspect.component";
import { FilesGuard } from "../../shared/guards/files.guard";
import { RemoveComponent } from "./components/remove/remove.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [FilesGuard],
    data: {
      title: "Files"
    },
    children: [
      {
        path: "",
        component: FileComponent,
        data: {
          title: ""
        }
      },
      {
        path: "remove/:uuid",
        component: RemoveComponent,
        data: {
          title: "Remove"
        }
      },
      {
        path: "inspect/:uuid/:type",
        canActivate: [FilesGuard],
        component: InspectComponent,
        data: {
          title: "Inspect"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FileRoutingModule {}
