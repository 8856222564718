/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./confirm-dialog.component";
var styles_ConfirmDialogComponent = [];
var RenderType_ConfirmDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
function View_ConfirmDialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_ConfirmDialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "alert alert-warning"], ["role", "alert"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "alert-heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Attention"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_2)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.warnings; _ck(_v, 4, 0, currVal_0); }, null); }
function View_ConfirmDialogComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_ConfirmDialogComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "alert alert-danger"], ["role", "danger"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h4", [["class", "alert-heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Error"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_4)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.errors; _ck(_v, 4, 0, currVal_0); }, null); }
function View_ConfirmDialogComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.text; _ck(_v, 2, 0, currVal_0); }); }
export function View_ConfirmDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [["matdialogtitle", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), (_l()(), i0.ɵeld(2, 0, null, null, 7, "mat-dialog-content", [["class", "mt-3 mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(3, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(10, 0, null, null, 8, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(11, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(12, 0, null, null, 2, "button", [["color", "primary"], ["mat-button", ""], ["matdialogclose", "cancel"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(13, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i0.ɵted(14, 0, ["", ""])), (_l()(), i0.ɵeld(15, 0, null, null, 3, "button", [["cdkfocusinitial", ""], ["color", "warn"], ["mat-button", ""], ["matdialogclose", "confirm"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 17).dialogRef.close(i0.ɵnov(_v, 17).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i0.ɵdid(16, 180224, null, 0, i4.MatButton, [i0.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), i0.ɵdid(17, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i0.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(18, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.data.warnings && (_co.data.warnings.length > 0)); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.data.errors && (_co.data.errors.length > 0)); _ck(_v, 7, 0, currVal_2); var currVal_3 = (!_co.data.errors || (_co.data.errors && (_co.data.errors.length === 0))); _ck(_v, 9, 0, currVal_3); var currVal_6 = "primary"; _ck(_v, 13, 0, currVal_6); var currVal_12 = (_co.data.errors && (_co.data.errors.length > 0)); var currVal_13 = "warn"; _ck(_v, 16, 0, currVal_12, currVal_13); var currVal_14 = true; _ck(_v, 17, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 1, 0, currVal_0); var currVal_4 = (i0.ɵnov(_v, 13).disabled || null); var currVal_5 = (i0.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_4, currVal_5); var currVal_7 = _co.data.btnCancel; _ck(_v, 14, 0, currVal_7); var currVal_8 = (i0.ɵnov(_v, 16).disabled || null); var currVal_9 = (i0.ɵnov(_v, 16)._animationMode === "NoopAnimations"); var currVal_10 = (i0.ɵnov(_v, 17).ariaLabel || null); var currVal_11 = i0.ɵnov(_v, 17).type; _ck(_v, 15, 0, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_15 = _co.data.btnOk; _ck(_v, 18, 0, currVal_15); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i0.ɵdid(1, 49152, null, 0, i7.ConfirmDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var ConfirmDialogComponentNgFactory = i0.ɵccf("app-confirm-dialog", i7.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
