<h3 matdialogtitle>{{data.title}}</h3>
<mat-dialog-content class="mt-3">
  <div *ngIf="this.data.warnings && this.data.warnings.length > 0" class="alert alert-warning" role="alert">
    <h4 class="alert-heading">Attention</h4>
    <ul *ngFor="let warning of this.data.warnings">
      <li>{{warning}}</li>
    </ul>
  </div>
  <div *ngIf="this.data.errors && this.data.errors.length > 0" class="alert alert-danger" role="danger">
    <h4 class="alert-heading">Error</h4>
    <ul *ngFor="let error of this.data.errors">
      <li>{{error}}</li>
    </ul>
  </div>
  <div *ngIf="!this.data.errors || (this.data.errors && this.data.errors.length === 0)">
    <p>{{data.text}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="onNoClick()" color="primary" mat-button matdialogclose="cancel">{{data.btnCancel}}</button>
  <button [disabled]="this.data.errors && this.data.errors.length > 0" [mat-dialog-close]="true" cdkfocusinitial
    color="warn" mat-button matdialogclose="confirm">{{data.btnOk}}</button>
</mat-dialog-actions>
