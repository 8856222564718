import { FormGroup, FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { Component, OnInit, ViewChild, Input, OnDestroy } from "@angular/core";
import { UploadService } from "./upload.service";
import { Utils } from "../../../../shared/utils";
import * as FileActions from "../../store/file.actions";
import { File, FileType } from "../../../../shared/models/file.model";
import { importedStates } from "../../../../shared/models/common";
import * as fromFiles from "../../store/file.reducers";
import { UploadStatus } from "../../../../shared/models/upload-status.model";
import { FileTemplateComponent } from "../file-template/file-template.component";
import { FileTemplateAdapter } from "../../../../shared/models/file-template.model";
import { FileService } from "../../shared/file.service";
import { HttpEventType } from '@angular/common/http';
import { IAPIFile } from '../../../../shared/models/API.model';
import { first } from 'rxjs/operators';

/**
 * Loads a Mat Stepper that allows a to upload a file for a specific sub category
 *
 * @export
 * @class UploadComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: "app-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"]
})
export class UploadComponent implements OnInit, OnDestroy {
  // to be used on the html template titles
  @Input() title: string;

  // defines the upload type (parameter, input)
  @Input() category: string;

  @ViewChild("inputDialog", { static: true }) fileInput;
  @ViewChild("uploadedFileName", { static: true }) uploadedFileName;
  @ViewChild("stepper", { static: true }) stepper: MatStepper;

  // subscriptions
  private upload$: Subscription;

  isLinear = false;
  step1Fgrp: FormGroup;
  step2Fgrp: FormGroup;
  subCategory: FormControl;

  // type starts as File and changes to especific on init
  newUpload: File;

  inputTypes: FileType[];
  parameterTypes: FileType[];

  /**
   * Creates an instance of UploadComponent.
   * @param {ToastrService} toastr
   * @param {UploadService} uploadService
   * @param {FileService} fileService
   * @param {Store<fromApp.AppState>} store
   * @param {MatDialog} dialog
   * @memberof UploadComponent
   */
  constructor(
    private toastr: ToastrService,
    private uploadService: UploadService,
    private fileService: FileService,
    private store: Store<fromFiles.State>,
    public dialog: MatDialog
  ) {
    this.upload$ = new Subscription();
  }

  /**
   *
   * @memberof UploadComponent
   */
  ngOnInit() {
    this.subCategory = new FormControl();
    this.fileService
      .getFileTypesByCategory("input")
      .subscribe(types => (this.inputTypes = types));
    this.fileService
      .getFileTypesByCategory("parameter")
      .subscribe(types => (this.parameterTypes = types));
  }

  /**
   * Triggered when a file is Uploaded
   *
   * @param {*} event
   * @param {MatStepper} stepper
   * @returns
   * @memberof UploadComponent
   */
  onFilesAdded(event, stepper: MatStepper) {
    if (!event.addedFiles) {
      return;
    }

    event.addedFiles.forEach(file => {
      const reader = new FileReader();
      try {
        reader.readAsDataURL(file);
        this.create(file, file.name, stepper);
      } catch (e) {
        this.toastr.error(
          "There was an error while trying to import the file. Please try again",
          "File Import"
        );
        stepper.reset();
        console.log(e); /** TODO: to be logged to an API */
        return;
      }
    });
    event.addedFiles = null;

    return;
  }

  /**
   * Uploads the new File and Adds it to the File List (provisory item)
   *
   * @param {(any | ArrayBuffer)} file
   * @param {string} filename
   * @param {MatStepper} stepper
   * @returns {File}
   * @memberof UploadComponent
   */
  create(file: any | ArrayBuffer, filename: string, stepper: MatStepper) {
    const category = this.subCategory.value[0];
    const subCategory = this.subCategory.value[1];

    this.uploadService.create(category, subCategory, filename).pipe(first()).subscribe(res => {
      if (res.success) {
        this.store.dispatch(FileActions.fetchFiles());
        this.uploadService.upload(res.uuid, file).subscribe(evt => {
          if (evt.type === HttpEventType.Response) {
            this.store.dispatch(FileActions.fetchFiles());
          }
        })
      }
    });

    stepper.reset();
  }

  /**
   * Loads the File Template info and calls the Dialog
   *
   * @param {string} category
   * @param {string} subCategorySlug
   * @param {string} subcategoryStr
   * @memberof UploadComponent
   */
  openTemplateDialog(
    category: string,
    subCategorySlug: string,
    subcategoryStr: string
  ) {
    this.uploadService
      .getSubCategoryMetadata(category, subCategorySlug)
      .subscribe(data => {
        const fileTemplateAdapter = new FileTemplateAdapter();

        this.openFileTemplateDialog(
          fileTemplateAdapter.adapt(data),
          category,
          subcategoryStr
        );
      });
  }

  /**
   * Opens the file template dialog
   *
   * @param {*} templateMetadata
   * @param {*} category
   * @param {*} subcategoryStr
   * @memberof UploadComponent
   */
  openFileTemplateDialog(templateMetadata, category, subcategoryStr) {
    this.dialog.open(FileTemplateComponent, {
      width: "1000px",
      data: {
        metadata: templateMetadata,
        category: category,
        subCategory: subcategoryStr
      }
    });
  }

  /**
   * Restarts the import Process and triggers the toastr
   */
  resetImportProcess() {
    this.stepper.reset();
    this.fileInput.nativeElement.value = "";
  }

  ngOnDestroy() {
    this.upload$.unsubscribe();
  }
}
