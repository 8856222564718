import { map, tap, switchMap, first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Observable, of } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import * as fromApp from "../../../../app/modules/calculation/store/calculation.reducers";
import * as CalculationActions from "../../../modules/calculation/store/calculation.actions";
import {
  Calculation,
  Result,
  CreditRiskResultAdapter
} from "../../../shared/models/calculation.model";
import {
  IAPIDetailMetadata,
  ICalculationAPIResult,
  IAPIReport,
  IAPIResponse
} from "../../../shared/models/API.model";

/**
 *
 * @export
 * @class CalculationService
 */
@Injectable({
  providedIn: "root"
})
export class CalculationService {
  // calculation: BehaviorSubject<Calculation[]>;
  currentCalculation: Calculation;

  /**
   *Creates an instance of CalculationService.
   * @param {HttpClient} http
   * @param {CalculationResultAdapter} calculationResultAdapter
   * @param {Store<fromApp.State>} store
   * @param {ToastrService} toastr
   * @memberof CalculationService
   */
  constructor(
    private http: HttpClient,
    private calculationResultAdapter: CreditRiskResultAdapter,
    private store: Store<fromApp.State>,
    private toastr: ToastrService
  ) {
    // this.calculation = new BehaviorSubject([]);  // DIE!!
  }

  /**
   * Returns an Observable containing the Calculation List
   *
   * @returns {Observable<fromApp.State>}
   * @memberof CalculationService
   */
  getCalculationList(params: HttpParams): Observable<any> {
    return this.http
    .get<any>(`${environment.api_route}/analyses/ls`, {
      params: params,
      withCredentials: true
    })
  }

    /**
   * Returns an Observable containing the Calculation Table Description
   *
   * @returns {Observable<fromApp.State>}
   * @memberof CalculationService
   */
  getCalculationTableDescription(cal_uuid:string, table_uuid: string, columns: string[]): Observable<any> {

    let params = new HttpParams();
    columns.forEach(c => params = params.append("columns", c))

    return this.http
    .get<any>(`${environment.api_route}/analyses/schema/table/${cal_uuid}/${table_uuid}`, {
      withCredentials: true,
      params
    })
  }

  /**
   * Returns a Calculation, containing the filtered Result by BL
   *
   * @param {Calculation} calculation
   * @param {string[]} blFilters
   * @returns {Calculation}
   * @memberof CalculationService
   */
  getCalculationByBL(
    uuid: string,
    blFilters: string[]
  ): Observable<ICalculationAPIResult> {
    let params = new HttpParams();

    // add business mapping filters to httpparams
    blFilters.forEach(
      filter => (params = params.append("business_line", filter))
    );

    return this.http
      .get<ICalculationAPIResult>(`${environment.api_route}analyses/dashboard/${uuid}`, {
        params: params,
        withCredentials: true
      });
  }

  /**
   * Returns an Observable containing the Calculation Metadata
   *
   * @returns {Observable<IAPIDetailMetadata>}
   * @memberof CalculationService
   */
  getCalculationDetailMetadata(): Observable<IAPIDetailMetadata> {
    return this.http
      .get<IAPIDetailMetadata>(`${environment.api_route}utils/profiles`, {
        withCredentials: true
      })
      .pipe(
        map(response =>
          // set the meta tab info naming
          {
            return {
              detailMetaNames: Object.keys(response["res"]),
              originalNames: response["res"]
            };
          }
        )
      );
  }

  /**
   * Returns a Calculation from the store, given a specific UUID
   *
   * @param {string} uuid
   * @returns {Observable<Calculation>}
   * @memberof CalculationService
   */
  getCalculationByUUID(uuid: string): Observable<any> {
    // const params = new HttpParams().append("filter_by", `uuid:=:'${uuid}'`)
    return this.http
    .get<IAPIResponse>(`${environment.api_route}analyses/dashboard/${uuid}`, {
      withCredentials: true
    }).pipe(
      first()
    )
  }

  /**
   * Returns an Observable for a specific Calculation, given a uuid
   *
   * @param {string} uuid
   * @param {boolean} useRAW
   * @returns {Observable<Result>}
   * @memberof CalculationService
   */
  getCalculationResultByUUID(
    uuid: string,
    useRAW: boolean
  ): Observable<Result> {
    let params = new HttpParams().set("uuid", uuid);

    if (!useRAW) {
      params = params.set("raw", "no");
    }

    return this.http
      .get<ICalculationAPIResult>(`${environment.api_route}analysis/dashboard/${uuid}`, {
        params: params,
        withCredentials: true
      })
      .pipe(map(data => this.calculationResultAdapter.adapt(data, useRAW)));
  }

  /**
   * Returns an Observable for a specific Report, given a uuid
   *
   * @param {string} uuid
   * @returns {Observable<IAPIReport>}
   * @memberof CalculationService
   */
  getCalculationReportByUUID(uuid: string): Observable<IAPIReport> {
    let params = new HttpParams().set("res_uuid", uuid);

    return this.http.get<IAPIReport>(`${environment.api_route}uploads/report`, {
      params: params,
      withCredentials: true
    });
  }

  /**
   * Returns the Calculation current state from the Store
   *
   * @returns
   * @memberof CalculationService
   */
  getCalculationCurrentState() {
    return this.store.pipe(select(fromApp.getCurrentState));
  }

  /**
   * Triggers the Calculation List update from the store
   *
   * @memberof CalculationService
   */
  updateCalculationList() {
    this.store.dispatch(CalculationActions.fecthCalculation());
  }

  currentState(): Observable<fromApp.State> {
    return this.store.select(fromApp.getCurrentState);
  }

  /**
   * Removes a specific Calculation by a given UUID, then updates the current list
   * and shows a Toastr message
   *
   * @param {string} uuid
   * @returns {Observable<string[]>}
   * @memberof CalculationService
   */
  removeCalculationByUUID(uuid: string): Observable<string[]> {
    const params = new HttpParams().set("uuid", uuid);
    return this.http
      .get<string[]>(`${environment.api_route}uploads/rm_result`, {
        withCredentials: true,
        params: params
      })
      .pipe(
        tap(data => {
          if (data["success"]) {
            // update file list
            this.updateCalculationList();

            // show success toastr
            this.toastr.success(
              "Calculation removed successfully.",
              "Calculation Remove"
            );
            return true;
          }

          // if not successful...error
          this.toastr.error(
            "There was an error while trying to delete the Calculation. Please try again",
            "Calculation Remove"
          );

          return false;
        })
      );
  }

  /**
   * Generates the Report for a specific Calculation
   *
   * @param {string} uuid
   * @memberof CalculationService
   */
  generateReport(uuid: string) {
    this.http
      .post<string[]>(
        `${environment.api_route}uploads/launch_report`,
        { res_uuid: uuid },
        { withCredentials: true }
      )
      .subscribe(data => {
        if (data["success"]) {
          // show success toastr
          this.toastr.success(
            "Report Generated successfully.",
            "Report Generate"
          );
          return true;
        }

        // if not successful...error
        this.toastr.error(
          "There was an error while trying to generate the Report. Please try again",
          "Report Generate"
        );

        return false;
      });
  }

  adaptResponse(res: ICalculationAPIResult) {
    return this.calculationResultAdapter.adapt(res, false);
  }

  getCalculationTable(cal_uuid: string, table_id: string, params: HttpParams) {
    return this.http.get(`${environment.api_route}analyses/inspect/table/${cal_uuid}/${table_id}`, {
      withCredentials: true,
      params: params
    })
  }

  downloadTable(cal_uuid: string, table_id: string, params: HttpParams) {
    return this.http.post(`${environment.api_route}analyses/download/table/${cal_uuid}/${table_id}`, {}, {
      withCredentials: true,
      params: params
    })
  }

}
