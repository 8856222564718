import * as tslib_1 from "tslib";
import * as DatasetActions from "./dataset.actions";
import { createReducer, on, createFeatureSelector, createSelector } from "@ngrx/store";
var initialState = {
    needsReload: false,
    loaded: false
};
var ɵ0 = function (state) { return (tslib_1.__assign({}, state, { needsReload: true, loaded: false })); }, ɵ1 = function (state, _a) {
    var datasets = _a.datasets;
    return (tslib_1.__assign({}, state, { needsReload: false, loaded: true }));
}, ɵ2 = function (state) { return (tslib_1.__assign({}, state, { datasets: undefined })); };
var datasetsReducer = createReducer(initialState, on(DatasetActions.fecthCols, ɵ0), on(DatasetActions.setCols, ɵ1), on(DatasetActions.clearCols, ɵ2));
export function reducer(state, action) {
    return datasetsReducer(state, action);
}
export var getDatasetsState = createFeatureSelector("datasets");
var ɵ3 = function (state) { return state; };
export var getCurrentState = createSelector(getDatasetsState, ɵ3);
export { ɵ0, ɵ1, ɵ2, ɵ3 };
