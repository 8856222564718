import * as tslib_1 from "tslib";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
var Calculation = /** @class */ (function () {
    function Calculation(uuid, started, finished, status, args, colName, colCreatedDate, countryCCY, reportId, result) {
        this.uuid = uuid;
        this.started = started;
        this.finished = finished;
        this.status = status;
        this.args = args;
        this.colName = colName;
        this.colCreatedDate = colCreatedDate;
        this.countryCCY = countryCCY;
        this.reportId = reportId;
        this.result = result;
    }
    return Calculation;
}());
export { Calculation };
var Result = /** @class */ (function () {
    function Result(dashboard, chartBL, chartCntrpty, chartRegGrp, chartRegSubGrp, chartCountry, tableBL, tableCntrpty, tableRegGrp, tableCountry, tableRaw, filterBL) {
        this.dashboard = dashboard;
        this.chartBL = chartBL;
        this.chartCntrpty = chartCntrpty;
        this.chartRegGrp = chartRegGrp;
        this.chartRegSubGrp = chartRegSubGrp;
        this.chartCountry = chartCountry;
        this.tableBL = tableBL;
        this.tableCntrpty = tableCntrpty;
        this.tableRegGrp = tableRegGrp;
        this.tableCountry = tableCountry;
        this.tableRaw = tableRaw;
        this.filterBL = filterBL;
        this.metaBL = {
            id: "chartBusinessMapping",
            title: "RANKING BY BUSINESS LINE",
            label: "Business Line value"
        };
        this.metaCntrpty = {
            id: "chartCounterparty",
            title: "RANKING BY COUNTERPARTY",
            label: "Counterparty value"
        };
        this.metaRegGrp = {
            id: "chartRegulatoryGroup",
            title: "RANKING BY REGULATORY GROUP",
            label: "Regulatory Group value"
        };
        this.metaRegSubGrp = {
            id: "chartRegulatorySubGroup",
            title: "RANKING BY REGULATORY GROUP",
            label: "Regulatory Group value"
        };
        this.metaCountry = {
            id: "chartCountry",
            title: "RANKING BY COUNTRY",
            label: "Population (millions)"
        };
    }
    return Result;
}());
export { Result };
var Dashboard = /** @class */ (function () {
    function Dashboard(presentCurrency, reportingDate, calculationAmount, eadAmount) {
        this.presentCurrency = presentCurrency;
        this.reportingDate = reportingDate;
        this.calculationAmount = calculationAmount;
        this.eadAmount = eadAmount;
    }
    return Dashboard;
}());
export { Dashboard };
var Chart = /** @class */ (function () {
    function Chart(labels, rows) {
        this.labels = labels;
        this.rows = rows;
    }
    return Chart;
}());
export { Chart };
var Table = /** @class */ (function () {
    function Table(headers, headersLong, rows, types) {
        this.headers = headers;
        this.headersLong = headersLong;
        this.rows = rows;
        this.rows = rows;
        this.types = types;
    }
    return Table;
}());
export { Table };
var Meta = /** @class */ (function () {
    function Meta() {
    }
    return Meta;
}());
export { Meta };
var MarketRiskResult = /** @class */ (function () {
    function MarketRiskResult(calc) {
        var _this = this;
        this.ir_specific = new BehaviorSubject(null);
        this.fe_charge = new BehaviorSubject(null);
        this.fet_charge = new BehaviorSubject(null);
        this.eq_charge = new BehaviorSubject(null);
        this.co_charge = new BehaviorSubject(null);
        this.cot_charge = new BehaviorSubject(null);
        this.ir_general = {};
        this.ir_specific.next(this.convertToTable(calc.market_risk['market_risk/csv/IR_SPECIFIC.csv']));
        this.fe_charge.next(this.convertToTable(calc.market_risk['market_risk/csv/FE_CHARGE.csv']));
        this.fet_charge.next(this.convertToTable(calc.market_risk['market_risk/csv/FE_CHARGE_TOTAL.csv']));
        this.eq_charge.next(this.convertToTable(calc.market_risk['market_risk/csv/EQ_CHARGE.csv']));
        this.co_charge.next(this.convertToTable(calc.market_risk['market_risk/csv/CO_CHARGE.csv']));
        this.cot_charge.next(this.convertToTable(calc.market_risk['market_risk/csv/CO_CHARGE_TOTAL.csv']));
        Object.keys(calc.market_risk).filter(function (x) { return x.startsWith('market_risk/csv/ir_generic/'); }).forEach(function (key) {
            var _a = tslib_1.__read(key.replace('market_risk/csv/ir_generic/', '').split('/'), 2), currency = _a[0], file = _a[1];
            if (!_this.ir_general[currency]) {
                _this.ir_general[currency] = {};
            }
            _this.ir_general[currency][file] = new BehaviorSubject(_this.convertToTable(calc.market_risk[key]));
        });
    }
    MarketRiskResult.prototype.convertToTable = function (data) {
        var dataRows = this.filterByHeadersToAvoid(data, [
            "SUM_RWA, SUM_EAD_AMOUNT"
        ]);
        dataRows.headersLong = data.headers;
        dataRows.headers = data.headers_print;
        // add the types
        dataRows.types = data.dtypes;
        return dataRows;
    };
    MarketRiskResult.prototype.filterByHeadersToAvoid = function (data, headersToAvoid) {
        // will store the rows in {column: value} manner
        var dataRows = new Table([], [], [], []);
        data.rows.forEach(function (row) {
            var newLine = {};
            for (var i = 0; i < data.headers.length; i++) {
                if (!headersToAvoid.includes(data.headers[i])) {
                    newLine[data.headers_print[i]] = row[i];
                }
            }
            dataRows.rows.push(newLine);
        });
        return dataRows;
    };
    return MarketRiskResult;
}());
export { MarketRiskResult };
var CreditRiskResultAdapter = /** @class */ (function () {
    function CreditRiskResultAdapter() {
    }
    CreditRiskResultAdapter.prototype.adapt = function (item, useRAW) {
        var result;
        var chartBL$ = new BehaviorSubject(null);
        var chartCntrpty$ = new BehaviorSubject(null);
        var chartRegGrp$ = new BehaviorSubject(null);
        var chartRegSubGrp$ = new BehaviorSubject(null);
        var chartCountry$ = new BehaviorSubject(null);
        var tableBL$ = new BehaviorSubject(null);
        var tableCntrpty$ = new BehaviorSubject(null);
        var tableRegGrp$ = new BehaviorSubject(null);
        var tableCountry$ = new BehaviorSubject(null);
        var filterBL$ = new BehaviorSubject(null);
        // create Dashboard data
        var dashboard = new Dashboard(item.job_metadata.credit_risk.local_ccy, item.calculation_details.args.reporting_date, Number(item.credit_risk.totals.RWA.toFixed()), Number(item.credit_risk.totals.EAD_AMOUNT.toFixed()));
        // create Chart data
        chartBL$.next(this.convertToChart(item.credit_risk.by_business_line, "BUSINESS LINE"));
        chartCntrpty$.next(this.convertToChart(item.credit_risk.by_counterparty_tranche, "COUNTERPARTY TRANCHE"));
        chartCountry$.next(this.convertToChart(item.credit_risk.by_country, "COUNTRY"));
        // create Table data
        tableBL$.next(this.convertToTable(item.credit_risk.by_business_line));
        tableCntrpty$.next(this.convertToTable(item.credit_risk.by_counterparty_tranche));
        tableCountry$.next(this.convertToTable(item.credit_risk.by_country));
        // create Regulatory Group Tables and Charts
        if (item.credit_risk.by_regulatory_group_long_name_tranche) {
            chartRegGrp$.next(this.convertToChart(item.credit_risk.by_regulatory_group_long_name_tranche, "REGULATORY GROUP LONG NAME TRANCHE"));
            tableRegGrp$.next(this.convertToTable(item.credit_risk.by_regulatory_group_long_name_tranche));
        }
        // create BL filter list
        filterBL$.next(this.getBusinessMappingFilters(item.credit_risk.by_business_line.rows));
        // create the Result
        result = new Result(dashboard, chartBL$, chartCntrpty$, chartRegGrp$, chartRegSubGrp$, chartCountry$, tableBL$, tableCntrpty$, tableRegGrp$, tableCountry$, this.generateRAWTable(item, useRAW), filterBL$);
        return result;
    };
    CreditRiskResultAdapter.prototype.generateRAWTable = function (item, useRAW) {
        return useRAW
            ? new BehaviorSubject(this.convertToTable(item.credit_risk.by_regulatory_group_long_name_tranche))
            : null;
    };
    CreditRiskResultAdapter.prototype.convertToTable = function (data) {
        var dataRows = this.filterByHeadersToAvoid(data, [
            "SUM_RWA, SUM_EAD_TRANCHE"
        ]);
        dataRows.headersLong = data.headers;
        dataRows.headers = data.headers_print;
        // add the types
        dataRows.types = data.dtypes;
        return dataRows;
    };
    CreditRiskResultAdapter.prototype.convertToChart = function (data, rowId) {
        var chart = new Chart([], []);
        var dataRows = this.filterByHeadersToAvoid(data, []);
        dataRows.rows.forEach(function (row) {
            chart.labels.push(row[rowId]);
            chart.rows.push(row['SUM RWA']);
        });
        return chart;
    };
    CreditRiskResultAdapter.prototype.getBusinessMappingFilters = function (data) {
        var filters = [];
        data.forEach(function (row) { return filters.push(row[0]); });
        return filters;
    };
    CreditRiskResultAdapter.prototype.filterByHeadersToAvoid = function (data, headersToAvoid) {
        // will store the rows in {column: value} manner
        var dataRows = new Table([], [], [], []);
        data.rows.forEach(function (row) {
            var newLine = {};
            for (var i = 0; i < data.headers.length; i++) {
                if (!headersToAvoid.includes(data.headers[i])) {
                    newLine[data.headers_print[i]] = row[i];
                }
            }
            dataRows.rows.push(newLine);
        });
        return dataRows;
    };
    CreditRiskResultAdapter.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreditRiskResultAdapter_Factory() { return new CreditRiskResultAdapter(); }, token: CreditRiskResultAdapter, providedIn: "root" });
    return CreditRiskResultAdapter;
}());
export { CreditRiskResultAdapter };
