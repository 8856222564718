import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, exhaustMap } from "rxjs/operators";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as FileActions from "./file.actions";
import { FileType } from "../../../shared/models/file.model";
import * as fromFiles from "../store/file.reducers";
import { Store } from "@ngrx/store";

@Injectable()
export class FileEffects {
  fileFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileActions.fetchFiles),
      map(action => {
        return FileActions.fetchedFiles()
      })
    )
  );

  fileTypesFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileActions.fetchFileTypes),
      exhaustMap(action => {
        /* TODO: This needs to be changed with the actual list of Inputs and Parameters, given by the Backend */
        return [
          [
            {
              category_slug: "input",
              category_str: "Input",
              subcategory_slug: "contract",
              subcategory_str: "Contract",
              order: 1
            },
            {
              category_slug: "input",
              category_str: "Input",
              subcategory_slug: "market",
              subcategory_str: "Market",
              order: 2
            },
            {
              category_slug: "input",
              category_str: "Input",
              subcategory_slug: "property",
              subcategory_str: "Property",
              order: 3
            },
            {
              category_slug: "input",
              category_str: "Input",
              subcategory_slug: "retail",
              subcategory_str: "Retail",
              order: 4
            },
            {
              category_slug: "input",
              category_str: "Input",
              subcategory_slug: "crm",
              subcategory_str: "CRM",
              order: 5
            },
            {
              category_slug: "input",
              category_str: "Input",
              subcategory_slug: "crm_expo_mapping",
              subcategory_str: "CRM Expo Mapping",
              order: 5
            },
            {
              category_slug: "parameter",
              category_str: "Parameter",
              subcategory_slug: "security",
              subcategory_str: "Security",
              order: 1
            },
            {
              category_slug: "parameter",
              category_str: "Parameter",
              subcategory_slug: "counterparty",
              subcategory_str: "Counterparty",
              order: 2
            },
            {
              category_slug: "parameter",
              category_str: "Parameter",
              subcategory_slug: "issuer",
              subcategory_str: "Issuer",
              order: 3
            },
            {
              category_slug: "parameter",
              category_str: "Parameter",
              subcategory_slug: "business_activity_mapping",
              subcategory_str: "Business Activity Mapping",
              order: 4
            },
            {
              category_slug: "parameter",
              category_str: "Parameter",
              subcategory_slug: "regulatory_group_mapping",
              subcategory_str: "Regulatory Group Mapping",
              order: 5
            },
            {
              category_slug: "parameter",
              category_str: "Parameter",
              subcategory_slug: "exchange_rate",
              subcategory_str: "Exchange Rate",
              order: 6
            },
            {
              category_slug: "parameter",
              category_str: "Parameter",
              subcategory_slug: "product_mapping",
              subcategory_str: "Product Mapping",
              order: 7
            },
            {
              category_slug: "parameter",
              category_str: "Parameter",
              subcategory_slug: "past_due_status_mapping",
              subcategory_str: "Past Due Status Mapping",
              order: 8
            },
            {
              category_slug: "parameter",
              category_str: "Parameter",
              subcategory_slug: "crm_internal_mapping",
              subcategory_str: "CRM Internal Mapping",
              order: 9
            },
            {
              category_slug: "parameter",
              category_str: "Parameter",
              subcategory_slug: "seniority_internal_mapping",
              subcategory_str: "Seniority Internal Mapping",
              order: 10
            }
          ]
        ];
      }),
      map((fileTypes: any[]) => {
        fileTypes = fileTypes.map(
          (type: any) =>
            new FileType(
              type.category_slug,
              type.category_str,
              type.subcategory_slug,
              type.subcategory_str,
              type.order
            )
        );
        return FileActions.setFileTypes({ fileTypes: fileTypes });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromFiles.State>
  ) {}

  getFileTypeBySubcategory(subcategory: string) {
    return this.store.select(fromFiles.getFileTypeBySubcategory(subcategory));
  }
}
