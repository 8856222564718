import { Component, OnInit, Input } from '@angular/core';
import { Notification } from '../../../shared/models/notification.model';
import { Router } from '@angular/router';
import { UiNotificationService } from '../../../shared/services/ui-notification.service';
import { FileService } from '../../../modules/file/shared/file.service';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: any;

  constructor(
    private router: Router,
    private notificationService: UiNotificationService,
    private fileService: FileService
  ) { }

  ngOnInit() {
  }

  goTo(route: any[]) {
    console.log("ROUTER", this.router);
    this.notificationService.ackNotification(this.notification.id)
    this.router.navigate(route);
  }

  downloadFile(fileUrl, fileName) {
    this.fileService.downloadFile(fileUrl, fileName);
  }

}
