/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./components/upload/upload.component.ngfactory";
import * as i2 from "./components/upload/upload.component";
import * as i3 from "ngx-toastr";
import * as i4 from "./components/upload/upload.service";
import * as i5 from "./shared/file.service";
import * as i6 from "@ngrx/store";
import * as i7 from "@angular/material/dialog";
import * as i8 from "./components/list/list.component.ngfactory";
import * as i9 from "./components/list/list.component";
import * as i10 from "@angular/router";
import * as i11 from "@angular/common/http";
import * as i12 from "./file.component";
var styles_FileComponent = [];
var RenderType_FileComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileComponent, data: {} });
export { RenderType_FileComponent as RenderType_FileComponent };
export function View_FileComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-upload", [["title", "Input"]], null, null, null, i1.View_UploadComponent_0, i1.RenderType_UploadComponent)), i0.ɵdid(3, 245760, null, 0, i2.UploadComponent, [i3.ToastrService, i4.UploadService, i5.FileService, i6.Store, i7.MatDialog], { title: [0, "title"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "app-list", [["category", "input"], ["title", "Input"]], null, null, null, i8.View_ListComponent_0, i8.RenderType_ListComponent)), i0.ɵdid(6, 245760, null, 0, i9.ListComponent, [i5.FileService, i7.MatDialog, i10.Router, i11.HttpClient, i6.Store, i0.ChangeDetectorRef], { title: [0, "title"], category: [1, "category"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "app-list", [["category", "parameter"], ["title", "Parameter"]], null, null, null, i8.View_ListComponent_0, i8.RenderType_ListComponent)), i0.ɵdid(9, 245760, null, 0, i9.ListComponent, [i5.FileService, i7.MatDialog, i10.Router, i11.HttpClient, i6.Store, i0.ChangeDetectorRef], { title: [0, "title"], category: [1, "category"] }, null)], function (_ck, _v) { var currVal_0 = "Input"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "Input"; var currVal_2 = "input"; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = "Parameter"; var currVal_4 = "parameter"; _ck(_v, 9, 0, currVal_3, currVal_4); }, null); }
export function View_FileComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-file", [], null, null, null, View_FileComponent_0, RenderType_FileComponent)), i0.ɵdid(1, 49152, null, 0, i12.FileComponent, [], null, null)], null, null); }
var FileComponentNgFactory = i0.ɵccf("app-file", i12.FileComponent, View_FileComponent_Host_0, {}, {}, []);
export { FileComponentNgFactory as FileComponentNgFactory };
