import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CheckboxFileListComponent } from "./checkbox-file-list/checkbox-file-list.component";
import { MaterialModule } from "../../material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AnalyticStatusComponent } from "./analytic-status/analytic-status.component";
import { ListRemoveDialogComponent } from "./list-remove-dialog/list-remove-dialog.component";
import { RouterModule } from "@angular/router";
import { CheckboxDropdownComponent } from "./checkbox-dropdown/checkbox-dropdown.component";
import { DropdownOptionsComponent } from "./dropdown-options/dropdown-options.component";
import { TableListComponent } from './table-list/table-list.component';
import { StatusIconComponent } from './status-icon/status-icon.component';
import { RecipeGeneratorComponent } from './recipe-generator/recipe-generator.component';
import { DynamicFormsMaterialUIModule } from '@ng-dynamic-forms/ui-material';

@NgModule({
  declarations: [
    CheckboxFileListComponent,
    AnalyticStatusComponent,
    ListRemoveDialogComponent,
    CheckboxDropdownComponent,
    DropdownOptionsComponent,
    TableListComponent,
    StatusIconComponent,
    RecipeGeneratorComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DynamicFormsMaterialUIModule
  ],
  exports: [
    CheckboxFileListComponent,
    AnalyticStatusComponent,
    ListRemoveDialogComponent,
    CheckboxDropdownComponent,
    DropdownOptionsComponent,
    TableListComponent,
    StatusIconComponent,
    RecipeGeneratorComponent
  ]
})
export class SharedModule {}
