import { ITableListStateAction, ITableListState } from '../table-list.model';
import TableListStateActions from './table-list.actions';

export default function tableListReducer(
    state: ITableListState, 
    action: ITableListStateAction
    ) {
    switch(action.type) {
        case TableListStateActions.SORT_CHANGED:
            return {
                ...state,
                sort: action.payload.direction ? [{
                    by: action.payload.active,
                    dir: action.payload.direction
                }] : []
            }
        case TableListStateActions.PAGING_CHANGED:
            return {
                ...state,
                limit: action.payload.pageSize,
                offset: action.payload.pageIndex
            }
        case TableListStateActions.FILTER_CHANGED:
            return {
                ...state,
                offset: 0,
                filter: action.payload ? [{
                    value: action.payload.value,
                    exact: action.payload.exact
                }]: []
            }
        case TableListStateActions.SELECTION_CHANGED:
            return {
                ...state,
                selection: action.payload.selection
            }
        default:
            return {
                ...state
            }
    }
}