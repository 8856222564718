import { Component, OnInit, Input } from "@angular/core";
import { analyticStates } from "../../models/common";

/**
 * Shows an icon (or spinner), regarding the analytic status
 *
 * @export
 * @class AnalyticStatusComponent
 * @implements {OnInit}
 */
@Component({
  selector: "app-analytic-status",
  templateUrl: "./analytic-status.component.html",
  styleUrls: ["./analytic-status.component.scss"]
})
export class AnalyticStatusComponent implements OnInit {
  mode = "indeterminate";

  @Input() analyticStatus;
  analyticStates = analyticStates;

  constructor() {}

  ngOnInit() {}
}
