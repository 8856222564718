import { TableListColumnType } from '../table-list/table-list.model';
import { isNullOrUndefined } from 'util';
import { DynamicFormControlModel, DynamicInputModel, DynamicDatePickerModel, DynamicSelectModel } from '@ng-dynamic-forms/core';

export interface IRecipeGeneratorConfig {
    fields: IRecipeGeneratorField[]
}

export interface IRecipeGeneratorField {
    name: string;
    label: string;
    type: TableListColumnType;
    operations?: IRecipeGeneratorOperation[];
    values?: any[] | string[];
}

export interface IRecipeGeneratorOperation {
    label: string;
    value: string;
    components?: DynamicFormControlModel[];
}

export function getDefaultTypesOperations(type: TableListColumnType, values = null) {
    if (!isNullOrUndefined(values)) {
        return [{ 
            label: "IN",
            value: "IN",
            components: [
                new DynamicSelectModel({
                    id: 'value',
                    label: 'Value',
                    multiple: true,
                    options: values
                })
            ]
        }]
    }
    switch (type) {
        case TableListColumnType.Date:
            return [
                {
                    label: "Equal",
                    value: "EQUAL",
                    components: [
                        new DynamicDatePickerModel({
                            id: 'value',
                            placeholder: 'Value'
                        })
                    ]
                },
                {
                    label: "Before",
                    value: "BEFORE",
                    components: [
                        new DynamicDatePickerModel({
                            id: 'value',
                            placeholder: 'Value'
                        })
                    ]
                },
                {
                    label: "After",
                    value: "AFTER",
                    components: [
                        new DynamicDatePickerModel({
                            id: 'value',
                            placeholder: 'Value'
                        })
                    ]
                },
                {
                    label: "Between",
                    value: "BETWEEN",
                    components: [
                        new DynamicDatePickerModel({
                            id: 'from',
                            placeholder: 'From'
                        }),
                        new DynamicDatePickerModel({
                            id: 'to',
                            placeholder: 'To'
                        })
                    ]
                }
            ];
        case TableListColumnType.Numeric:
            return [
                {
                    label: "Equal",
                    value: "=",
                    components: [
                        new DynamicInputModel({
                            id: 'value',
                            placeholder: 'Value',
                            inputType: 'number'
                        })
                    ]
                },
                {
                    label: "Less than",
                    value: "<",
                    components: [
                        new DynamicInputModel({
                            id: 'value',
                            placeholder: 'Value',
                            inputType: 'number'
                        })
                    ]
                },
                {
                    label: "More Than",
                    value: ">",
                    components: [
                        new DynamicInputModel({
                            id: 'value',
                            placeholder: 'Value',
                            inputType: 'number'
                        })
                    ]
                },
                {
                    label: "Between",
                    value: "BETWEEN",
                    components: [
                        new DynamicInputModel({
                            id: 'from',
                            placeholder: 'From',
                            inputType: 'number'
                        }),
                        new DynamicInputModel({
                            id: 'to',
                            placeholder: 'To',
                            inputType: 'number'
                        })
                    ]
                }
            ];
        default:
            return [
                {
                    label: "Like",
                    value: "LIKE",
                    components: [
                        new DynamicInputModel({
                            id: 'value'
                        })
                    ]
                }
            ];
    }
}
