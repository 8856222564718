import { OnInit, EventEmitter, OnDestroy, ChangeDetectorRef, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { TableListColumnType, TableListConfigDefaults } from './table-list.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import tableListReducer from './table-list-state/table-list.reducer';
import { debounceTime } from 'rxjs/operators';
import TableListStateActions from './table-list-state/table-list.actions';
import { FormControl } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { isNumber } from '@ng-dynamic-forms/core';
import { getDefaultTypesOperations } from '../recipe-generator/recipe-generator.model';
var TableListComponent = /** @class */ (function () {
    function TableListComponent(componentFactoryResolver, changeDetection) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.changeDetection = changeDetection;
        this.tableStateChanged = new EventEmitter();
        this.selectionChanged = new EventEmitter();
        this.selection = [];
        this.columnTypes = TableListColumnType;
        this.filterControl = new FormControl();
        this.filterMatchControl = new FormControl(false);
        this.expandedIndex = -1;
    }
    Object.defineProperty(TableListComponent.prototype, "content", {
        set: function (content) {
            if (content) { // initially setter gets called with undefined
                this.expandComponent = content;
            }
        },
        enumerable: true,
        configurable: true
    });
    TableListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setDefaults();
        this.listenToFilter();
        this.state.subscribe(function (newState) {
            _this.tableStateChanged.emit(newState);
        });
    };
    TableListComponent.prototype.ngOnChanges = function () {
        console.log("ONCHANGE");
        this.setDefaults();
        // TODO: REMOVE THIS WHEN QUERIES RETURN TOTALCOUNT
        if (this.dataSource && !this.dataSource.hasNextPage) {
            var nextButton = document.querySelector('.mat-paginator-navigation-next');
            if (nextButton) {
                nextButton.setAttribute("disabled", "disabled");
            }
        }
        if (this.config && this.config.columns) {
            this.filterConfig = {
                fields: this.config.columns.map(function (c) {
                    return {
                        name: c.name,
                        type: c.type,
                        label: c.label,
                        operations: getDefaultTypesOperations(c.type)
                    };
                })
            };
        }
    };
    TableListComponent.prototype.reload = function () {
        this.tableStateChanged.emit({
            limit: this.config && this.config.state.limit ? this.config.state.limit : TableListConfigDefaults.state.limit,
            offset: this.config && this.config.state.offset ? this.config.state.offset : TableListConfigDefaults.state.offset,
            sort: this.config && this.config.state.sort ? this.config.state.sort : TableListConfigDefaults.state.sort,
            filter: this.config && this.config.state.filter ? this.config.state.sort : TableListConfigDefaults.state.filter
        });
    };
    TableListComponent.prototype.listenToFilter = function () {
        var _this = this;
        this.filterSubscription = this.filterControl.valueChanges.pipe(debounceTime(1000)).subscribe(function (value) {
            _this.filterChanged({
                exact: _this.filterMatchControl.value,
                value: value
            });
        });
        this.filterMatchSubscription = this.filterMatchControl.valueChanges.subscribe(function (value) {
            if (_this.filterControl.value && _this.filterControl.value.length > 0) {
                _this.filterChanged({
                    exact: value,
                    value: _this.filterControl.value
                });
            }
        });
    };
    TableListComponent.prototype.setDefaults = function () {
        if (this.config) {
            this.config.columns = this.config.columns || TableListConfigDefaults.columns;
            this.config.pageSize = this.config.pageSize || TableListConfigDefaults.pageSize;
            this.config.pageSizeOptions = this.config.pageSizeOptions || TableListConfigDefaults.pageSizeOptions;
            this.config.state = this.config.state ? this.config.state : TableListConfigDefaults.state;
            this.config.emptyMessage = this.config.emptyMessage ? this.config.emptyMessage : TableListConfigDefaults.emptyMessage;
            this.config.hasPagination = isNullOrUndefined(this.config.hasPagination) ? TableListConfigDefaults.hasPagination : this.config.hasPagination;
            this.config.hasFilter = isNullOrUndefined(this.config.hasFilter) ? TableListConfigDefaults.hasFilter : this.config.hasFilter;
        }
        if (!this.state) {
            this.state = new BehaviorSubject({
                limit: this.config && this.config.state.limit ? this.config.state.limit : TableListConfigDefaults.state.limit,
                offset: this.config && this.config.state.offset ? this.config.state.offset : TableListConfigDefaults.state.offset,
                sort: this.config && this.config.state.sort ? this.config.state.sort : TableListConfigDefaults.state.sort,
                filter: this.config && this.config.state.filter ? this.config.state.sort : TableListConfigDefaults.state.filter
            });
        }
    };
    TableListComponent.prototype.filterChanged = function (filterInfo) {
        this.state.next(tableListReducer(this.state.value, {
            type: TableListStateActions.FILTER_CHANGED,
            payload: filterInfo.value && filterInfo.value.length > 0 ? filterInfo : null
        }));
    };
    TableListComponent.prototype.sortChanged = function (sortInfo) {
        this.state.next(tableListReducer(this.state.value, { type: TableListStateActions.SORT_CHANGED, payload: sortInfo }));
    };
    TableListComponent.prototype.pagingChanged = function (pagingInfo) {
        this.state.next(tableListReducer(this.state.value, { type: TableListStateActions.PAGING_CHANGED, payload: pagingInfo }));
    };
    TableListComponent.prototype.setSelection = function (val) {
        var element = this.dataSource.data.data.filter(function (x) { return x.uuid === val; })[0];
        if (Array.isArray(val)) {
            this.selection = val;
        }
        else {
            this.selection = this.isSelected(val) ?
                this.selection.filter(function (x) { return x.uuid !== val; })
                : [].concat(this.selection, element);
        }
        this.selectionChanged.emit({ selection: this.selection, current: element });
        this.changeDetection.markForCheck();
    };
    TableListComponent.prototype.isSelected = function (val) {
        return val && this.selection.findIndex(function (x) { return x.uuid === val; }) >= 0;
    };
    Object.defineProperty(TableListComponent.prototype, "tableColumns", {
        get: function () {
            return this.config.columns.map(function (x) { return x.name; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableListComponent.prototype, "hasExpandableColumns", {
        get: function () {
            return this.config.columns
                .filter(function (x) { return x.type === TableListColumnType.ExpandableRow; }).length > 0;
        },
        enumerable: true,
        configurable: true
    });
    TableListComponent.prototype.getExpandableColumnData = function (e) {
        return this.config.columns
            .find(function (x) { return x.type === TableListColumnType.ExpandableRow; }).valueGetter(e);
    };
    TableListComponent.prototype.expandRow = function (e) {
        var _this = this;
        var rowIndex = this.dataSource.data.data.indexOf(e);
        if (this.expandedIndex === rowIndex) {
            this.expandedIndex = -1;
        }
        else {
            this.expandedIndex = rowIndex;
        }
        setTimeout(function () {
            _this.createDynamicExpandedComponent(e);
        }, 0);
    };
    TableListComponent.prototype.createDynamicExpandedComponent = function (e) {
        var expandColumn = this.config.columns
            .find(function (x) { return x.type === TableListColumnType.ExpandableRow; });
        var resolver = this.componentFactoryResolver.resolveComponentFactory(expandColumn.expand.component);
        var componentRef = this.expandComponent.createComponent(resolver);
        componentRef.instance.data = e;
        this.changeDetection.markForCheck();
    };
    TableListComponent.prototype.isElementExpanded = function (e) {
        return this.dataSource.data.data.indexOf(e) === this.expandedIndex;
    };
    TableListComponent.prototype.updateComponent = function () {
        this.changeDetection.markForCheck();
    };
    TableListComponent.prototype.ngOnDestroy = function () {
        this.state.unsubscribe();
    };
    TableListComponent.prototype.hasData = function (val) {
        return isNumber(val) || (!isNullOrUndefined(val) && val.length > 0);
    };
    return TableListComponent;
}());
export { TableListComponent };
