export const environment = {
  production: true,
  ws_notifications: getWSProtocol() + location.host + "/api/notifications/",
  app_version: require("../../package.json").version,
  api_route: "https://test-enigma.optimum-finance.net/api/v2/"
};

/**
 * Returns the correct Web Service protocol to be used
 *
 * @export
 * @returns {string}
 */
export function getWSProtocol(): string {
  return location.protocol === "https:" ? "wss://" : "ws://";
}
