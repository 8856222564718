import {
  trigger,
  state,
  style,
  transition,
  animate
} from "@angular/animations";
import { tap } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { FileService } from "../../shared/file.service";
import { File } from "../../../../shared/models/file.model";
import { Dataset } from "../../../../shared/models/dataset.model";
import { DatasetService } from "../../../dataset/shared/dataset.service";
import { Calculation } from "../../../../shared/models/calculation.model";
import { RoutingState } from "../../../../shared/services/routing-state.service";
import { CalculationService } from "../../../calculation/shared/calculation.service";
import { ConfirmDialogComponent } from "../../../../shared/components/confirm-dialog/confirm-dialog.component";

/**
 * Shows a list of all the depending "datasets/calculations". Allows to delete these datasets/calculations and
 * to delete the File
 *
 * @export
 * @class RemoveComponent
 * @implements {OnInit}
 */
@Component({
  selector: "app-remove",
  templateUrl: "./remove.component.html",
  styleUrls: ["./remove.component.scss"],
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ])
  ]
})
export class RemoveComponent implements OnInit {
  dataSource: MatTableDataSource<string[]>;
  displayedColumns: string[] = [
    "expandColumn",
    "name",
    "createdDate",
    "actions"
  ];
  previousState: string;
  expandedElement: false;
  expandedIndex = 0;
  datasetsCount = 0;
  currentFile: File;
  isLoading = true;

  /**
   * Creates an instance of RemoveComponent.
   * @param {RoutingState} routingState
   * @param {ActivatedRoute} route
   * @param {Router} router
   * @param {FileService} fileService
   * @param {DatasetService} datasetService
   * @param {CalculationService} calculationService
   * @param {MatDialog} dialog
   * @memberof RemoveComponent
   */
  constructor(
    private routingState: RoutingState,
    private route: ActivatedRoute,
    private router: Router,
    private fileService: FileService,
    private datasetService: DatasetService,
    private calculationService: CalculationService,
    private dialog: MatDialog
  ) {}

  /**
   *
   * @memberof RemoveComponent
   */
  ngOnInit() {
    this.previousState = this.routingState.getPreviousUrl();
    this.route.params.subscribe(params => {
      this.setDatasetDependencies(params["uuid"]);
      this.fileService.getFileByUUID(params["uuid"]).subscribe(res => {
        if (!this.currentFile) {
          this.currentFile = res;
        }
      });
    });
  }

  /**
   * Sets the number of dataset dependencies while trying to remove
   *
   * @param {string} uuid
   * @memberof RemoveComponent
   */
  setDatasetDependencies(uuid: string) {
    this.fileService.canRemove(uuid).subscribe(
      response => {
        if (response === true) {
          this.datasetsCount = 0;
          /** TODO: Edge case, deal with related dependencies
           * been deleted meanwhile and File removal being possible at this point */
        }
        this.isLoading = false;
      },
      error => {
        /** TODO: create models */
        this.dataSource = new MatTableDataSource(error.error.collection_uuids);
        this.datasetsCount = error.error.collection_uuids
          ? error.error.collection_uuids.length
          : 0;
        this.isLoading = false;
      }
    );
  }

  /**
   * Checks if the given row is expanded
   *
   * @param {*} row
   * @returns {boolean}
   * @memberof RemoveComponent
   */
  expandRow(row: any): boolean {
    this.expandedIndex = this.dataSource.data.indexOf(row);
    return (this.expandedElement = this.expandedElement === row ? null : row);
  }

  /**
   * Opens the removal confirm dialog
   *
   * @param {Dataset} dataset
   * @memberof RemoveComponent
   */
  removeDatasetDiag(dataset: Dataset) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: {
        title: "Remove Dataset",
        text: "Do you confirm the removal?",
        btnOk: "Remove",
        btnCancel: "Cancel"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // has removal been confirmed
      if (result) {
        this.datasetService
          .removeDataset(dataset.uuid)
          .subscribe(response => this.ngOnInit());
      }
    });

    // Forces the dialog to close when backdrop is used
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    });
  }

  /**
   * Opens the removal calculation confirm dialog
   *
   * @param {Calculation} element
   * @memberof RemoveComponent
   */
  removeCalcDiag(element: Calculation) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: {
        title: "Remove Calculation",
        text: "Do you confirm the removal?",
        btnOk: "Remove",
        btnCancel: "Cancel"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.calculationService
          .removeCalculationByUUID(element.uuid)
          .subscribe(response => this.ngOnInit());
      }
    });

    // Forces the dialog to close when backdrop is used
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    });
  }

  /**
   * Opens the removal confirm dialog
   * @memberof RemoveComponent
   */
  removeFile() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: {
        title: "Remove File",
        text: "Do you confirm the removal?",
        btnOk: "Remove",
        btnCancel: "Cancel"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // confirmed removal
      if (result) {
        this.fileService
          .removeFile(this.currentFile.uuid)
          .subscribe(response => this.router.navigate(["/pages/files"]));
      }
    });

    // Forces the dialog to close when backdrop is used
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    });
  }
}
