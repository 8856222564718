<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center mb-5">
          <img alt="Enigma Risk Logo" src="../../../../assets/img/brand/logo_enigma_white.png" />
        </div>
        <div class="col-md-4 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form (ngSubmit)="login()" [formGroup]="loginForm">
                  <div class="form-group">
                    <div *ngIf="showAlert" class="alert alert-danger" role="alert">
                      <h4 class="alert-heading">Error</h4>
                      <p>Invalid Email or Password.</p>
                    </div>
                    <label for="username">Username</label>
                    <input type="text" class="form-control" id="username" placeholder="Username"
                      formControlName="username" required>
                    <div [hidden]="loginForm.get('username').valid || loginForm.get('username').pristine"
                      class="alert alert-danger">
                      Username is required
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="email">Password</label>
                    <input type="password" class="form-control" id="password" placeholder="Password"
                      formControlName="password" required>
                    <div [hidden]="loginForm.get('password').valid || loginForm.get('password').pristine"
                      class="alert alert-danger">
                      Password is required
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary">Login</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
