import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { LoginResultModel } from "../models/login-result.model";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  constructor(private http: HttpClient) {}

  /**
   * Returns an Observable regarding the result of the login process
   *
   * @param {string} username
   * @param {string} password
   * @returns {Observable<LoginResultModel>}
   * @memberof AuthService
   */
  login(username: string, password: string): Observable<LoginResultModel> {
    const formData: FormData = new FormData();

    formData.append("username", username);
    formData.append("password", password);

    return this.http.post<LoginResultModel>(`${environment.api_route}users/login`, formData, {
      withCredentials: true
    });
  }
}
