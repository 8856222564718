import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ViewContainerRef, ComponentFactoryResolver, ElementRef } from '@angular/core';
import { ITableListConfig, TableListColumnType, ITableListState, TableListConfigDefaults, ITableListDataSource } from './table-list.model';
import { BehaviorSubject, Subscription, Subject } from 'rxjs';
import tableListReducer from './table-list-state/table-list.reducer';
import { debounceTime, filter, map, distinctUntilChanged } from 'rxjs/operators';
import TableListStateActions from './table-list-state/table-list.actions';
import { FormControl } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { isNullOrUndefined } from 'util';
import { isNumber } from '@ng-dynamic-forms/core';
import { IRecipeGeneratorConfig, getDefaultTypesOperations } from '../recipe-generator/recipe-generator.model';

@Component({
  selector: 'app-table-list',
  templateUrl: './table-list.component.html',
  styleUrls: ['./table-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("detailExpand", [
      state(
        "collapsed",
        style({ height: "0px", minHeight: "0", display: "none" })
      ),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ])
  ]
})
export class TableListComponent implements OnInit, OnDestroy {
  @Input() dataSource: ITableListDataSource<any>;
  @Input() config: ITableListConfig;
  @Output() tableStateChanged: EventEmitter<ITableListState> = new EventEmitter();
  @Output() selectionChanged: EventEmitter<{selection: any[], current: any}> = new EventEmitter();
  @Output() selection: any[] = [];
  columnTypes = TableListColumnType;
  state: BehaviorSubject<ITableListState>;
  filterControl = new FormControl();
  filterMatchControl = new FormControl(false);
  filterSubscription: Subscription;
  filterMatchSubscription: Subscription;
  expandedElement: false;
  expandedIndex = -1;

  filterConfig: IRecipeGeneratorConfig;

  private expandComponent: ViewContainerRef;
  @ViewChild('expandComponent',{read:ViewContainerRef, static: false}) set content(content: ViewContainerRef) {
      if(content) { // initially setter gets called with undefined
          this.expandComponent = content;
      }
  }

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.setDefaults();

    this.listenToFilter();

    this.state.subscribe(newState => {    
      this.tableStateChanged.emit(newState);
    });
  }

  ngOnChanges() {
    console.log("ONCHANGE");
    this.setDefaults();
    // TODO: REMOVE THIS WHEN QUERIES RETURN TOTALCOUNT
    if (this.dataSource && !this.dataSource.hasNextPage) {
      const nextButton = document.querySelector('.mat-paginator-navigation-next');
      if (nextButton) {
        nextButton.setAttribute("disabled","disabled");
      }
    }

    if (this.config && this.config.columns) {
      this.filterConfig = {
        fields: this.config.columns.map(c => {
          return {
            name: c.name,
            type: c.type,
            label: c.label,
            operations: getDefaultTypesOperations(c.type)
          }
        })
      }
    }
  }

  reload() {
    this.tableStateChanged.emit(<ITableListState>{
      limit: this.config && this.config.state.limit ? this.config.state.limit : TableListConfigDefaults.state.limit,
      offset: this.config && this.config.state.offset ? this.config.state.offset : TableListConfigDefaults.state.offset,
      sort: this.config && this.config.state.sort ? this.config.state.sort : TableListConfigDefaults.state.sort,
      filter: this.config && this.config.state.filter ? this.config.state.sort : TableListConfigDefaults.state.filter
    });
  }

  listenToFilter() {
    this.filterSubscription = this.filterControl.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      this.filterChanged({
        exact: this.filterMatchControl.value,
        value
      })
    });
    this.filterMatchSubscription = this.filterMatchControl.valueChanges.subscribe(value => {
      if (this.filterControl.value && this.filterControl.value.length > 0) {
        this.filterChanged({
          exact: value,
          value: this.filterControl.value
        })
      }
    });
  }

  setDefaults() {
    if (this.config) {
      this.config.columns = this.config.columns || TableListConfigDefaults.columns
      this.config.pageSize = this.config.pageSize || TableListConfigDefaults.pageSize
      this.config.pageSizeOptions = this.config.pageSizeOptions || TableListConfigDefaults.pageSizeOptions
      this.config.state = this.config.state ? this.config.state : TableListConfigDefaults.state;
      this.config.emptyMessage = this.config.emptyMessage ? this.config.emptyMessage : TableListConfigDefaults.emptyMessage;
      this.config.hasPagination = isNullOrUndefined(this.config.hasPagination) ? TableListConfigDefaults.hasPagination : this.config.hasPagination;
      this.config.hasFilter = isNullOrUndefined(this.config.hasFilter) ? TableListConfigDefaults.hasFilter : this.config.hasFilter;
    }

    if (!this.state) {
      this.state = new BehaviorSubject(<ITableListState>{
        limit: this.config && this.config.state.limit ? this.config.state.limit : TableListConfigDefaults.state.limit,
        offset: this.config && this.config.state.offset ? this.config.state.offset : TableListConfigDefaults.state.offset,
        sort: this.config && this.config.state.sort ? this.config.state.sort : TableListConfigDefaults.state.sort,
        filter: this.config && this.config.state.filter ? this.config.state.sort : TableListConfigDefaults.state.filter
      });
    }

  }

  filterChanged(filterInfo) {
    this.state.next(
      tableListReducer(
        this.state.value, 
        {
          type: TableListStateActions.FILTER_CHANGED,
          payload: filterInfo.value && filterInfo.value.length > 0 ? filterInfo : null
        }
      )
    )
  }

  sortChanged(sortInfo) {
    this.state.next(
      tableListReducer(
        this.state.value, 
        { type: TableListStateActions.SORT_CHANGED, payload: sortInfo }
      )
    )
  }

  pagingChanged(pagingInfo) {
    this.state.next(
      tableListReducer(
        this.state.value, 
        { type: TableListStateActions.PAGING_CHANGED, payload: pagingInfo }
      )
    )
  }

  setSelection(val) {
    const element = this.dataSource.data.data.filter(x => x.uuid === val)[0];
    if (Array.isArray(val)) {
      this.selection = val;
    } else {
      this.selection = this.isSelected(val) ? 
      this.selection.filter(x => x.uuid !== val) 
      : [].concat(this.selection, element);
    }
    
    this.selectionChanged.emit({ selection: this.selection, current: element});
    this.changeDetection.markForCheck()
  }

  isSelected(val) {
    return val && this.selection.findIndex(x => x.uuid === val) >= 0;
  }

  get tableColumns(): string[] {
    return this.config.columns.map(x => x.name)
  }

  get hasExpandableColumns(): boolean {
    return this.config.columns
                .filter(x => x.type === TableListColumnType.ExpandableRow).length > 0;
  }

  getExpandableColumnData(e: any) {
    return this.config.columns
                .find(x => x.type === TableListColumnType.ExpandableRow).valueGetter(e);
  }

  expandRow(e: any) {
    const rowIndex = this.dataSource.data.data.indexOf(e);
    if (this.expandedIndex === rowIndex) {
      this.expandedIndex = -1;
    } else {
      this.expandedIndex = rowIndex;
    }
    setTimeout(() => {
      this.createDynamicExpandedComponent(e);
    },0);
  }

  createDynamicExpandedComponent(e: any) {
    const expandColumn = this.config.columns
    .find(x => x.type === TableListColumnType.ExpandableRow);
    const resolver = this.componentFactoryResolver.resolveComponentFactory(expandColumn.expand.component);
    const componentRef = this.expandComponent.createComponent(resolver);
    (<any>componentRef.instance).data = e;
    this.changeDetection.markForCheck();
  }

  isElementExpanded(e: any) {
    return this.dataSource.data.data.indexOf(e) === this.expandedIndex;
  }

  updateComponent() {
    this.changeDetection.markForCheck();
  }

  ngOnDestroy() {
    this.state.unsubscribe();
  }

  hasData(val) {
    return isNumber(val) || (!isNullOrUndefined(val) && val.length > 0);
  }

}
