import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { navItems } from "./_nav";
import { UiNotificationService } from "../../shared/services/ui-notification.service";
import { UserService } from "../../shared/services/user.service";
import { Router } from "@angular/router";
import { Notification } from "../../shared/models/notification.model";
import * as fromFile from "../../modules/file/store/file.reducers";
import { Store } from "@ngrx/store";
import { environment } from "../../../environments/environment.prod";
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: "app-main-layout",
  templateUrl: "./main-layout.component.html",
  styleUrls: ["./main-layout.component.scss"]
})
export class MainLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('cdkVirtualScrollViewport', { static: false }) cdkVirtualScrollViewport: CdkVirtualScrollViewport;
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  public notifications: Notification[] = [];
  public newNotificationsTotal: number = 0;
  public previousScrollIndex = 0;
  public hasRequestPending = false;
  version = environment.app_version;
  showSidebar = false;

  constructor(
    private userService: UserService,
    private router: Router,
    public notificationService: UiNotificationService,
    private store: Store<fromFile.State>,
    private changeDetection: ChangeDetectorRef
  ) {
    this.changes = new MutationObserver(mutations => {
      this.sidebarMinimized = document.body.classList.contains(
        "sidebar-minimized"
      );
    });

    this.changes.observe(this.element, {
      attributes: true,
      attributeFilter: ["class"]
    });
  }

  ngOnInit() {
    this.notificationService.initNotifications();
    
    this.notificationService.notifications$.subscribe(
      notifications => {
        this.notifications = notifications;
        this.newNotificationsTotal = this.notifications.filter(x => x.acked_at === "").length;
        this.hasRequestPending = false;
        this.changeDetection.detectChanges();
      }
    );
  }

  menuClosed() {
    //this.notificationService.initNotifications(false);
  }

  menuOpened() {
    if (this.cdkVirtualScrollViewport) {
      this.cdkVirtualScrollViewport.setRenderedContentOffset(0);
      this.cdkVirtualScrollViewport.scrollToIndex(0);
    }
  }

  ngOnDestroy() {
    this.notificationService.notifications$.unsubscribe();
  }

  // readAllNotifications() {
  //   this.notificationService.markAllRead();
  // }

  onLogout() {
    this.notificationService.ngOnDestroy();
    this.userService.deleteToken();
    this.router.navigate(["login"]);
  }

  rangeChanged(index) {
    if (index >= (this.notifications.length - 10) && (index > this.previousScrollIndex) && !this.hasRequestPending) {
      this.notificationService.updateNotifications(this.notifications.length);
      this.hasRequestPending = true;
    }
    this.previousScrollIndex = index;
  }

  ngAfterViewInit() {
    // used to force sidebar to be presented (hidden) after being fully loaded
    setTimeout(() => {
      this.showSidebar = true;
    }, 2000);
  }
}
