import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(http) {
        this.http = http;
    }
    /**
     * Returns an Observable regarding the result of the login process
     *
     * @param {string} username
     * @param {string} password
     * @returns {Observable<LoginResultModel>}
     * @memberof AuthService
     */
    AuthService.prototype.login = function (username, password) {
        var formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        return this.http.post(environment.api_route + "users/login", formData, {
            withCredentials: true
        });
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
