import * as CalculationActions from "./calculation.actions";
import {
  createReducer,
  on,
  Action,
  createFeatureSelector,
  createSelector
} from "@ngrx/store";
import { Calculation } from "../../../shared/models/calculation.model";

export interface State {
  calculation: Calculation[];
  needsReload: boolean;
  loaded: boolean;
}

const initialState = {
  calculation: [],
  needsReload: false,
  loaded: false
};

const calculationReducer = createReducer(
  initialState,
  on(
    CalculationActions.fecthCalculation,
    state => ({
      ...state,
      needsReload: true,
      loaded: false
    })
  ),
  on(CalculationActions.addCalculation, (state, { calculation }) => {
    return { ...state, calculation: [calculation, ...state.calculation] };
  }),
  on(CalculationActions.setCalculation, (state) => ({
    ...state,
    loaded: true,
    needsReload: false
  })),
  on(CalculationActions.deleteCol, (state, { calculation }) => ({
    ...state,
    calculation: [...state.calculation.filter(x => x !== calculation)]
  })),
  on(CalculationActions.clearCalculation, state => ({
    ...state,
    calculation: undefined
  }))
);

export const getCalculation = (state: State) => state.calculation;

export function reducer(state: State | undefined, action: Action) {
  return calculationReducer(state, action);
}

export const getCalculationState = createFeatureSelector("calculation");

export const getCurrentState = createSelector(
  getCalculationState,
  (state: State) => state
);

export const getAllCalculation = createSelector(
  getCalculationState,
  getCalculation
);

export const getCalculationByUUID = createSelector(
  getCalculationState,
  (state: State, uuid: string) =>
    state.calculation.find(calculation => calculation.uuid === uuid)
);
