import * as tslib_1 from "tslib";
import * as FileActions from "./file.actions";
import { createReducer, on, createSelector, createFeatureSelector } from "@ngrx/store";
var initialState = {
    files: [],
    fileTypes: [],
    loaded: false,
    needReload: false
};
var ɵ0 = function (state) { return (tslib_1.__assign({}, state, { loaded: false, needReload: true })); }, ɵ1 = function (state) { return (tslib_1.__assign({}, state, { loaded: true, needReload: false })); }, ɵ2 = function (state) { return (tslib_1.__assign({}, state, { loaded: true })); }, ɵ3 = function (state, _a) {
    var fileTypes = _a.fileTypes;
    return (tslib_1.__assign({}, state, { fileTypes: fileTypes, loaded: true }));
};
var filesReducer = createReducer(initialState, on(FileActions.fetchFiles, ɵ0), on(FileActions.fetchedFiles, ɵ1), on(FileActions.fetchFileTypes, ɵ2), on(FileActions.setFileTypes, ɵ3));
export function reducer(state, action) {
    return filesReducer(state, action);
}
export var getFileState = createFeatureSelector("files");
var ɵ4 = function (state) {
    if (state) {
        var files$ = state.files;
        files$.filter(function (file) { return file.type.categorySlug === "input"; });
        return files$;
    }
    return [];
};
export var getInputFileState = createSelector(getFileState, ɵ4);
export var getFiles = function (state) { return state.files; };
var ɵ5 = function (state) { return state; };
export var getCurrentState = createSelector(getFileState, ɵ5);
var ɵ6 = function (state, uuid) { return state.files.find(function (col) { return col.uuid === uuid; }); };
export var getFileByUUID = createSelector(getFileState, ɵ6);
var ɵ7 = function (state, category) {
    if (state.files && state.loaded) {
        return {
            files: state.files.filter(function (file) {
                if (file.domain === "UPLOADS" && file.type.categorySlug === category) {
                    return file;
                }
            }),
            fileTypes: state.fileTypes,
            loaded: true
        };
    }
    return { files: [], loaded: false };
};
export var getListByCategory = createSelector(getFileState, ɵ7);
export var getFileTypeByCategory = function (category) {
    return createSelector(getFileState, function (state) {
        if (state) {
            return state.fileTypes.filter(function (type) { return type.categorySlug === category; });
        }
    });
};
export var getFileTypeBySubcategory = function (subcategory) {
    return createSelector(getFileState, function (state) {
        if (state) {
            var foundType_1;
            state.fileTypes.forEach(function (type) {
                if (type.subcategorySlug == subcategory) {
                    foundType_1 = type;
                }
            });
            return foundType_1;
        }
    });
};
var ɵ8 = function (state) { return state.fileTypes; };
export var getFileTypes = createSelector(getFileState, ɵ8);
var ɵ9 = function (state) { return state.loaded; };
export var isLoaded = createSelector(getFileState, ɵ9);
var ɵ10 = function (state) { return state.needReload; };
export var needsReload = createSelector(getFileState, ɵ10);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
