import { map, tap, first } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import * as fromApp from "../../../../app/modules/calculation/store/calculation.reducers";
import * as CalculationActions from "../../../modules/calculation/store/calculation.actions";
import { CreditRiskResultAdapter } from "../../../shared/models/calculation.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/models/calculation.model";
import * as i3 from "@ngrx/store";
import * as i4 from "ngx-toastr";
/**
 *
 * @export
 * @class CalculationService
 */
var CalculationService = /** @class */ (function () {
    /**
     *Creates an instance of CalculationService.
     * @param {HttpClient} http
     * @param {CalculationResultAdapter} calculationResultAdapter
     * @param {Store<fromApp.State>} store
     * @param {ToastrService} toastr
     * @memberof CalculationService
     */
    function CalculationService(http, calculationResultAdapter, store, toastr) {
        this.http = http;
        this.calculationResultAdapter = calculationResultAdapter;
        this.store = store;
        this.toastr = toastr;
        // this.calculation = new BehaviorSubject([]);  // DIE!!
    }
    /**
     * Returns an Observable containing the Calculation List
     *
     * @returns {Observable<fromApp.State>}
     * @memberof CalculationService
     */
    CalculationService.prototype.getCalculationList = function (params) {
        return this.http
            .get(environment.api_route + "/analyses/ls", {
            params: params,
            withCredentials: true
        });
    };
    /**
   * Returns an Observable containing the Calculation Table Description
   *
   * @returns {Observable<fromApp.State>}
   * @memberof CalculationService
   */
    CalculationService.prototype.getCalculationTableDescription = function (cal_uuid, table_uuid, columns) {
        var params = new HttpParams();
        columns.forEach(function (c) { return params = params.append("columns", c); });
        return this.http
            .get(environment.api_route + "/analyses/schema/table/" + cal_uuid + "/" + table_uuid, {
            withCredentials: true,
            params: params
        });
    };
    /**
     * Returns a Calculation, containing the filtered Result by BL
     *
     * @param {Calculation} calculation
     * @param {string[]} blFilters
     * @returns {Calculation}
     * @memberof CalculationService
     */
    CalculationService.prototype.getCalculationByBL = function (uuid, blFilters) {
        var params = new HttpParams();
        // add business mapping filters to httpparams
        blFilters.forEach(function (filter) { return (params = params.append("business_line", filter)); });
        return this.http
            .get(environment.api_route + "analyses/dashboard/" + uuid, {
            params: params,
            withCredentials: true
        });
    };
    /**
     * Returns an Observable containing the Calculation Metadata
     *
     * @returns {Observable<IAPIDetailMetadata>}
     * @memberof CalculationService
     */
    CalculationService.prototype.getCalculationDetailMetadata = function () {
        return this.http
            .get(environment.api_route + "utils/profiles", {
            withCredentials: true
        })
            .pipe(map(function (response) {
            return {
                detailMetaNames: Object.keys(response["res"]),
                originalNames: response["res"]
            };
        }));
    };
    /**
     * Returns a Calculation from the store, given a specific UUID
     *
     * @param {string} uuid
     * @returns {Observable<Calculation>}
     * @memberof CalculationService
     */
    CalculationService.prototype.getCalculationByUUID = function (uuid) {
        // const params = new HttpParams().append("filter_by", `uuid:=:'${uuid}'`)
        return this.http
            .get(environment.api_route + "analyses/dashboard/" + uuid, {
            withCredentials: true
        }).pipe(first());
    };
    /**
     * Returns an Observable for a specific Calculation, given a uuid
     *
     * @param {string} uuid
     * @param {boolean} useRAW
     * @returns {Observable<Result>}
     * @memberof CalculationService
     */
    CalculationService.prototype.getCalculationResultByUUID = function (uuid, useRAW) {
        var _this = this;
        var params = new HttpParams().set("uuid", uuid);
        if (!useRAW) {
            params = params.set("raw", "no");
        }
        return this.http
            .get(environment.api_route + "analysis/dashboard/" + uuid, {
            params: params,
            withCredentials: true
        })
            .pipe(map(function (data) { return _this.calculationResultAdapter.adapt(data, useRAW); }));
    };
    /**
     * Returns an Observable for a specific Report, given a uuid
     *
     * @param {string} uuid
     * @returns {Observable<IAPIReport>}
     * @memberof CalculationService
     */
    CalculationService.prototype.getCalculationReportByUUID = function (uuid) {
        var params = new HttpParams().set("res_uuid", uuid);
        return this.http.get(environment.api_route + "uploads/report", {
            params: params,
            withCredentials: true
        });
    };
    /**
     * Returns the Calculation current state from the Store
     *
     * @returns
     * @memberof CalculationService
     */
    CalculationService.prototype.getCalculationCurrentState = function () {
        return this.store.pipe(select(fromApp.getCurrentState));
    };
    /**
     * Triggers the Calculation List update from the store
     *
     * @memberof CalculationService
     */
    CalculationService.prototype.updateCalculationList = function () {
        this.store.dispatch(CalculationActions.fecthCalculation());
    };
    CalculationService.prototype.currentState = function () {
        return this.store.select(fromApp.getCurrentState);
    };
    /**
     * Removes a specific Calculation by a given UUID, then updates the current list
     * and shows a Toastr message
     *
     * @param {string} uuid
     * @returns {Observable<string[]>}
     * @memberof CalculationService
     */
    CalculationService.prototype.removeCalculationByUUID = function (uuid) {
        var _this = this;
        var params = new HttpParams().set("uuid", uuid);
        return this.http
            .get(environment.api_route + "uploads/rm_result", {
            withCredentials: true,
            params: params
        })
            .pipe(tap(function (data) {
            if (data["success"]) {
                // update file list
                _this.updateCalculationList();
                // show success toastr
                _this.toastr.success("Calculation removed successfully.", "Calculation Remove");
                return true;
            }
            // if not successful...error
            _this.toastr.error("There was an error while trying to delete the Calculation. Please try again", "Calculation Remove");
            return false;
        }));
    };
    /**
     * Generates the Report for a specific Calculation
     *
     * @param {string} uuid
     * @memberof CalculationService
     */
    CalculationService.prototype.generateReport = function (uuid) {
        var _this = this;
        this.http
            .post(environment.api_route + "uploads/launch_report", { res_uuid: uuid }, { withCredentials: true })
            .subscribe(function (data) {
            if (data["success"]) {
                // show success toastr
                _this.toastr.success("Report Generated successfully.", "Report Generate");
                return true;
            }
            // if not successful...error
            _this.toastr.error("There was an error while trying to generate the Report. Please try again", "Report Generate");
            return false;
        });
    };
    CalculationService.prototype.adaptResponse = function (res) {
        return this.calculationResultAdapter.adapt(res, false);
    };
    CalculationService.prototype.getCalculationTable = function (cal_uuid, table_id, params) {
        return this.http.get(environment.api_route + "analyses/inspect/table/" + cal_uuid + "/" + table_id, {
            withCredentials: true,
            params: params
        });
    };
    CalculationService.prototype.downloadTable = function (cal_uuid, table_id, params) {
        return this.http.post(environment.api_route + "analyses/download/table/" + cal_uuid + "/" + table_id, {}, {
            withCredentials: true,
            params: params
        });
    };
    CalculationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CalculationService_Factory() { return new CalculationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CreditRiskResultAdapter), i0.ɵɵinject(i3.Store), i0.ɵɵinject(i4.ToastrService)); }, token: CalculationService, providedIn: "root" });
    return CalculationService;
}());
export { CalculationService };
