import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { FileSaverService } from "ngx-filesaver";
import { Injectable, isDevMode } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { mergeMap, map, switchMap, tap, first } from "rxjs/operators";
import * as fromApp from "../store/file.reducers";
import * as FileActions from "../store/file.actions";

import {
  IAPIFileHead,
  FileInspectAdapter,
  IFileInspect,
  IAPIFileInspect,
  IFileInspectData
} from "../../../shared/models/file-inspect.model";
import { ToastrService } from "ngx-toastr";
import { FileType } from "../../../shared/models/file.model";
import { environment } from "../../../../environments/environment";
import tableChangesToAPIParams from '../../../shared/components/table-list/table-changes-to-api';
import { TableListConfigDefaults } from '../../../shared/components/table-list/table-list.model';
import { IAPIFileList } from '../../../shared/models/API.model';
import { isNullOrUndefined } from 'util';

/**
 *
 * @export
 * @class FileService
 */
@Injectable({
  providedIn: "root"
})
export class FileService {
  /**
   * Creates an instance of FileService.
   * @param {Store<fromApp.State>} store
   * @param {HttpClient} http
   * @param {FileSaverService} fileSaver
   * @param {ToastrService} toastr
   * @memberof FileService
   */
  constructor(
    private store: Store<fromApp.State>,
    private http: HttpClient,
    private fileSaver: FileSaverService,
    private toastr: ToastrService
  ) {}

  /**
   * Returns an Observable containing the currentstate, amoung the list of Files
   *
   * @returns {Observable<fromApp.State>}
   * @memberof FileService
   */
  readFiles(): Observable<fromApp.State> {
    return this.store.pipe(select(fromApp.getCurrentState));
  }

  /**
   * Returns a list of Files by a given category
   *
   * @param {string} category
   * @returns {Observable<fromApp.State>}
   * @memberof FileService
   */
  getFilesByCategory(category: string): Observable<fromApp.State> {
    return this.store.select(fromApp.getListByCategory, category);
  }

  getFileTypes(): Observable<FileType[]> {
    return this.store.select(fromApp.getFileTypes);
  }

  /**
   * Returns the list of File Types by a given category
   *
   * @param {string} category
   * @returns {Observable<FileType[]>}
   * @memberof FileService
   */
  getFileTypesByCategory(category: string): Observable<FileType[]> {
    return this.store.select(fromApp.getFileTypeByCategory(category));
  }

  /**
   * Checks if there are any files in the Store,
   * returning an Observable with the information
   *
   * @returns {Observable<boolean>}
   * @memberof FileService
   */
  hasFiles(): Observable<boolean> {
    return this.http.get<IAPIFileList>(`${environment.api_route}files/ls`, {
      withCredentials: true,
      params: tableChangesToAPIParams(TableListConfigDefaults.state)
    }).pipe(
      first(),
      map(res => {
        if (res.success && res.result.length > 0) {
          return true;
        }
        return false;
      })
    )
  }

  /**
   * Returns a File for the given UUID
   *
   * @param {string} uuid
   * @returns
   * @memberof FileService
   */
  getFileByUUID(uuid: string) {
    return this.store.pipe(select(fromApp.getFileByUUID, uuid));
  }

  /**
   * Returns an Observable containing the File "Head" information
   *
   * @param {string} uuid
   * @param {string} type
   * @param {string} selectedColumn
   * @param {string} selectedKind
   * @returns {Observable<IFileInspect>}
   * @memberof FileService
   */
  getFileHead(
    uuid: string,
    type: string,
    params: HttpParams
  ): Observable<IFileInspectData> {
    return this.http
      .get<any>(`${environment.api_route}files/inspect/${uuid}`, {
        withCredentials: true,
        params: params
      })
      .pipe(
        map(res => res.result),
        switchMap((fileHead: IAPIFileInspect) => {
          return of({
            data: new FileInspectAdapter().adapt(fileHead.table_data, type),
            links: fileHead.links
          });
        })
      );
  }

  /**
   * Removes a File, given a specific UUID
   *
   * @param {string} uuid
   * @returns
   * @memberof FileService
   */
  removeFile(uuid: string) {
    const params = new HttpParams().set("uuid", uuid);
    return this.http
      .get(`${environment.api_route}uploads/rm`, {
        withCredentials: true,
        params: params
      })
      .pipe(
        tap(data => {
          if (data["success"]) {
            // update file list
            this.updateFileList();
            // show success toastr
            this.toastr.success("File removed successfully.", "File Remove");
            return;
          }

          // if not successful...error
          this.toastr.error(
            "There was an error while trying to delete the File. Please try again",
            "File Remove"
          );
        })
      );
  }

  /**
   * Checks if it is possible to remove a File, given a specific UUID
   *
   * @param {string} uuid
   * @returns {Observable<boolean>}
   * @memberof FileService
   */
  canRemove(uuid: string): Observable<boolean> {
    return this.http
      .get(`${environment.api_route}files/${uuid}`, {
        withCredentials: true,
        params: new HttpParams().set("just_check", "true")
      })
      .pipe(map(response => response["success"]));
  }

  /**
   * Downloads the corresponding File Template, given a Category and Subcategory
   *
   * @param {string} category
   * @param {string} subCategory
   * @memberof FileService
   */
  downloadTemplate(category: string, subCategory: string) {
    const fileName =
      category +
      "_" +
      subCategory
        .toLowerCase()
        .split(" ")
        .join("_") +
      "_template" +
      `.${"csv"}`;

    let params = new HttpParams();
    params = params.append("category", category);
    params = params.append("subcategory", subCategory
                                            .toLowerCase()
                                            .split(" ")
                                            .join("_"));


    this.http
      .get(`${environment.api_route}files/sample`, {
        withCredentials: true,
        responseType: "blob",
        params: params
      })
      .subscribe(res => {
        const file = new Blob([res], { type: "text/csv;charset=utf-8" });
        this.fileSaver.save(file, fileName);
      });
  }

  downloadFile(fileUrl: string, fileName: string) {
    this.http
      .get(fileUrl, {
        withCredentials: true,
        responseType: "blob"
      })
      .subscribe(res => {
        const file = new Blob([res], { type: "text/csv;charset=utf-8" });
        this.fileSaver.save(file, fileName);
      });
  }

  /**
   * Triggers a store File list update
   *
   * @memberof FileService
   */
  updateFileList() {
    this.store.dispatch(FileActions.fetchFiles());
  }

  setRecipe(recipe, recipeId = null) {
    if (isNullOrUndefined(recipeId)) {
      return this.http
      .post(`${environment.api_route}files/recipe/create`, recipe, {
        withCredentials: true,
      }).pipe(first())
    } else {
      return this.http
      .put(`${environment.api_route}files/recipe/${recipeId}`, recipe, {
        withCredentials: true,
      }).pipe(first())
    }
  }

}
