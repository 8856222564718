<div class="row">
  <div class="col-12">
    <app-upload title="Input"></app-upload>
  </div>

  <div class="col-6">
    <app-list title="Input" category='input'></app-list>
  </div>
  <div class="col-6">
    <app-list title="Parameter" category='parameter'></app-list>
  </div>
</div>
