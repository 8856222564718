import { of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { FileSaverService } from "ngx-filesaver";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, switchMap, tap, first } from "rxjs/operators";
import * as fromApp from "../store/file.reducers";
import * as FileActions from "../store/file.actions";
import { FileInspectAdapter, IAPIFileInspect, IFileInspectData } from "../../../shared/models/file-inspect.model";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../../environments/environment";
import tableChangesToAPIParams from '../../../shared/components/table-list/table-changes-to-api';
import { TableListConfigDefaults } from '../../../shared/components/table-list/table-list.model';
import { isNullOrUndefined } from 'util';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-filesaver";
import * as i4 from "ngx-toastr";
/**
 *
 * @export
 * @class FileService
 */
var FileService = /** @class */ (function () {
    /**
     * Creates an instance of FileService.
     * @param {Store<fromApp.State>} store
     * @param {HttpClient} http
     * @param {FileSaverService} fileSaver
     * @param {ToastrService} toastr
     * @memberof FileService
     */
    function FileService(store, http, fileSaver, toastr) {
        this.store = store;
        this.http = http;
        this.fileSaver = fileSaver;
        this.toastr = toastr;
    }
    /**
     * Returns an Observable containing the currentstate, amoung the list of Files
     *
     * @returns {Observable<fromApp.State>}
     * @memberof FileService
     */
    FileService.prototype.readFiles = function () {
        return this.store.pipe(select(fromApp.getCurrentState));
    };
    /**
     * Returns a list of Files by a given category
     *
     * @param {string} category
     * @returns {Observable<fromApp.State>}
     * @memberof FileService
     */
    FileService.prototype.getFilesByCategory = function (category) {
        return this.store.select(fromApp.getListByCategory, category);
    };
    FileService.prototype.getFileTypes = function () {
        return this.store.select(fromApp.getFileTypes);
    };
    /**
     * Returns the list of File Types by a given category
     *
     * @param {string} category
     * @returns {Observable<FileType[]>}
     * @memberof FileService
     */
    FileService.prototype.getFileTypesByCategory = function (category) {
        return this.store.select(fromApp.getFileTypeByCategory(category));
    };
    /**
     * Checks if there are any files in the Store,
     * returning an Observable with the information
     *
     * @returns {Observable<boolean>}
     * @memberof FileService
     */
    FileService.prototype.hasFiles = function () {
        return this.http.get(environment.api_route + "files/ls", {
            withCredentials: true,
            params: tableChangesToAPIParams(TableListConfigDefaults.state)
        }).pipe(first(), map(function (res) {
            if (res.success && res.result.length > 0) {
                return true;
            }
            return false;
        }));
    };
    /**
     * Returns a File for the given UUID
     *
     * @param {string} uuid
     * @returns
     * @memberof FileService
     */
    FileService.prototype.getFileByUUID = function (uuid) {
        return this.store.pipe(select(fromApp.getFileByUUID, uuid));
    };
    /**
     * Returns an Observable containing the File "Head" information
     *
     * @param {string} uuid
     * @param {string} type
     * @param {string} selectedColumn
     * @param {string} selectedKind
     * @returns {Observable<IFileInspect>}
     * @memberof FileService
     */
    FileService.prototype.getFileHead = function (uuid, type, params) {
        return this.http
            .get(environment.api_route + "files/inspect/" + uuid, {
            withCredentials: true,
            params: params
        })
            .pipe(map(function (res) { return res.result; }), switchMap(function (fileHead) {
            return of({
                data: new FileInspectAdapter().adapt(fileHead.table_data, type),
                links: fileHead.links
            });
        }));
    };
    /**
     * Removes a File, given a specific UUID
     *
     * @param {string} uuid
     * @returns
     * @memberof FileService
     */
    FileService.prototype.removeFile = function (uuid) {
        var _this = this;
        var params = new HttpParams().set("uuid", uuid);
        return this.http
            .get(environment.api_route + "uploads/rm", {
            withCredentials: true,
            params: params
        })
            .pipe(tap(function (data) {
            if (data["success"]) {
                // update file list
                _this.updateFileList();
                // show success toastr
                _this.toastr.success("File removed successfully.", "File Remove");
                return;
            }
            // if not successful...error
            _this.toastr.error("There was an error while trying to delete the File. Please try again", "File Remove");
        }));
    };
    /**
     * Checks if it is possible to remove a File, given a specific UUID
     *
     * @param {string} uuid
     * @returns {Observable<boolean>}
     * @memberof FileService
     */
    FileService.prototype.canRemove = function (uuid) {
        return this.http
            .get(environment.api_route + "files/" + uuid, {
            withCredentials: true,
            params: new HttpParams().set("just_check", "true")
        })
            .pipe(map(function (response) { return response["success"]; }));
    };
    /**
     * Downloads the corresponding File Template, given a Category and Subcategory
     *
     * @param {string} category
     * @param {string} subCategory
     * @memberof FileService
     */
    FileService.prototype.downloadTemplate = function (category, subCategory) {
        var _this = this;
        var fileName = category +
            "_" +
            subCategory
                .toLowerCase()
                .split(" ")
                .join("_") +
            "_template" +
            ("." + "csv");
        var params = new HttpParams();
        params = params.append("category", category);
        params = params.append("subcategory", subCategory
            .toLowerCase()
            .split(" ")
            .join("_"));
        this.http
            .get(environment.api_route + "files/sample", {
            withCredentials: true,
            responseType: "blob",
            params: params
        })
            .subscribe(function (res) {
            var file = new Blob([res], { type: "text/csv;charset=utf-8" });
            _this.fileSaver.save(file, fileName);
        });
    };
    FileService.prototype.downloadFile = function (fileUrl, fileName) {
        var _this = this;
        this.http
            .get(fileUrl, {
            withCredentials: true,
            responseType: "blob"
        })
            .subscribe(function (res) {
            var file = new Blob([res], { type: "text/csv;charset=utf-8" });
            _this.fileSaver.save(file, fileName);
        });
    };
    /**
     * Triggers a store File list update
     *
     * @memberof FileService
     */
    FileService.prototype.updateFileList = function () {
        this.store.dispatch(FileActions.fetchFiles());
    };
    FileService.prototype.setRecipe = function (recipe, recipeId) {
        if (recipeId === void 0) { recipeId = null; }
        if (isNullOrUndefined(recipeId)) {
            return this.http
                .post(environment.api_route + "files/recipe/create", recipe, {
                withCredentials: true,
            }).pipe(first());
        }
        else {
            return this.http
                .put(environment.api_route + "files/recipe/" + recipeId, recipe, {
                withCredentials: true,
            }).pipe(first());
        }
    };
    FileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileService_Factory() { return new FileService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.FileSaverService), i0.ɵɵinject(i4.ToastrService)); }, token: FileService, providedIn: "root" });
    return FileService;
}());
export { FileService };
