import { Store } from "@ngrx/store";
import { map, mergeMap, first } from "rxjs/operators";
import { Injectable, OnDestroy } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import * as fromFiles from "../../file/store/file.reducers";
import { File, Metadata, FileType } from "../../../shared/models/file.model";
import { environment } from "../../../../environments/environment";
import { IDCCalculations } from "../../../shared/models/dataset.model";
import { LaunchMeta, Filters } from "../../../shared/models/launch-rwa.model";
import { IAPILaunchCalculation, IAPICounterparty, IAPICountries, IAPIFileValidationResult, IMetadataResult, IAPIFile } from '../../../shared/models/API.model';
@Injectable({
  providedIn: "root"
})

/**
 * This Service is used as an auxiliary of the New Calculation page
 *
 * @export
 * @class NewCalSetService
 */
export class NewCalSetService implements OnDestroy {
  private http$: Subscription;
  private launchMeta: LaunchMeta;

  metadata: BehaviorSubject<Metadata>;
  selectedInputs: {
    subcategory: string;
    paramInput: IAPIFile;
  }[] = [];
  selectedParameters: {
    subcategory: string;
    paramInput: IAPIFile;
  }[] = [];
  counterpartyNames: {prop: string};

  /**
   * Creates an instance of NewCalSetService.
   * @param {HttpClient} http
   * @param {Store<fromFiles.State>} store
   * @memberof NewCalSetService
   */
  constructor(private http: HttpClient, private store: Store<fromFiles.State>) {
    this.metadata = new BehaviorSubject({
      counterparties: [],
      countries: [],
      currencies: [],
      reporting_dates: []
    });

    this.http$ = new Subscription();
  }

  /**
   * Returns an object containing the used Input Files, ordered by category
   *
   * @returns
   * @memberof NewCalSetService
   */
  getInputFiles() {
    return this.store.select(fromFiles.getInputFileState);
  }

  /**
   * Returns an Observable containing the metadata to be used on Calculator Component load
   * or, each time a selected Input File changes
   *
   * @param {string[]} uuids
   * @returns {Observable<Metadata>}
   * @memberof NewCalSetService
   */
  getReportingDate(uuids: string[]): Observable<string> {
    let params = new HttpParams();
    uuids.forEach(uuid => {
      params = params.append("file_uuids", uuid);
    });
    return this.http
      .get(`${environment.api_route}files/meta`, {
        withCredentials: true,
        params: params
      })
      .pipe(
        map((res: IMetadataResult) => {
          return res.result.reporting_dates[0];
        })
      );
  }

  /**
   * Returns an Observable containing the metadata to be used on Calculator Component load
   * or, each time a selected Input File changes
   *
   * @param {string[]} uuids
   * @returns {Observable<Metadata>}
   * @memberof NewCalSetService
   */
  getReportingDates(uuids: string[]): Observable<string[]> {
    let params = new HttpParams();
    uuids.forEach(uuid => {
      params = params.append("file_uuids", uuid);
    });
    return this.http
      .get(`${environment.api_route}files/meta`, {
        withCredentials: true,
        params: params
      })
      .pipe(
        first(),
        map((res: IMetadataResult) => {
          return res.result.reporting_dates;
        })
      );
  }

  getFilesMeta(uuids: string[], reportingDate: string): Observable<Metadata> {
        let params = new HttpParams();
        uuids.forEach(uuid => {
          params = params.append("file_uuids", uuid);
        });
        params = params.append("reporting_date", reportingDate);
        return this.getAPIMeta(params);
  }

  launchRisk(kind: string, uuid: string) {
    const formData: FormData = new FormData();
    formData.append("kind", kind);
    return this.http
    .post(`${environment.api_route}analyses/launch/risk/${uuid}`, formData, {
      withCredentials: true
    })
  }

  /**
   * Gets the Counterparty Names
   *
   * @param {string} uuid
   * @memberof NewCalSetService
   */
  getCounterpartyNames(uuid: string) {
    /* Get counterparty names */
    return this.http
      .get<IAPICounterparty>(`${environment.api_route}files/counterparties`, {
        withCredentials: true,
        params: new HttpParams().append("file_uuid", uuid)
      });
  }

  /**
   * Sets the Counterparty Names to be used for Filter Presentation
   *
   * @param {Observable<IAPICounterparty>} counterparties
   * @memberof NewCalSetService
   */
  setCounterpartyNames(counterparties: Observable<IAPICounterparty>){
    counterparties.subscribe(response => {
      console.log("SET OF COUNTERPARTY");
      this.counterpartyNames = response.result;
    })
  }

  /**
   * Returns an Observable containing the metadata to be used each time
   * there is a change on the selected Reporting Date Filter
   *
   * @param {string} reportingDate
   * @returns {Observable<Metadata>}
   * @memberof NewCalSetService
   */
  getMetadata(reportingDate: string): Observable<Metadata> {
    let params = new HttpParams();
    let existingReportingDates = [];

    this.selectedInputs.forEach(input => {
      /* Assemble Input uuids */
      if ((<any>input.paramInput).checked) {
        params = params.append("file_uuids", input.paramInput.validation_result.valid_file.uuid);
      }
    });

    // add reporting date as param
    params = params.append("reporting_date", reportingDate);
    this.metadata.subscribe(metadata => {
      // removes the reporting date and adds it to the start of the array, since it should be "the selected value"
      existingReportingDates = metadata.reporting_dates.filter(
        date => date !== reportingDate
      );
      existingReportingDates.unshift(reportingDate);
    });

    return this.getAPIMeta(params);
  }

  /**
   * Returns the Metadata for a specific File
   *
   * @private
   * @param {HttpParams} params
   * @returns {Observable<Metadata>}
   * @memberof NewCalSetService
   */
  private getAPIMeta(params: HttpParams): Observable<Metadata> {
    return this.http
      .get(`${environment.api_route}files/meta`, {
        withCredentials: true,
        params: params
      })
      .pipe(
        map((data: IMetadataResult) => {
          /* convert counterparty ids to names */
          const counterparties = [];

          data.result.counterparties.forEach(counterparty => {
            if (this.counterpartyNames[counterparty]) {
              counterparties.push(this.counterpartyNames[counterparty]);
            }
          });

          return new Metadata(
            counterparties,
            data.result.countries,
            data.result.currencies,
            data.result.reporting_dates // if we already have the reporting dates loaded, we need to use it
          );
        })
      );
  }

/**
 * Launches the RWA job on the backend
 *
 * @param {string} referenceCountry
 * @param {string} reportingDate
 * @param {string[]} countries
 * @param {string[]} counterparties
 * @param {string[]} currency
 * @param {IDCCalculations[]} fileCalculations
 * @param {string} datasetUUID
 * @param {string} approach
 * @returns {Observable<IAPILaunchCalculation>}
 * @memberof NewCalSetService
 */
launch(
    referenceCountry: string,
    reportingDate: string,
    countries: string[],
    counterparties: string[],
    currency: string[],
    fileCalculations: any,
    datasetUUID: string,
    approach: string,
    name: string
  ): Observable<IAPILaunchCalculation> {

    const filters = new Filters(
      this.getFilterData(countries),
      this.getFilterData(counterparties),
      this.getFilterData(currency)
    );

    this.launchMeta = new LaunchMeta(
      reportingDate,
      approach,
      fileCalculations,
      filters,
      datasetUUID,
      name
    );

    return this.http.post<IAPILaunchCalculation>(`${environment.api_route}analyses/create`, this.launchMeta, {
      withCredentials: true
    });
  }

  /**
   * Get the list of selected UUIDS by category
   *
   * @param {string} category
   * @param {*} selectedInputParams
   * @param {IDCCalculations[]} fileCalculations
   * @param {FileType[]} types
   * @returns
   * @memberof NewCalSetService
   */
  getSelectedUUIDS(
    category: string,
    selectedInputParams,
    fileCalculations: IDCCalculations[],
    types: FileType[]
  ) {
    function getParam(
      selected: {
        subcategory: string;
        paramInput: IAPIFileValidationResult;
      }[],
      toSearch: string
    ): string[] {
      const uuids = [];
      selected.forEach(input => {
        if (
          input.paramInput.subcategory === toSearch &&
          (<any>input.paramInput).checked
        ) {
          let filefound = false;
          /* we need to check if there is a new version of the file after it has passed validation */
          fileCalculations.forEach(file => {
            if (file.filename === input.paramInput.filename) {
              filefound = true;
              uuids.push(file.uuid);
              return;
            }
          });

          if (!filefound) {
            uuids.push(input.paramInput.uuid);
          }
        }
      });
      return uuids;
    }

    const selected = {};
    types
      .filter(type => type.categorySlug === category)
      .forEach(type => {
        selected[type.subcategorySlug] = getParam(
          selectedInputParams,
          type.subcategoryStr
        );
      });

    return selected;
  }

  /**
   * Returns the filtered array of filters.
   * When it has 'ALL' it returns an empty array
   *
   * @param {string[]} filters
   * @returns {string[]}
   * @memberof NewCalSetService
   */
  getFilterData(filters: string[]): string[] {
    return filters.indexOf("ALL") > -1 ? [] : filters;
  }

  /**
   * Returns Launch related warning messages, according to business rules
   * @returns {string[]}
   * @memberof NewCalSetService
   */
  getLaunchWarnings(): string[] {
    const warnings: string[] = [];
    return warnings;
  }

  /**
   * Returns Launch related error messages, according to business rules
   * @returns {string[]}
   * @memberof NewCalSetService
   */
  getLaunchErrors(): string[] {
    const errors: string[] = [];
    const oneSelectedInput =
      "At least one of the following files: Contract, Market, Property, Retail, needs to be selected";

    if (
      !this.selectedInputs.find(
        parameter =>
          parameter.subcategory === "contract" ||
          parameter.subcategory === "market" ||
          parameter.subcategory === "property" ||
          parameter.subcategory === "retail"
      )
    ) {
      errors.push(oneSelectedInput);
    }

    return errors;
  }

  /**
   * Returns an Observable containing the Countries
   *
   * @returns {Observable<{}>}
   * @memberof NewCalSetService
   */
  getCountriesList(): Observable<{}> {
    return this.http.get<IAPICountries>(`${environment.api_route}utils/countries`, {
      withCredentials: true
    });
  }

  /**
   *
   * @memberof NewCalSetService
   */
  ngOnDestroy() {
    this.http$.unsubscribe();
  }
}
