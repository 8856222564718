import { Subscription, combineLatest } from "rxjs";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { FileService } from "../../shared/file.service";
import { RoutingState } from "../../../../shared/services/routing-state.service";
import { ITableListDataSource, ITableListConfig, TableListColumnType, ITableListColumn, ITableListState } from '../../../../shared/components/table-list/table-list.model';
import {  IFileInspectData, IFileInspectLinks } from '../../../../shared/models/file-inspect.model';
import { MatTableDataSource } from '@angular/material/table';
import { FileErrorComponent } from './file-error/file-error.component';
import tableChangesToAPIParams from '../../../../shared/components/table-list/table-changes-to-api';
import { TableListComponent } from '../../../../shared/components/table-list/table-list.component';
import { first, distinctUntilChanged, map, filter, withLatestFrom, last } from 'rxjs/operators';

/**
 * Shows the different inspection tables (Valid, Rejected, Raw) for the specific File
 *
 * @export
 * @class InspectComponent
 * @implements {OnInit}
 */
@Component({
  selector: "app-inspect",
  templateUrl: "./inspect.component.html",
  styleUrls: ["./inspect.component.scss"]
})
export class InspectComponent implements OnInit, OnDestroy {
  @ViewChild('table', { static: false }) table: TableListComponent;
  uuid: string;
  currentSubCategory: string;
  currentFilename: string
  currentType: string;
  previousState: string;
  selectedColumn: string;
  selectedKind: string;
  currentSource: string;
  dataSource: ITableListDataSource<any>;
  tableConfig: ITableListConfig;
  currentLinks: any[] = [];
  paramsSub$: Subscription;

  /**
   * Creates an instance of InspectComponent.
   * @param {ActivatedRoute} route
   * @param {RoutingState} routingState
   * @param {FileService} fileService
   * @memberof InspectComponent
   */
  constructor(
    private route: ActivatedRoute,
    private routingState: RoutingState,
    private fileService: FileService,
    private router: Router
  ) {}

  /**
   *
   * @memberof InspectComponent
   */
  ngOnInit() {
    this.paramsSub$ = this.route.params.subscribe(params => {
      this.uuid = params["uuid"];
      this.route.queryParams.pipe(first()).subscribe(queryParams => {
        this.currentSubCategory = queryParams["subcategory"];
        this.currentFilename = queryParams["filename"]
        this.selectedColumn = queryParams["column"];
        this.selectedKind = queryParams["kind"];
        this.currentSource = queryParams["source"]
        this.loadInspectByType(params["type"]);
      })
    });
    this.previousState = this.routingState.getPreviousUrl();
  }

  getCurrentLinks(links: IFileInspectLinks) {
    this.currentLinks = [];
    Object.keys(links.next ? links.next : {}).forEach(key => {
      this.currentLinks.push({
        tag: links.next[key].tag,
        uuid: links.next[key].uuid
      })
    });
    Object.keys(links.previous ? links.previous : {}).forEach(key => {
      this.currentLinks.push({
        tag: links.previous[key].tag,
        uuid: links.previous[key].uuid
      })
    });
  }

  navigateToType(type: string) {
    this.dataSource = null;
    this.tableConfig = null;
    const uuid = this.currentLinks.find(l => l.tag === type).uuid;
    this.router.navigate(["pages", "files","inspect", uuid, type === "REJECTED" ? "summary" : type], {
      queryParams: {
        subcategory: this.currentSubCategory,
        filename: this.currentFilename
      }
    });
  }

  tableStateChanged(changes: ITableListState) {
    let params = tableChangesToAPIParams(changes)

    let tableType = this.currentType.toLowerCase() === 'valid' || this.currentType.toLowerCase() === 'raw' ? 'default' : null;
    if (!tableType) {
      tableType = this.currentType.toLowerCase() === 'summary' ?  'rejected-summary' : 'rejected-extended';
    }
    params = params.append("table", tableType);

    if (this.selectedColumn) {
      params = params
                  .append("filter_by", `ERR_COLUMN:LIKE:'${this.selectedColumn}'`)
                  .append("filter_by", `ERR_DETAIL:LIKE:'${this.selectedKind}'`)
    }

    this.fileService
      .getFileHead(this.uuid, this.currentType, params)
      .pipe(first())
      .subscribe((fileInspect: IFileInspectData) => {
        this.getCurrentLinks(fileInspect.links);
        this.tableConfig = {
          columns: this.setTypeSpecificColumns(fileInspect.data.displayedColumns.map((header, index) => {
            return {
              name: header,
              label: fileInspect.data.columnLabels[index],
              type: this.getTableType(fileInspect.data.columnTypes[header]),
              valueGetter: (e) => e[header],
              highlight: this.selectedColumn && this.selectedColumn === header ? true : false 
            }
          }) ,this.currentType)
        }
        let hasNextPage = false;
          if (fileInspect.data.dataRows.length > changes.limit) {
            fileInspect.data.dataRows.pop();
            hasNextPage = true;
        }
        this.dataSource = {
          data: new MatTableDataSource(fileInspect.data.dataRows),
          loaded: true,
          hasNextPage
        };
      });
  }


  getTableType(dtype: string) {
    switch(dtype) {
      case "float":
        return TableListColumnType.Numeric;
      case "date":
        return TableListColumnType.Date;
      default:
        return TableListColumnType.String;
    }
  }

  setTypeSpecificColumns(columns: ITableListColumn[], type: string) {
    switch (type) {
      case "summary":
        return [].concat(columns, [
          {
            name: "actions",
            label: "Actions",
            valueGetter: (e) => e,
            type: TableListColumnType.Actions,
            actions: {
              list: [
                {
                  name: "inspectError",
                  label: "Inspect Error",
                  icon: "search",
                  click: (e: any) => {
                    console.log("E", e);
                    this.router.navigate(["pages", "files", "inspect", this.uuid, "rejected"], {
                      queryParams: {
                        column: e.ERR_COLUMN,
                        kind: e.ERR_DETAIL,
                        source: this.currentSource ? this.currentSource : null
                      }
                    })
                  },
                  intent: "primary",
                }
              ]
            }
          }
        ])
      case "rejected": {
        return [].concat([
          <ITableListColumn>{
            name: "ERRORS",
            label: "",
            valueGetter: (e) => e.ERRORS,
            type: TableListColumnType.ExpandableRow,
            expand: {
              component: FileErrorComponent,
              data: {}
            }
          }
        ], columns.filter(x => x.name !== "ERR_COLUMN" && x.name !== "ERR_DETAIL"))
      }
      default:
        return columns;
    }
  }

  /**
   * Loads a specific table, by the given type
   *
   * @param {string} type
   * @memberof InspectComponent
   */
  loadInspectByType(type: string) {
    this.currentType = type ? type : 'RAW';
    this.currentLinks = [];
    this.tableConfig = {
      columns: []
    }
    if (this.table)
      this.table.reload();
  }

  ngOnDestroy(): void {
    this.paramsSub$.unsubscribe();
  }
}
