import { Store } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
import { HttpEventType } from "@angular/common/http";
import { HttpClient, HttpRequest, HttpParams } from "@angular/common/http";
import * as FileActions from "../../store/file.actions";
import { File } from "../../../../shared/models/file.model";
import { importedStates } from "../../../../shared/models/common";
import { environment } from "../../../../../environments/environment";
import * as fromApp from "../../../../store/reducers/app.reducers";
import { IAPIFileSchema } from '../../../../shared/models/API.model';

/**
 *
 * @export
 * @class UploadService
 */
@Injectable()
export class UploadService {
  /**
   * Creates an instance of UploadService.
   * @param {HttpClient} http
   * @memberof UploadService
   */
  constructor(
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private toastr: ToastrService
  ) {}

  /**
   * Uses the API to upload the selected file
   *
   * @param {string} category
   * @param {string} subCategory
   * @param {(string | Blob)} file
   * @param {string} filename
   * @memberof UploadService
   */
  public create(
    category: string,
    subCategory: string,
    filename: string
  ) {

    const formData: FormData = new FormData();
    formData.append("category", category);
    formData.append("subcategory", subCategory);
    formData.append("filename", filename);

    return this.http.post<any>(API.create, formData, {
      withCredentials: true,
    });
  }

  /**
   * Uses the API to upload the selected file
   *
   * @param {string} category
   * @param {string} subCategory
   * @param {(string | Blob)} file
   * @param {string} filename
   * @memberof UploadService
   */
  public upload(
    uuid: string,
    file: string | Blob
  ) {
    // let toastrDispatched = false;
    // create a new multipart-form for every file
    const formData: FormData = new FormData();
    formData.append("file", file);

    // create a http-post request and pass the form
    const req = new HttpRequest("POST", `${API.upload}${uuid}`, formData, {
      reportProgress: true,
      withCredentials: true
    });

    return this.http.request(req);
  }

  /**
   * Returns the file schema for a specific category/subcategory
   *
   * @param {string} category
   * @param {string} subCategory
   * @returns
   * @memberof UploadService
   */
  public getSubCategoryMetadata(category: string, subCategory: string) {
    let params = new HttpParams().set("category", category);
    params = params.set("subcategory", subCategory);

    return this.http.get<IAPIFileSchema>(`${environment.api_route}files/schema`, {
      params: params,
      withCredentials: true
    });
  }
}

const API = {
  create: `${environment.api_route}files/create`,
  upload: `${environment.api_route}files/upload/`
};
