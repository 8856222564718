import { NgModule, Type } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from "./app.component";

// Import Main Layout
import { MainLayoutComponent } from "./core/main-layout/main-layout.component";
import { NotificationItemComponent } from "./core/notifications/notification-item/notification-item.component";

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule
} from "@coreui/angular";

// Import routing module
import { AppRoutingModule } from "./app.routing";

// Import 3rd party components
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

import { ChartsModule } from "ng2-charts/ng2-charts";
import { MaterialModule } from "./material.module";
import { ToastrModule } from "ngx-toastr";
import { TabsModule } from "ngx-bootstrap/tabs";
import { UploadService } from "./modules/file/components/upload/upload.service";
import { UserService } from "./shared/services/user.service";
import { AuthGuard } from "./shared/guards/auth.guard";
import { LoginPageComponent } from "./core/login/login/login.component";
import { FileSaverModule } from "ngx-filesaver";

import "hammerjs";

// Template needed dependencies
import "popper.js";
import "jquery";
import "bootstrap";
import { StoreModule } from "@ngrx/store";
import {
  StoreRouterConnectingModule,
  RouterStateSerializer
} from "@ngrx/router-store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { EffectsModule } from "@ngrx/effects";

// NgRX Main Store loading
import { FileModule } from "./modules/file/file.module";
import { NewCalSetService } from "./modules/calculation/shared/new-calculation-settings.service";
import { ConfirmDialogComponent } from "./shared/components/confirm-dialog/confirm-dialog.component";
import { FilesGuard } from "./shared/guards/files.guard";
import { DatasetsGuard } from "./shared/guards/datasets.guard";
import { SharedModule } from "./shared/components/shared.module";

import { CustomSerializer } from "./store";
import { RoutingState } from "./shared/services/routing-state.service";
import { CalculationsGuard } from "./shared/guards/analyses.guard";
import { DYNAMIC_FORM_CONTROL_MAP_FN, DynamicFormControlModel, DynamicFormControl, DynamicFormValueControlModel } from '@ng-dynamic-forms/core';
import { SelectFromTableFormControlComponent } from './modules/dataset/simulation/select-from-table-form-control/select-from-table-form-control.component';
import { AddToStepButtonComponent } from './modules/dataset/stress-test/add-to-step-button/add-to-step-button.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    MaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    FormsModule,
    FileSaverModule,
    FileModule,
    StoreModule.forRoot([]),

    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    SharedModule
  ],

  declarations: [
    AppComponent,
    MainLayoutComponent,
    NotificationItemComponent,
    LoginPageComponent,
    ConfirmDialogComponent
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: CustomSerializer
    },
    {
      provide: DYNAMIC_FORM_CONTROL_MAP_FN,
      useValue: (model: DynamicFormControlModel): Type<DynamicFormControl> | null  => {
        
        switch (model.type) {
          case "SELECT_FROM_TABLE_FORM_CONTROL":
            return SelectFromTableFormControlComponent;
          case "BUTTON_FORM_CONTROL":
            return AddToStepButtonComponent;
        }
      }
    },

    UploadService,
    UserService,
    AuthGuard,
    NewCalSetService,
    FilesGuard,
    DatasetsGuard,
    CalculationsGuard,
    RoutingState
  ],
  entryComponents: [ConfirmDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
