import { Observable } from "rxjs";
import { tap, map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Store } from "@ngrx/store";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as fromApp from "../store/dataset.reducers";
import { FileType } from "../../../shared/models/file.model";
import * as DatasetActions from "../store/dataset.actions";
import { DatasetAdapter } from "../../../shared/models/dataset.model";
import { environment } from "../../../../environments/environment";
import { NewCalSetService } from "../../calculation/shared/new-calculation-settings.service";
import * as i0 from "@angular/core";
import * as i1 from "../../calculation/shared/new-calculation-settings.service";
import * as i2 from "@angular/common/http";
import * as i3 from "@ngrx/store";
import * as i4 from "ngx-toastr";
import * as i5 from "../../../shared/models/dataset.model";
/**
 *
 * @export
 * @class DatasetService
 */
var DatasetService = /** @class */ (function () {
    /**
     * Creates an instance of DatasetService.
     * @param {NewCalSetService} newCalcSetService
     * @param {HttpClient} http
     * @param {Store<fromApp.State>} store
     * @param {ToastrService} toastr
     * @memberof DatasetService
     */
    function DatasetService(newCalcSetService, http, store, toastr, dsAdapter) {
        this.newCalcSetService = newCalcSetService;
        this.http = http;
        this.store = store;
        this.toastr = toastr;
        this.dsAdapter = dsAdapter;
    }
    /**
     * Creates a Dataset
     *
     * @param {string} datasetName
     * @returns {Observable<IAPIDatasetCreate>}
     * @memberof DatasetService
     */
    DatasetService.prototype.createDataset = function (datasetName, types, selection) {
        var inputs = types.filter(function (x) { return x.categorySlug === 'input'; }).reduce(function (acc, curr) {
            var res = selection.filter(function (x) { return x.type.subcategorySlug === curr.subcategorySlug; });
            acc[curr.subcategorySlug] = res && res.length > 0 && res[0].uuid ? res.map(function (x) { return x.uuid; }) : [];
            return acc;
        }, {});
        var parameters = types.filter(function (x) { return x.categorySlug === 'parameter'; }).reduce(function (acc, curr) {
            acc[curr.subcategorySlug] = selection.filter(function (x) { return x.type.subcategorySlug === curr.subcategorySlug; }).map(function (x) { return x.uuid; });
            return acc;
        }, {});
        return this.http.post(environment.api_route + "collections/create", {
            input_uuids: inputs,
            param_uuids: parameters,
            name: datasetName
        }, {
            withCredentials: true
        });
    };
    /**
     * Returns an object of uuids, indexed by subcategories, given a specific category as input
     *
     * @param {string} category
     * @param {{
     *       subcategorySlug: string;
     *       subcategoryStr: string;
     *       paramInput: File;
     *     }[]} selectedInputParams
     * @param {FileType[]} types
     * @returns {{}}
     * @memberof DatasetService
     */
    DatasetService.prototype.getSelectedUUIDS = function (category, selectedInputParams, types) {
        function getParam(selected, toSearch) {
            var uuids = [];
            selected.forEach(function (input) {
                return input.paramInput.subcategory === toSearch &&
                    input.paramInput.checked
                    ? uuids.push(input.paramInput.uuid)
                    : "";
            });
            return uuids;
        }
        var selected = {};
        types
            .filter(function (type) { return type.categorySlug === category; })
            .forEach(function (type) {
            if (!selected[type.subcategorySlug]) {
                selected[type.subcategorySlug] = [];
            }
            selected[type.subcategorySlug] = getParam(selectedInputParams, type.subcategoryStr);
        });
        return selected;
    };
    /**
     * Returns a Dataset for the given UUID
     *
     * @param {string} uuid
     * @returns {Observable<Dataset>}
     * @memberof DatasetService
     */
    DatasetService.prototype.getDatasetByUUID = function (uuid) {
        return this.http.get(environment.api_route + "collections/ls", {
            withCredentials: true,
            params: new HttpParams().set("filter_by", "uuid:=:'" + uuid + "'")
        }).pipe(map(function (cols) { return cols.result[0]; }));
    };
    DatasetService.prototype.getDatasetIngestionFiles = function (uuids) {
        return this.http.get(environment.api_route + "files/ls", {
            withCredentials: true,
            params: new HttpParams().set("filter_by", "uuid:IN:(" + uuids.join(',') + ")")
        }).pipe(map(function (cols) { return cols['result']; }));
    };
    /**
     * Returns all Datasets and the current State
     *
     * @returns {Observable<fromApp.State>}
     * @memberof DatasetService
     */
    DatasetService.prototype.readDatasets = function (params) {
        //return this.store.pipe(select(fromApp.getCurrentState));
        return this.http.get(environment.api_route + "collections/ls", {
            withCredentials: true,
            params: params
        }).pipe(map(function (cols) { return cols.result; }));
    };
    DatasetService.prototype.getDatasetStatus = function (ds) {
        var badRowsCount = 0;
        var totalRowsCount = 0;
        if (ds.validation_result && ds.validation_result.file_validations) {
            Object.keys(ds.validation_result.file_validations).forEach(function (k) {
                badRowsCount += parseInt(ds.validation_result.file_validations[k].bad_rows);
                totalRowsCount += parseInt(ds.validation_result.file_validations[k].total_rows);
            });
        }
        return {
            status: ds.validation_status,
            badRows: badRowsCount,
            totalRows: totalRowsCount
        };
    };
    /**
     * Checks if the Dataset removal is possible
     *
     * @param {string} uuid
     * @returns {Observable<boolean>}
     * @memberof DatasetService
     */
    DatasetService.prototype.canRemove = function (uuid) {
        var params = new HttpParams().set("just_check", "true");
        return this.http
            .delete(environment.api_route + "collections/" + uuid, {
            withCredentials: true,
            params: params
        })
            .pipe(map(function (response) { return response["success"]; }));
    };
    /**
     * Removes a specific Dataset
     *
     * @param {string} uuid
     * @returns
     * @memberof DatasetService
     */
    DatasetService.prototype.removeDataset = function (uuid) {
        var _this = this;
        return this.http
            .delete(environment.api_route + "collections/" + uuid, {
            withCredentials: true
        })
            .pipe(tap(function (data) {
            if (data["success"]) {
                // update dataset list
                _this.updateDatasetList();
                // show success toastr
                _this.toastr.success("Dataset removed successfully.", "Dataset Remove");
                return;
            }
            // if not successful...error
            _this.toastr.error("There was an error while trying to delete the File. Please try again", "File Remove");
        }));
    };
    DatasetService.prototype.currentStatus = function () {
        return this.store.select(fromApp.getCurrentState);
    };
    /**
     * Triggers the Datset list update
     *
     * @memberof DatasetService
     */
    DatasetService.prototype.updateDatasetList = function () {
        this.store.dispatch(DatasetActions.fecthCols());
    };
    DatasetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DatasetService_Factory() { return new DatasetService(i0.ɵɵinject(i1.NewCalSetService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Store), i0.ɵɵinject(i4.ToastrService), i0.ɵɵinject(i5.DatasetAdapter)); }, token: DatasetService, providedIn: "root" });
    return DatasetService;
}());
export { DatasetService };
