import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../shared/services/auth.service";
import { UserService } from "../../../shared/services/user.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/reducers/app.reducers";
import * as FileActions from "../../../modules/file/store/file.actions";
import * as DatasetActions from "../../../modules/dataset/store/dataset.actions";
import * as CalculationActions from "../../../modules/calculation/store/calculation.actions";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginPageComponent implements OnInit, OnDestroy {
  private homePage: string = "/pages/calculation/list/collections";
  private login$: Subscription;
  loginForm = new FormGroup({
    username: new FormControl("", Validators.required),
    password: new FormControl("", Validators.required)
  });

  showAlert = false;

  constructor(
    private auth: AuthService,
    private user: UserService,
    private router: Router,
    private store: Store<fromApp.AppState>
  ) {
    this.login$ = new Subscription();
  }

  ngOnInit(): void {
    if (this.user.isLogged()) {
      console.log("I'm logged, redirect");
      this.router.navigate([this.homePage]);
    }
  }

  login() {
    this.login$ = this.auth
      .login(
        this.loginForm.get("username").value,
        this.loginForm.get("password").value
      )
      .subscribe(
        r => {
          if (r.success) {
            this.store.dispatch(DatasetActions.clearCols());
            this.store.dispatch(CalculationActions.clearCalculation());
            this.showAlert = false;
            this.user.setToken(r.jwt);
            this.router.navigateByUrl(this.homePage);
          }
        },
        r => {
          this.showAlert = true;
        }
      );
  }

  ngOnDestroy() {
    this.login$.unsubscribe();
  }
}
