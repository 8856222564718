import * as i0 from "@angular/core";
var TOKEN = "TOKEN";
var UserService = /** @class */ (function () {
    function UserService() {
    }
    /**
     * Returns the user token from local storage
     *
     * @returns {string}
     * @memberof UserService
     */
    UserService.prototype.getToken = function () {
        return localStorage.getItem(TOKEN);
    };
    /**
     * Sets the user token on local storage
     *
     * @param {string} token
     * @memberof UserService
     */
    UserService.prototype.setToken = function (token) {
        localStorage.setItem(TOKEN, token);
    };
    /**
     * Deletes the user token from local storage
     *
     * @memberof UserService
     */
    UserService.prototype.deleteToken = function () {
        localStorage.removeItem(TOKEN);
    };
    /**
     * Checks if the user has a stored token
     *
     * @returns
     * @memberof UserService
     */
    UserService.prototype.isLogged = function () {
        return localStorage.getItem(TOKEN) != null;
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
