import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromApp from "../../modules/file/store/file.reducers";
import { Observable, of } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import * as FileActions from "../../modules/file/store/file.actions";

@Injectable()
export class FilesGuard implements CanActivate {
  constructor(private store: Store<fromApp.State>) {}

  /**
   * Returns the list of Files from the Store (if they exist) or from the API
   *
   * @returns {Observable<File>}
   * @memberof FilesGuard
   */
  getFromStoreOrAPI(): Observable<File> {
    return this.store.select("files").pipe(
      tap((data: any) => {
        if (!data || !data.files || !data.files.length) {
          this.store.dispatch(FileActions.fetchFileTypes());
        }
      })
    );
  }

  /**
   * Checks if the route can be activated
   *
   * @returns {(Observable<boolean> | boolean)}
   * @memberof FilesGuard
   */
  canActivate(): Observable<boolean> | boolean {
    return this.getFromStoreOrAPI().pipe(switchMap(() => of(true)));
  }
}
