import { Injectable, OnDestroy } from "@angular/core";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import {
  Notification,
  NotificationAdapter
} from "../models/notification.model";
import { UserService } from "./user.service";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/reducers/app.reducers";
import * as FileActions from "../../modules/file/store/file.actions";
import * as DatasetActions from "../../modules/dataset/store/dataset.actions";
import * as CalculationActions from "../../modules/calculation/store/calculation.actions";
import { retryWhen, tap, delay, first } from "rxjs/operators";
import { Observable, of, Subject } from "rxjs";
import { CalculationService } from "../../modules/calculation/shared/calculation.service";
import { DatasetService } from "../../modules/dataset/shared/dataset.service";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { FileService } from '../../modules/file/shared/file.service';

@Injectable({
  providedIn: "root"
})
export class UiNotificationService implements OnDestroy {
  notifications: Notification[] = [];
  totalNotifications = 0;
  notifications$: Subject<Notification[]> = new Subject();
  newNotification$: Subject<any> = new Subject();
  //notificationSocket: WebSocketSubject<any>;
  initialUpdate = true;
  evtSource: EventSource;
  keepAliveSec: 1;

  /**
   * Creates an instance of UiNotificationService.
   * @param {UserService} userService
   * @param {NotificationAdapter} adapter
   * @param {Store<fromApp.AppState>} store
   * @param {CalculationService} calculationService
   * @param {DatasetService} datasetService
   * @memberof UiNotificationService
   */
  constructor(
    public datasetService: DatasetService,
    private http: HttpClient,
    private adapter: NotificationAdapter,
    private fileService: FileService,
    private calculationService: CalculationService
  ) {

    this.initEvtSource();
    this.evtSource.onmessage = (event) => {
      const newNotification = JSON.parse(event.data);
      this.notifications = [
        newNotification,
        ...this.notifications
      ]
      this.getNotificationDetails([`'${newNotification.payload.job_uuid}'`], newNotification.id);
    }
    this.evtSource.onerror = (err) => {
      this.evtSource.close();
      setTimeout(this.initEvtSource, this.keepAliveSec * 1000);
      this.keepAliveSec < 65 ? this.keepAliveSec *= 2 : 64;
    }
  }

  initEvtSource() {
    this.evtSource = new EventSource(`${environment.api_route}notifications/sse`, { withCredentials: true });
  }

  notifyStore(notification) {
    switch(notification.data.qualified_func.function) {
      case "validate_file":
        this.fileService.updateFileList();
        break;
      case "validate_data_collection":
        this.datasetService.updateDatasetList();
        break;
      case "generate_csv":
        console.log(notification.data.status)
        if (notification.data.status == "SUCCEEDED") {
          this.fileService.downloadFile(
            `${environment.api_route}${notification.data.result.get_url.substring(1)}`,  // Because starts with slash. 
            notification.data.result.filename
          );
        }
        console.log(notification)
      default:
        this.calculationService.updateCalculationList();
    }
  }

  /**
   * Returns an array of notifications
   *
   * @returns {Notification[]}
   * @memberof UiNotificationService
   */
  getNotifications(): Notification[] {
    return this.notifications;
  }

  /**
   * Creates the notifications Websocket connection
   *
   * @memberof UiNotificationService
   */
  updateNotifications(skip: number = 0) {
    let params = new HttpParams();
    params = params.append("limit", "15");
    params = params.append("skip", `${skip}`);
    params = params.append("sort_by", "sent_at:DESC");
    this.http.get<any>(`${environment.api_route}notifications/events`, {
      withCredentials: true,
      params: params
    }).pipe(first()).subscribe(res => {

      const newNotifications = res.result.rows.map(r => this.adapter.adapt(r, res.result.headers));
      if (newNotifications && newNotifications.length > 0) {
        this.notifications = [
          ...this.notifications,
          ...newNotifications
        ];
        this.getNotificationDetails(newNotifications.map(r => `'${r.payload.job_uuid}'`));
      }
    })
  }

  getNotificationDetails(ids: string[], notifyId = null) {
    let params = new HttpParams();
    params = params.append("filter_by", `uuid:IN:(${ids.join(',')})`)
    this.http.get<any>(`${environment.api_route}jobs/ls`, {
      withCredentials: true,
      params: params
    }).pipe(first()).subscribe(res => {
      this.notifications = this.notifications.map((n, i) => {
        const nData = res.result.find(x => x.uuid === n.payload.job_uuid);
        return {
          ...n,
          data: n.data ? n.data : nData,
          acked_at: n.acked_at ? n.acked_at : ""
        }
      });
      this.notifications$.next(this.notifications);
      if (notifyId) {
        this.newNotification$.next(this.notifications.find(x => x.id === notifyId));
        this.notifyStore(this.notifications.find(x => x.id === notifyId));
      }
    });
  }

  /**
   * Marks all new notifications as being read
   *
   * @memberof UiNotificationService
   */
  markAllRead() {
    const toAck = this.notifications.reduce((acc: number[], curr: Notification) => {
      // if new and not shown before, ack it on backend, mark it as not new
      if (curr.acked_at === "") {
        acc.push(curr.id);
      }
      return acc;
    }, []);
    this.http.post<any>(`${environment.api_route}notifications/ack`, toAck, {
      withCredentials: true
    }).pipe(first()).subscribe(res => {
      if (res.success) {
      }
    })
  }

  ackNotification(id) {
    this.http.post<any>(`${environment.api_route}notifications/ack`, [id], {
      withCredentials: true
    }).pipe(first()).subscribe(res => {
      if (res.success) {
        console.log("NOTIFICATION ACKNOWNLAGED", this.notifications.find(x => x.id === id), this);
        this.notifications.find(x => x.id === id).acked_at = new Date().toISOString();
        this.notifications$.next(this.notifications);
      }
    })
  }

  /**
   * Initializes notifications
   *
   * @memberof UiNotificationService
   */
  initNotifications(update = true) {
    this.notifications = [];
    this.totalNotifications = 0;
    if (update) {
      this.updateNotifications();
    }
  }

  killNotifications() {
    this.evtSource.close();
  }

  ngOnDestroy() {
    this.killNotifications();
  }
}
