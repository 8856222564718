<div mat-menu-item *ngIf="notification.data">
    <ng-container [ngSwitch]="notification.data.qualified_func.function">
        <ng-container *ngSwitchCase="'validate_file'">
            <div class="notification-content-container" (click)="goTo(['/pages/files/inspect', notification.data.result.uuid, 'valid'])">
                <div class="notification-new" *ngIf="notification.acked_at === ''">
                        <mat-icon>new_releases</mat-icon>
                </div>
                <div>
                    <div class="mb-1"><small><strong>
                        File Validation {{ notification.payload.value | titlecase }}
                    </strong></small></div>
                    <div class="file-name"><small class="text-muted">{{ notification.data.result.filename }}</small></div>
                    <small class="text-muted">{{ notification.sent_at | date:'yyyy/MM/dd HH:mm:ss' }}</small>
                </div>
                <div class="notification-actions">
                    <mat-icon>arrow_forward</mat-icon>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'credit_risk'">
            <div class="notification-content-container" (click)="goTo(['/pages/calculation/results', notification.data.args.args[0].cal_uuid])">
                <div class="notification-new" *ngIf="notification.acked_at === ''">
                        <mat-icon>new_releases</mat-icon>
                </div>
                <div>
                    <div class="mb-1"><small><strong>
                        Credit Risk {{ notification.payload.value | titlecase }}
                    </strong></small></div>
                    <div class="file-name"><small class="text-muted">Status: {{ notification.data.status | titlecase }}</small></div>
                    <div class="file-name" *ngIf="notification.data.result.warnings">
                        <small class="text-muted">Warnings: {{ notification.data.result.warnings.length }}</small>
                    </div>
                    <small class="text-muted">{{ notification.sent_at | date:'yyyy/MM/dd HH:mm:ss' }}</small>
                </div>
                <div class="notification-actions">
                    <mat-icon>arrow_forward</mat-icon>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'market_risk'">
            <div class="notification-content-container" (click)="goTo(['/pages/calculation/results', notification.data.args.args[0].cal_uuid, 'market'])">
                <div class="notification-new" *ngIf="notification.acked_at === ''">
                        <mat-icon>new_releases</mat-icon>
                </div>
                <div>
                    <div class="mb-1"><small><strong>
                        Market Risk {{ notification.payload.value | titlecase }}
                    </strong></small></div>
                    <div class="file-name"><small class="text-muted">Status: {{ notification.data.status | titlecase }}</small></div>
                    <div class="file-name" *ngIf="notification.data.result.warnings">
                        <small class="text-muted">Warnings: {{ notification.data.result.warnings.length }}</small>
                    </div>
                    <small class="text-muted">{{ notification.sent_at | date:'yyyy/MM/dd HH:mm:ss' }}</small>
                </div>
                <div class="notification-actions">
                    <mat-icon>arrow_forward</mat-icon>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'validate_data_collection'">
            <div class="notification-content-container" (click)="goTo(['/pages/datasets/details', notification.data.result.uuid])">
                <div class="notification-new" *ngIf="notification.acked_at === ''">
                        <mat-icon>new_releases</mat-icon>
                </div>
                <div>
                    <div class="mb-1"><small><strong>
                        Collection Validation {{ notification.payload.value | titlecase }}
                    </strong></small></div>

                    <div class="file-name"><small class="text-muted">Status: {{ notification.data.result.status | titlecase }}</small></div>
                    <div class="file-name" *ngIf="notification.data.result.tables_validated">
                        <small class="text-muted">Tables Validated: {{ notification.data.result.tables_validated.length }}</small>
                    </div>
                    <small class="text-muted">{{ notification.sent_at | date:'yyyy/MM/dd HH:mm:ss' }}</small>
                </div>
                <div class="notification-actions">
                    <mat-icon>arrow_forward</mat-icon>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'generate_csv'">
                <div class="notification-content-container" (click)="downloadFile(notification.data.result.signed_url, notification.data.result.filename)">
                    <div class="notification-new" *ngIf="notification.acked_at === ''">
                            <mat-icon>new_releases</mat-icon>
                    </div>
                    <div>
                        <div class="mb-1">
                            <small>
                                <strong>
                                    CSV Generated
                                </strong>
                            </small>
                        </div>
                        <small class="text-muted">{{ notification.sent_at | date:'yyyy/MM/dd HH:mm:ss' }}</small>
                    </div>
                    <div class="notification-actions">
                        <mat-icon>arrow_forward</mat-icon>
                    </div>
                </div>
            </ng-container>
    </ng-container>
</div>