<div class="animated fadeIn">
  <div class="card core-card">
    <div class="card-header core-card-header">
      <div class="ml-2"><em class="icon-equalizer"></em> Import new File</div>
    </div>
    <div class="card-body core-card-body">
      <div class="row">
        <div class="col-6">
          <mat-horizontal-stepper [linear]=false #stepper>

            <!-- STEP 1 Select Type-->
            <mat-step>
              <ng-template matStepLabel>Select the Type</ng-template>
              <mat-form-field>
                <mat-label>Select the Type</mat-label>
                <mat-select [formControl]="subCategory" (valueChange)="stepper.next()" data-cy="subcategoryDropdown">
                  <mat-optgroup label="Inputs">
                    <mat-option *ngFor="let type of inputTypes" [value]="['input', type.subcategorySlug]">
                      {{type.subcategoryStr}}
                    </mat-option>
                  </mat-optgroup>
                  <mat-optgroup label="Parameters">
                    <mat-option *ngFor="let type of parameterTypes" [value]="['parameter', type.subcategorySlug]">
                      {{type.subcategoryStr}}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
            </mat-step>

            <!-- STEP 2 Select FILE-->
            <mat-step>
              <ng-template matStepLabel>Select a File</ng-template>
              <ngx-dropzone class="custom-dropzone" (change)="onFilesAdded($event, stepper)" [multiple]="false">
              </ngx-dropzone>
            </mat-step>
          </mat-horizontal-stepper>
        </div>
        <div class="col-6">
          <!-- FILE Templates-->
          <div class="row mt-4">
            <div class="col-6">
              <h5 class="mb-3">Input File Templates</h5>
            </div>
            <div class="col-6">
              <h5 class="mb-3">Parameter File Templates</h5>
            </div>
            <div class="col-6">
              <div class="col-12" *ngFor="let type of inputTypes" data-cy="inputfileTemplates">
                <p><a (click)="openTemplateDialog('input', type.subcategorySlug, type.subcategoryStr)">
                    <h6><em class="fa fa-list"></em> {{type.subcategoryStr}}</h6>
                  </a></p>
              </div>
            </div>
            <div class="col-6">
              <div class="col-12" *ngFor="let type of parameterTypes">
                <p><a (click)="openTemplateDialog('parameter', type.subcategorySlug, type.subcategoryStr)">
                    <h6><em class="fa fa-list"></em> {{type.subcategoryStr}}</h6>
                  </a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
