import { Routes } from "@angular/router";
// Import Containers
import { MainLayoutComponent } from "./core/main-layout/main-layout.component";
import { LoginPageComponent } from "./core/login/login/login.component";
import { AuthGuard } from "./shared/guards/auth.guard";
var ɵ0 = {
    title: "Login Page"
}, ɵ1 = {
    title: "Home"
}, ɵ2 = function () {
    return import("./modules/calculation/calculation.module.ngfactory").then(function (m) { return m.CalculationModuleNgFactory; });
}, ɵ3 = function () {
    return import("./modules/file/file.module.ngfactory").then(function (m) { return m.FileModuleNgFactory; });
}, ɵ4 = function () {
    return import("./modules/dataset/dataset.module.ngfactory").then(function (m) { return m.DatasetModuleNgFactory; });
}, ɵ5 = function () {
    return import("./modules/dataset/dataset.module.ngfactory").then(function (m) { return m.DatasetModuleNgFactory; });
};
/*
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
 */
export var routes = [
    {
        path: "",
        redirectTo: "login",
        pathMatch: "full"
    },
    /*  {
      path: '404',
      component: P404Component,
      data: {
        title: 'Page 404'
      }
    },
    {
      path: '500',
      component: P500Component,
      data: {
        title: 'Page 500'
      }
    },
    {
      path: 'login',
      component: LoginComponent,
      data: {
        title: 'Login Page'
      }
    },
    {
      path: 'register',
      component: RegisterComponent,
      data: {
        title: 'Register Page'
      }
    }, */
    {
        path: "login",
        component: LoginPageComponent,
        data: ɵ0
    },
    {
        path: "pages",
        component: MainLayoutComponent,
        canActivate: [AuthGuard],
        data: ɵ1,
        children: [
            {
                path: "calculation",
                loadChildren: ɵ2,
                canActivate: [AuthGuard]
            },
            {
                path: "files",
                loadChildren: ɵ3,
                canActivate: [AuthGuard]
            },
            {
                path: "datasets",
                loadChildren: ɵ4,
                canActivate: [AuthGuard]
            },
            {
                path: "simulations",
                loadChildren: ɵ5,
                canActivate: [AuthGuard]
            }
        ]
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
