import { createAction, props } from "@ngrx/store";
import { Dataset } from "../../../shared/models/dataset.model";

export const setCols = createAction(
  "[Datasets] SET",
  props<{ datasets: Dataset[] }>()
);
export const addCols = createAction(
  "[Datasets] ADD",
  props<{ payload: Dataset }>()
);
export const fecthCols = createAction("[Datasets] FETCH");
export const deleteCol = createAction(
  "[Datasets] DELETE",
  props<{ dataset: Dataset }>()
);
export const clearCols = createAction("[Datasets] CLEAR");
