import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
/**
 * Generates a "remove/cancel" dialog
 *
 * @export
 * @class ListRemoveDialogComponent
 */
@Component({
  selector: "app-list-remove-dialog",
  templateUrl: "./list-remove-dialog.component.html",
  styleUrls: ["./list-remove-dialog.component.scss"]
})
export class ListRemoveDialogComponent {
  /**
   *Creates an instance of ListRemoveDialogComponent.
   * @param {MatDialogRef<ListRemoveDialogComponent>} dialogRef
   * @param {{
   *       title: string;
   *       uuid: string;
   *       errors: string[];
   *       redirectRoute: string[];
   *     }} data
   * @memberof ListRemoveDialogComponent
   */
  constructor(
    public dialogRef: MatDialogRef<ListRemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      uuid: string;
      errors: string[];
      redirectRoute: string[];
    }
  ) {}

  /**
   * Closes the dialog
   *
   * @memberof ListRemoveDialogComponent
   */
  close() {
    this.dialogRef.close();
  }
}
