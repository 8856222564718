import * as DatasetActions from "./dataset.actions";
import { Dataset } from "../../../shared/models/dataset.model";
import {
  createReducer,
  on,
  Action,
  createFeatureSelector,
  createSelector
} from "@ngrx/store";

export interface State {
  needsReload: boolean;
  loaded: boolean;
}

const initialState = {
  needsReload: false,
  loaded: false
};

const datasetsReducer = createReducer(
  initialState,
  on(DatasetActions.fecthCols, state => ({
    ...state,
    needsReload: true,
    loaded: false
  })),
  on(DatasetActions.setCols, (state, { datasets }) => ({
    ...state,
    needsReload: false,
    loaded: true
  })),
  on(DatasetActions.clearCols, state => ({
    ...state,
    datasets: undefined
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return datasetsReducer(state, action);
}

export const getDatasetsState = createFeatureSelector("datasets");

export const getCurrentState = createSelector(
  getDatasetsState,
  (state: State) => state
);
