<div class="animated fadeIn">
  <div class="card core-card">
    <div class="card-header core-card-header">
      <div class="ml-2"><em class="icon-equalizer"></em> Imported {{title}} Files</div>
    </div>
    <div class="card-body core-card-body">
      <app-table-list [dataSource]="dataSource" [config]="tableListConfig"
        (tableStateChanged)="this.tableStateChanged($event)">
      </app-table-list>
    </div>
  </div>
</div>