import * as tslib_1 from "tslib";
import { OnDestroy } from "@angular/core";
import { NotificationAdapter } from "../models/notification.model";
import { first } from "rxjs/operators";
import { Subject } from "rxjs";
import { CalculationService } from "../../modules/calculation/shared/calculation.service";
import { DatasetService } from "../../modules/dataset/shared/dataset.service";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from '@angular/common/http';
import { FileService } from '../../modules/file/shared/file.service';
import * as i0 from "@angular/core";
import * as i1 from "../../modules/dataset/shared/dataset.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../models/notification.model";
import * as i4 from "../../modules/file/shared/file.service";
import * as i5 from "../../modules/calculation/shared/calculation.service";
var UiNotificationService = /** @class */ (function () {
    /**
     * Creates an instance of UiNotificationService.
     * @param {UserService} userService
     * @param {NotificationAdapter} adapter
     * @param {Store<fromApp.AppState>} store
     * @param {CalculationService} calculationService
     * @param {DatasetService} datasetService
     * @memberof UiNotificationService
     */
    function UiNotificationService(datasetService, http, adapter, fileService, calculationService) {
        var _this = this;
        this.datasetService = datasetService;
        this.http = http;
        this.adapter = adapter;
        this.fileService = fileService;
        this.calculationService = calculationService;
        this.notifications = [];
        this.totalNotifications = 0;
        this.notifications$ = new Subject();
        this.newNotification$ = new Subject();
        //notificationSocket: WebSocketSubject<any>;
        this.initialUpdate = true;
        this.initEvtSource();
        this.evtSource.onmessage = function (event) {
            var newNotification = JSON.parse(event.data);
            _this.notifications = tslib_1.__spread([
                newNotification
            ], _this.notifications);
            _this.getNotificationDetails(["'" + newNotification.payload.job_uuid + "'"], newNotification.id);
        };
        this.evtSource.onerror = function (err) {
            _this.evtSource.close();
            setTimeout(_this.initEvtSource, _this.keepAliveSec * 1000);
            _this.keepAliveSec < 65 ? _this.keepAliveSec *= 2 : 64;
        };
    }
    UiNotificationService.prototype.initEvtSource = function () {
        this.evtSource = new EventSource(environment.api_route + "notifications/sse", { withCredentials: true });
    };
    UiNotificationService.prototype.notifyStore = function (notification) {
        switch (notification.data.qualified_func.function) {
            case "validate_file":
                this.fileService.updateFileList();
                break;
            case "validate_data_collection":
                this.datasetService.updateDatasetList();
                break;
            case "generate_csv":
                console.log(notification.data.status);
                if (notification.data.status == "SUCCEEDED") {
                    this.fileService.downloadFile("" + environment.api_route + notification.data.result.get_url.substring(1), // Because starts with slash. 
                    notification.data.result.filename);
                }
                console.log(notification);
            default:
                this.calculationService.updateCalculationList();
        }
    };
    /**
     * Returns an array of notifications
     *
     * @returns {Notification[]}
     * @memberof UiNotificationService
     */
    UiNotificationService.prototype.getNotifications = function () {
        return this.notifications;
    };
    /**
     * Creates the notifications Websocket connection
     *
     * @memberof UiNotificationService
     */
    UiNotificationService.prototype.updateNotifications = function (skip) {
        var _this = this;
        if (skip === void 0) { skip = 0; }
        var params = new HttpParams();
        params = params.append("limit", "15");
        params = params.append("skip", "" + skip);
        params = params.append("sort_by", "sent_at:DESC");
        this.http.get(environment.api_route + "notifications/events", {
            withCredentials: true,
            params: params
        }).pipe(first()).subscribe(function (res) {
            var newNotifications = res.result.rows.map(function (r) { return _this.adapter.adapt(r, res.result.headers); });
            if (newNotifications && newNotifications.length > 0) {
                _this.notifications = tslib_1.__spread(_this.notifications, newNotifications);
                _this.getNotificationDetails(newNotifications.map(function (r) { return "'" + r.payload.job_uuid + "'"; }));
            }
        });
    };
    UiNotificationService.prototype.getNotificationDetails = function (ids, notifyId) {
        var _this = this;
        if (notifyId === void 0) { notifyId = null; }
        var params = new HttpParams();
        params = params.append("filter_by", "uuid:IN:(" + ids.join(',') + ")");
        this.http.get(environment.api_route + "jobs/ls", {
            withCredentials: true,
            params: params
        }).pipe(first()).subscribe(function (res) {
            _this.notifications = _this.notifications.map(function (n, i) {
                var nData = res.result.find(function (x) { return x.uuid === n.payload.job_uuid; });
                return tslib_1.__assign({}, n, { data: n.data ? n.data : nData, acked_at: n.acked_at ? n.acked_at : "" });
            });
            _this.notifications$.next(_this.notifications);
            if (notifyId) {
                _this.newNotification$.next(_this.notifications.find(function (x) { return x.id === notifyId; }));
                _this.notifyStore(_this.notifications.find(function (x) { return x.id === notifyId; }));
            }
        });
    };
    /**
     * Marks all new notifications as being read
     *
     * @memberof UiNotificationService
     */
    UiNotificationService.prototype.markAllRead = function () {
        var toAck = this.notifications.reduce(function (acc, curr) {
            // if new and not shown before, ack it on backend, mark it as not new
            if (curr.acked_at === "") {
                acc.push(curr.id);
            }
            return acc;
        }, []);
        this.http.post(environment.api_route + "notifications/ack", toAck, {
            withCredentials: true
        }).pipe(first()).subscribe(function (res) {
            if (res.success) {
            }
        });
    };
    UiNotificationService.prototype.ackNotification = function (id) {
        var _this = this;
        this.http.post(environment.api_route + "notifications/ack", [id], {
            withCredentials: true
        }).pipe(first()).subscribe(function (res) {
            if (res.success) {
                console.log("NOTIFICATION ACKNOWNLAGED", _this.notifications.find(function (x) { return x.id === id; }), _this);
                _this.notifications.find(function (x) { return x.id === id; }).acked_at = new Date().toISOString();
                _this.notifications$.next(_this.notifications);
            }
        });
    };
    /**
     * Initializes notifications
     *
     * @memberof UiNotificationService
     */
    UiNotificationService.prototype.initNotifications = function (update) {
        if (update === void 0) { update = true; }
        this.notifications = [];
        this.totalNotifications = 0;
        if (update) {
            this.updateNotifications();
        }
    };
    UiNotificationService.prototype.killNotifications = function () {
        this.evtSource.close();
    };
    UiNotificationService.prototype.ngOnDestroy = function () {
        this.killNotifications();
    };
    UiNotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UiNotificationService_Factory() { return new UiNotificationService(i0.ɵɵinject(i1.DatasetService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.NotificationAdapter), i0.ɵɵinject(i4.FileService), i0.ɵɵinject(i5.CalculationService)); }, token: UiNotificationService, providedIn: "root" });
    return UiNotificationService;
}());
export { UiNotificationService };
