enum inspectDatasetType {
  raw,
  valid,
  rejected
}

export interface IFileInspectData {
  data: IFileInspect;
  links: IFileInspectLinks;
}

export interface IFileInspect {
  type: string;
  dataRows: string[];
  columnTypes: {};
  rejectedErrors: {}[];
  genericColumns: string[];
  displayedColumns: string[];
  columnLabels: string[];
}

export interface IFileInspectLink {
  uuid: string;
  filename: string;
  tag: string;
}

export interface IFileInspectLinks {
  current: IFileInspectLink;
  next?: Map<string, IFileInspectLink>;
  previous?: Map<string, IFileInspectLink>;
}

export interface IAPIFileInspect {
  table_data: IAPIFileHead;
  links: IFileInspectLinks;
}

export interface IAPIFileHead {
  dtypes: {};
  headers: string[];
  headers_print: string[];
  rows: [];
  recipe_diff?: [];
}

export class FileInspect implements IFileInspect {
  constructor(
    public type: string,
    public dataRows: string[],
    public columnTypes: {},
    public rejectedErrors: {}[],
    public genericColumns: string[],
    public displayedColumns: string[],
    public columnLabels: string[],
    public recipe_diff: []
  ) {}
}

export class FileInspectAdapter {
  adapt(apiFileHead: IAPIFileHead, type: string): FileInspect {
    // will store the rows in {column: value} manner
    const dataRows = [];
    let rejectedErrors = [];
    apiFileHead.rows.forEach(row => {
      const newLine = {};
      for (let i = 0; i < apiFileHead.headers.length; i++) {
        // if there are any errors with need to store them for further use
        newLine[apiFileHead.headers[i]] = row[i];
      }
      dataRows.push(newLine);
    });

    return new FileInspect(
      type,
      dataRows,
      apiFileHead.dtypes,
      rejectedErrors,
      this.getGenericColumns(apiFileHead.headers),
      this.getDisplayedColumns(apiFileHead.headers, type),
      this.getDisplayedColumns(apiFileHead.headers_print, type),
      apiFileHead.recipe_diff ? apiFileHead.recipe_diff : []
    );
  }

  getGenericColumns(headers: string[]) {
    return headers.filter(header => header !== "ERRORS");
  }

  getDisplayedColumns(headers: string[], type: string) {
    let displayedColumns = this.getGenericColumns(headers).slice(0);
    return displayedColumns;
  }

  checkDatasetType(type: string) {
    switch (type) {
      case "raw":
        return inspectDatasetType.raw;
      case "valid":
        return inspectDatasetType.valid;
      case "rejected":
        return inspectDatasetType.rejected;
      default:
        break;
    }
  }
}
