<app-header [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/logo_enigma_white.png', width: 127, height: 48, alt: 'Enigma Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/logo_enigma_minimized.png', width: 40, height: 39, alt: 'Enigma Logo'}"
  [sidebarToggler]="'lg'" [asideMenuToggler]=false>
  <!-- <ul class="nav navbar-nav d-md-down-none">
    <ng-container *ngFor="let navI of navItems">
        <li class="nav-item px-3">
          <a class="nav-link" routerLink="{{navI.url}}" routerLinkActive="active-link">{{navI.name}}</a>
        </li>
    </ng-container>
  </ul> -->
  <nav mat-tab-nav-bar>
    <ng-container *ngFor="let navI of navItems">
      <a mat-tab-link class="nav-link" routerLink="{{navI.url}}"
        [active]="router.isActive(navI.url)">
        {{navI.name}}
      </a>
    </ng-container>
  </nav>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown d-md-down-none show mr-3">
      <div class="notification">
        <button mat-icon-button 
          aria-label="Notifications"
          data-cy="notificationBell" 
          [matMenuTriggerFor]="notificationList"
          (menuOpened)="menuOpened()"
          (menuClosed)="menuClosed()"
          >
          <mat-icon 
            [attr.data-count]="newNotificationsTotal"
            matBadge="{{ newNotificationsTotal }}"
            matBadgeColor="warn"
            matBadgeHidden="{{ (newNotificationsTotal) === 0}}">
            notifications
          </mat-icon>
        </button>
      </div>
      <mat-menu #notificationList="matMenu">
        <ng-container *ngIf="this.notificationService.notifications$ | async; let data">
            <ng-container *ngIf="data.length > 0; else noNotifications">
                <cdk-virtual-scroll-viewport 
                  itemSize="70" [minBufferPx]="450"
                  [maxBufferPx]="600" class="notifications-viewport"
                  (scrolledIndexChange)="rangeChanged($event)"
                  #cdkVirtualScrollViewport>
                  <ng-container *cdkVirtualFor="let notification of data">
                      <div class="notification-item" *ngIf="notification.data.qualified_func.function !== 'apply_recipe'">
                          <app-notification-item [notification]="notification"></app-notification-item>
                      </div>
                  </ng-container>
                </cdk-virtual-scroll-viewport>
            </ng-container>
            <ng-template #noNotifications>Currently you don't have notifications</ng-template>
        </ng-container>
        <!-- <div class="notification-item" *ngFor="let notification of notifications">
          <app-notification-item [notification]="notification"></app-notification-item>
        </div> -->
      </mat-menu>
    </li>
    <li>
      <button mat-mini-fab class="user-def-btn" [matMenuTriggerFor]="userContext">
          D
      </button>
      <mat-menu #userContext="matMenu" class="user-context-menu">
        <button mat-menu-item (click)="onLogout()">
          <mat-icon>power_settings_new</mat-icon> Logout
        </button>
      </mat-menu>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'none'" [hidden]="!this.showSidebar">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <ol class="breadcrumb">
      <app-breadcrumb></app-breadcrumb>
    </ol>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
</div>
<app-footer>
    <span class="ml-auto"><strong>Enigma</strong> Capital Risk &copy; is a trademark of Potentia <em
        class="version">(v.{{this.version}})</em></span>
</app-footer>
