/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i10 from "@angular/common";
import * as i11 from "@angular/material/progress-spinner";
import * as i12 from "@angular/platform-browser/animations";
import * as i13 from "./status-icon.component";
var styles_StatusIconComponent = [i0.styles];
var RenderType_StatusIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusIconComponent, data: {} });
export { RenderType_StatusIconComponent as RenderType_StatusIconComponent };
function View_StatusIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "succeeded-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "em", [["class", "icon-check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", "%"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.percentege === 100) ? "percentege-full" : "percentege"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.percentege; _ck(_v, 4, 0, currVal_1); }); }
function View_StatusIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "em", [["class", "icon-close"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.status.status, ""); _ck(_v, 2, 0, currVal_0); }, null); }
function View_StatusIconComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "em", [["class", "icon-close"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 212992, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i8.HAMMER_LOADER]], { message: [0, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.status.status, ""); _ck(_v, 2, 0, currVal_0); }, null); }
function View_StatusIconComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"], ["value", "50"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i9.View_MatSpinner_0, i9.RenderType_MatSpinner)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(4, 114688, null, 0, i11.MatSpinner, [i1.ElementRef, i5.Platform, [2, i10.DOCUMENT], [2, i12.ANIMATION_MODULE_TYPE], i11.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], mode: [1, "mode"], value: [2, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.status.status; _ck(_v, 3, 0, currVal_3); var currVal_4 = 30; var currVal_5 = "indeterminate"; var currVal_6 = "50"; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 4).diameter; var currVal_2 = i1.ɵnov(_v, 4).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_StatusIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i10.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusIconComponent_1)), i1.ɵdid(3, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusIconComponent_2)), i1.ɵdid(5, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusIconComponent_3)), i1.ɵdid(7, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusIconComponent_4)), i1.ɵdid(9, 16384, null, 0, i10.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.status.status; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.analiticStatus.SUCCEEDED; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.analiticStatus.CANCELLED; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.analiticStatus.FAILED; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_StatusIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status-icon", [], null, null, null, View_StatusIconComponent_0, RenderType_StatusIconComponent)), i1.ɵdid(1, 114688, null, 0, i13.StatusIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatusIconComponentNgFactory = i1.ɵccf("app-status-icon", i13.StatusIconComponent, View_StatusIconComponent_Host_0, { status: "importedStatus" }, {}, []);
export { StatusIconComponentNgFactory as StatusIconComponentNgFactory };
