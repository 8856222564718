import { IAPIDCCalculations } from "./API.model";
import { Injectable } from "@angular/core";
import * as fromFiles from "../../modules/file/store/file.reducers";
import { Store } from "@ngrx/store";
import { File } from "./file.model";
import { Observable, of } from "rxjs";
import { analyticStates } from "./common";

export interface IDataset {
  uuid: string;
  name: string;
  createdDate: string;
  inputFiles: Observable<File[]>;
  paramFiles: Observable<File[]>;
  validation: IDCValidation;
  status: number;
}
export interface IDCValidation {
  status: "SUCCESS" | "FAILURE";
  datasetFiles: IDCCalculations[];
  ingestionFiles: IDCCalculations[];
}

export interface IDCCalculations {
  badRows: number;
  category: string;
  filename: string;
  status: string;
  subcategory: string;
  totalRows: number;
  goodRows: number;
  uuid: string;
}
export class Dataset implements IDataset {
  constructor(
    public uuid: string,
    public name: string,
    public createdDate: string,
    public inputFiles: Observable<File[]>,
    public paramFiles: Observable<File[]>,
    public validation: IDCValidation,
    public status: number
  ) {}
}

@Injectable({
  providedIn: "root"
})
export class DatasetAdapter {
  constructor(private store: Store<fromFiles.State>) {}
  adapt(item: any): Dataset {
    const inputFiles = [];
    const paramFiles = [];

    /** Parse the different analyses files (dataset, ingestion, shadowed) **/
    const datasetFiles = this.parseCalculationsFiles(
      item.validation_details.collection_analyses
    );
    const ingestionFiles = this.parseCalculationsFiles(
      item.validation_details.ingestion_analyses
    );
    const shadowedFiles = this.parseCalculationsFiles(
      item.validation_details.shadowed_analyses
    );

    /** Iterate over the Files store to find the matching Dataset Files */
    this.store
      .select(fromFiles.getFileState)
      .subscribe((state: fromFiles.State) => {
        if (!state || !state.files) {
          return;
        }

        state.files.forEach(paramInput => {
          const inputKeys = Object.keys(item.args.input_uuids);
          const paramKeys = Object.keys(item.args.param_uuids);

          inputKeys.forEach(key => {
            item.args.input_uuids[key].forEach(uuid => {
              if (paramInput.uuid === uuid) {
                // Avoiding duplicates
                if (!inputFiles.find(file => file.uuid === paramInput.uuid)) {
                  inputFiles.push(paramInput);
                }
              }
            });
          });

          paramKeys.forEach(key => {
            item.args.param_uuids[key].forEach(uuid => {
              if (paramInput.uuid === uuid) {
                // Avoiding duplicates
                if (!paramFiles.find(file => file.uuid === paramInput.uuid)) {
                  paramFiles.push(paramInput);
                }
              }
            });
          });
        });
      });

    return new Dataset(
      item.uuid,
      item.col_name,
      item.uploaded_ts,
      of(inputFiles),
      of(paramFiles),
      {
        status: item.validation_details.status,
        datasetFiles: datasetFiles,
        ingestionFiles: this.combineSortIngestionShadowed(
          ingestionFiles,
          shadowedFiles
        )
      },
      item.processed_ts
        ? this.getStatus(item.validation_details.status)
        : analyticStates.started
    );
  }

  private getStatus(status: "SUCCESS" | "FAILURE") {
    switch (status) {
      case "SUCCESS":
        return analyticStates.succeeded;
      case "FAILURE":
        return analyticStates.failed;
    }
  }

  private parseCalculationsFiles(
    analyses: IAPIDCCalculations[]
  ): IDCCalculations[] {
    let parsedCalculations: IDCCalculations[] = [];
    Object.keys(analyses).forEach(key => {
      // we need to get the FileType Object from the store to determine the subcategory string
      this.store
        .select(fromFiles.getFileTypeBySubcategory(analyses[key].subcategory))
        .subscribe(type => {
          parsedCalculations.push({
            badRows: analyses[key].bad_rows,
            category: analyses[key].category,
            filename: analyses[key].filename,
            status: analyses[key].status,
            subcategory: type.subcategoryStr,
            totalRows: analyses[key].total_rows,
            goodRows: analyses[key].total_rows - analyses[key].bad_rows,
            uuid: analyses[key].uuid
          });
        });
    });

    // Sorts the arr by category 'input' and subcategory DESC
    parsedCalculations.sort((a, b) => (a.subcategory < b.subcategory ? 1 : -1));
    parsedCalculations.sort((a, b) => (a.category === "input" ? -1 : 1));

    return parsedCalculations;
  }

  private combineSortIngestionShadowed(
    ingestionFiles: IDCCalculations[],
    shadowedFiles: IDCCalculations[]
  ) {
    Array.prototype.push.apply(ingestionFiles, shadowedFiles);

    // Sorts the arr by category 'input' and subcategory DESC
    ingestionFiles.sort((a, b) => (a.subcategory < b.subcategory ? 1 : -1));
    return ingestionFiles.sort((a, b) => (a.category === "input" ? -1 : 1));
  }
}
