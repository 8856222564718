import { Store } from "@ngrx/store";
import { map, first } from "rxjs/operators";
import { OnDestroy } from "@angular/core";
import { HttpParams, HttpClient } from "@angular/common/http";
import { BehaviorSubject, Subscription } from "rxjs";
import * as fromFiles from "../../file/store/file.reducers";
import { Metadata, FileType } from "../../../shared/models/file.model";
import { environment } from "../../../../environments/environment";
import { LaunchMeta, Filters } from "../../../shared/models/launch-rwa.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
var NewCalSetService = /** @class */ (function () {
    /**
     * Creates an instance of NewCalSetService.
     * @param {HttpClient} http
     * @param {Store<fromFiles.State>} store
     * @memberof NewCalSetService
     */
    function NewCalSetService(http, store) {
        this.http = http;
        this.store = store;
        this.selectedInputs = [];
        this.selectedParameters = [];
        this.metadata = new BehaviorSubject({
            counterparties: [],
            countries: [],
            currencies: [],
            reporting_dates: []
        });
        this.http$ = new Subscription();
    }
    /**
     * Returns an object containing the used Input Files, ordered by category
     *
     * @returns
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.getInputFiles = function () {
        return this.store.select(fromFiles.getInputFileState);
    };
    /**
     * Returns an Observable containing the metadata to be used on Calculator Component load
     * or, each time a selected Input File changes
     *
     * @param {string[]} uuids
     * @returns {Observable<Metadata>}
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.getReportingDate = function (uuids) {
        var params = new HttpParams();
        uuids.forEach(function (uuid) {
            params = params.append("file_uuids", uuid);
        });
        return this.http
            .get(environment.api_route + "files/meta", {
            withCredentials: true,
            params: params
        })
            .pipe(map(function (res) {
            return res.result.reporting_dates[0];
        }));
    };
    /**
     * Returns an Observable containing the metadata to be used on Calculator Component load
     * or, each time a selected Input File changes
     *
     * @param {string[]} uuids
     * @returns {Observable<Metadata>}
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.getReportingDates = function (uuids) {
        var params = new HttpParams();
        uuids.forEach(function (uuid) {
            params = params.append("file_uuids", uuid);
        });
        return this.http
            .get(environment.api_route + "files/meta", {
            withCredentials: true,
            params: params
        })
            .pipe(first(), map(function (res) {
            return res.result.reporting_dates;
        }));
    };
    NewCalSetService.prototype.getFilesMeta = function (uuids, reportingDate) {
        var params = new HttpParams();
        uuids.forEach(function (uuid) {
            params = params.append("file_uuids", uuid);
        });
        params = params.append("reporting_date", reportingDate);
        return this.getAPIMeta(params);
    };
    NewCalSetService.prototype.launchRisk = function (kind, uuid) {
        var formData = new FormData();
        formData.append("kind", kind);
        return this.http
            .post(environment.api_route + "analyses/launch/risk/" + uuid, formData, {
            withCredentials: true
        });
    };
    /**
     * Gets the Counterparty Names
     *
     * @param {string} uuid
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.getCounterpartyNames = function (uuid) {
        /* Get counterparty names */
        return this.http
            .get(environment.api_route + "files/counterparties", {
            withCredentials: true,
            params: new HttpParams().append("file_uuid", uuid)
        });
    };
    /**
     * Sets the Counterparty Names to be used for Filter Presentation
     *
     * @param {Observable<IAPICounterparty>} counterparties
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.setCounterpartyNames = function (counterparties) {
        var _this = this;
        counterparties.subscribe(function (response) {
            console.log("SET OF COUNTERPARTY");
            _this.counterpartyNames = response.result;
        });
    };
    /**
     * Returns an Observable containing the metadata to be used each time
     * there is a change on the selected Reporting Date Filter
     *
     * @param {string} reportingDate
     * @returns {Observable<Metadata>}
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.getMetadata = function (reportingDate) {
        var params = new HttpParams();
        var existingReportingDates = [];
        this.selectedInputs.forEach(function (input) {
            /* Assemble Input uuids */
            if (input.paramInput.checked) {
                params = params.append("file_uuids", input.paramInput.validation_result.valid_file.uuid);
            }
        });
        // add reporting date as param
        params = params.append("reporting_date", reportingDate);
        this.metadata.subscribe(function (metadata) {
            // removes the reporting date and adds it to the start of the array, since it should be "the selected value"
            existingReportingDates = metadata.reporting_dates.filter(function (date) { return date !== reportingDate; });
            existingReportingDates.unshift(reportingDate);
        });
        return this.getAPIMeta(params);
    };
    /**
     * Returns the Metadata for a specific File
     *
     * @private
     * @param {HttpParams} params
     * @returns {Observable<Metadata>}
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.getAPIMeta = function (params) {
        var _this = this;
        return this.http
            .get(environment.api_route + "files/meta", {
            withCredentials: true,
            params: params
        })
            .pipe(map(function (data) {
            /* convert counterparty ids to names */
            var counterparties = [];
            data.result.counterparties.forEach(function (counterparty) {
                if (_this.counterpartyNames[counterparty]) {
                    counterparties.push(_this.counterpartyNames[counterparty]);
                }
            });
            return new Metadata(counterparties, data.result.countries, data.result.currencies, data.result.reporting_dates // if we already have the reporting dates loaded, we need to use it
            );
        }));
    };
    /**
     * Launches the RWA job on the backend
     *
     * @param {string} referenceCountry
     * @param {string} reportingDate
     * @param {string[]} countries
     * @param {string[]} counterparties
     * @param {string[]} currency
     * @param {IDCCalculations[]} fileCalculations
     * @param {string} datasetUUID
     * @param {string} approach
     * @returns {Observable<IAPILaunchCalculation>}
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.launch = function (referenceCountry, reportingDate, countries, counterparties, currency, fileCalculations, datasetUUID, approach, name) {
        var filters = new Filters(this.getFilterData(countries), this.getFilterData(counterparties), this.getFilterData(currency));
        this.launchMeta = new LaunchMeta(reportingDate, approach, fileCalculations, filters, datasetUUID, name);
        return this.http.post(environment.api_route + "analyses/create", this.launchMeta, {
            withCredentials: true
        });
    };
    /**
     * Get the list of selected UUIDS by category
     *
     * @param {string} category
     * @param {*} selectedInputParams
     * @param {IDCCalculations[]} fileCalculations
     * @param {FileType[]} types
     * @returns
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.getSelectedUUIDS = function (category, selectedInputParams, fileCalculations, types) {
        function getParam(selected, toSearch) {
            var uuids = [];
            selected.forEach(function (input) {
                if (input.paramInput.subcategory === toSearch &&
                    input.paramInput.checked) {
                    var filefound_1 = false;
                    /* we need to check if there is a new version of the file after it has passed validation */
                    fileCalculations.forEach(function (file) {
                        if (file.filename === input.paramInput.filename) {
                            filefound_1 = true;
                            uuids.push(file.uuid);
                            return;
                        }
                    });
                    if (!filefound_1) {
                        uuids.push(input.paramInput.uuid);
                    }
                }
            });
            return uuids;
        }
        var selected = {};
        types
            .filter(function (type) { return type.categorySlug === category; })
            .forEach(function (type) {
            selected[type.subcategorySlug] = getParam(selectedInputParams, type.subcategoryStr);
        });
        return selected;
    };
    /**
     * Returns the filtered array of filters.
     * When it has 'ALL' it returns an empty array
     *
     * @param {string[]} filters
     * @returns {string[]}
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.getFilterData = function (filters) {
        return filters.indexOf("ALL") > -1 ? [] : filters;
    };
    /**
     * Returns Launch related warning messages, according to business rules
     * @returns {string[]}
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.getLaunchWarnings = function () {
        var warnings = [];
        return warnings;
    };
    /**
     * Returns Launch related error messages, according to business rules
     * @returns {string[]}
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.getLaunchErrors = function () {
        var errors = [];
        var oneSelectedInput = "At least one of the following files: Contract, Market, Property, Retail, needs to be selected";
        if (!this.selectedInputs.find(function (parameter) {
            return parameter.subcategory === "contract" ||
                parameter.subcategory === "market" ||
                parameter.subcategory === "property" ||
                parameter.subcategory === "retail";
        })) {
            errors.push(oneSelectedInput);
        }
        return errors;
    };
    /**
     * Returns an Observable containing the Countries
     *
     * @returns {Observable<{}>}
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.getCountriesList = function () {
        return this.http.get(environment.api_route + "utils/countries", {
            withCredentials: true
        });
    };
    /**
     *
     * @memberof NewCalSetService
     */
    NewCalSetService.prototype.ngOnDestroy = function () {
        this.http$.unsubscribe();
    };
    NewCalSetService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NewCalSetService_Factory() { return new NewCalSetService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: NewCalSetService, providedIn: "root" });
    return NewCalSetService;
}());
export { NewCalSetService };
