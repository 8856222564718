<ng-container *ngIf="errorsInfo">
    <div class="row">
        <div class="col-6">
            <div class="error-container" *ngFor="let error of errorsInfo.columnErrors">
                <div class="error-entry">
                    <div class="error-property">
                        <div>
                            <mat-icon>timeline</mat-icon><span>Stage</span>
                        </div>
                        <div>
                            <mat-icon>border_vertical</mat-icon><span>Column</span>
                        </div>
                        <div>
                            <mat-icon>error_outline</mat-icon><span>Errors</span>
                        </div>
                    </div>
                    <div class="error-value">
                        <div>
                            <span>{{ error.stage }}</span>
                        </div>
                        <div>
                            <span>{{ error.column }}</span>
                        </div>
                        <div class="dblock">
                            <div *ngFor="let errorDesc of error.errors">
                                <span>{{ errorDesc }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Other Row Errors
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-container *ngFor="let error of errorsInfo.rowErrors; let i = index">
                        <div class="error-container">
                            <div class="error-entry">
                                <div class="error-property">
                                    <div>
                                        <mat-icon>timeline</mat-icon><span>Stage</span>
                                    </div>
                                    <div>
                                        <mat-icon>border_vertical</mat-icon><span>Column</span>
                                    </div>
                                    <div>
                                        <mat-icon>error_outline</mat-icon><span>Errors</span>
                                    </div>
                                </div>
                                <div class="error-value">
                                    <div>
                                        <span>{{ error.stage }}</span>
                                    </div>
                                    <div>
                                        <span>{{ error.column }}</span>
                                    </div>
                                    <div class="dblock">
                                        <ng-container *ngFor="let errorDesc of error.errors">
                                            <div>
                                                <span>{{ errorDesc }}</span>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-divider *ngIf="i < errorsInfo.rowErrors.length - 1"></mat-divider>
                    </ng-container>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</ng-container>