import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
/**
 * Generates a "confirm/cancel" dialog
 *
 * @export
 * @class ConfirmDialogComponent
 */
@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html"
})
export class ConfirmDialogComponent {
  /**
   *Creates an instance of ConfirmDialogComponent.
   * @param {MatDialogRef<ConfirmDialogComponent>} dialogRef
   * @param {{
   *       title: string;
   *       warnings: string[];
   *       errors: string[];
   *       text: string;
   *       btnOk: string;
   *       btnCancel: string;
   *     }} data
   * @memberof ConfirmDialogComponent
   */
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      warnings: string[];
      errors: string[];
      text: string;
      btnOk: string;
      btnCancel: string;
    }
  ) {}

  /**
   * Closes the dialog
   *
   * @memberof ConfirmDialogComponent
   */
  onNoClick() {
    this.dialogRef.close();
  }
}
