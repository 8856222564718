import { Observable, Subscription } from "rxjs";
import { tap, map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Utils } from "../../../shared/utils";
import * as fromApp from "../store/dataset.reducers";
import { File, FileType } from "../../../shared/models/file.model";
import * as DatasetActions from "../store/dataset.actions";
import { DatasetAdapter } from "../../../shared/models/dataset.model";
import { IAPIDatasetCreate, IAPIDatasetList, IAPIDataset, IAPIFile } from "../../../shared/models/API.model";
import { environment } from "../../../../environments/environment";
import { NewCalSetService } from "../../calculation/shared/new-calculation-settings.service";

/**
 *
 * @export
 * @class DatasetService
 */
@Injectable({
  providedIn: "root"
})
export class DatasetService {
  /**
   * Creates an instance of DatasetService.
   * @param {NewCalSetService} newCalcSetService
   * @param {HttpClient} http
   * @param {Store<fromApp.State>} store
   * @param {ToastrService} toastr
   * @memberof DatasetService
   */
  constructor(
    public newCalcSetService: NewCalSetService,
    private http: HttpClient,
    private store: Store<fromApp.State>,
    private toastr: ToastrService,
    private dsAdapter: DatasetAdapter
  ) {}

  /**
   * Creates a Dataset
   *
   * @param {string} datasetName
   * @returns {Observable<IAPIDatasetCreate>}
   * @memberof DatasetService
   */
  createDataset(
    datasetName: string,
    types: FileType[],
    selection: any[]
  ): Observable<IAPIDatasetCreate> {
    const inputs = types.filter(x => x.categorySlug === 'input').reduce((acc, curr) => {
      const res = selection.filter(x => x.type.subcategorySlug === curr.subcategorySlug);
      acc[curr.subcategorySlug] = res && res.length > 0 && res[0].uuid ? res.map(x => x.uuid) : [];
      return acc;
    }, {});

    const parameters = types.filter(x => x.categorySlug === 'parameter').reduce((acc, curr) => {
      acc[curr.subcategorySlug] = selection.filter(x => x.type.subcategorySlug === curr.subcategorySlug).map(x => x.uuid);
      return acc;
    }, {});

    return this.http.post<IAPIDatasetCreate>(
      `${environment.api_route}collections/create`,
      {
        input_uuids: inputs,
        param_uuids: parameters,
        name: datasetName
      },
      {
        withCredentials: true
      }
    );
  }

  /**
   * Returns an object of uuids, indexed by subcategories, given a specific category as input
   *
   * @param {string} category
   * @param {{
   *       subcategorySlug: string;
   *       subcategoryStr: string;
   *       paramInput: File;
   *     }[]} selectedInputParams
   * @param {FileType[]} types
   * @returns {{}}
   * @memberof DatasetService
   */
  getSelectedUUIDS(
    category: string,
    selectedInputParams: {
      subcategory: string;
      paramInput: IAPIFile;
    }[],
    types: FileType[]
  ): {} {
    function getParam(
      selected: {
        subcategory: string;
        paramInput: IAPIFile;
      }[],
      toSearch: string
    ): string[] {
      const uuids = [];
      selected.forEach(input =>
        input.paramInput.subcategory === toSearch &&
        (<any>input.paramInput).checked
          ? uuids.push(input.paramInput.uuid)
          : ""
      );
      return uuids;
    }

    const selected = {};
    types
      .filter(type => type.categorySlug === category)
      .forEach(type => {
        if (!selected[type.subcategorySlug]) {
          selected[type.subcategorySlug] = [];
        }

        selected[type.subcategorySlug] = getParam(
          selectedInputParams,
          type.subcategoryStr
        );
      });

    return selected;
  }

  /**
   * Returns a Dataset for the given UUID
   *
   * @param {string} uuid
   * @returns {Observable<Dataset>}
   * @memberof DatasetService
   */
  getDatasetByUUID(uuid: string): Observable<IAPIDataset> {
    return this.http.get<IAPIDatasetList>(`${environment.api_route}collections/ls`, {
      withCredentials: true,
      params: new HttpParams().set("filter_by", `uuid:=:'${uuid}'`)
    }).pipe(
      map((cols: IAPIDatasetList) => cols.result[0])
    )
  }

  getDatasetIngestionFiles(uuids: string[]) {
    return this.http.get(`${environment.api_route}files/ls`, {
      withCredentials: true,
      params: new HttpParams().set("filter_by", `uuid:IN:(${uuids.join(',')})`)
    }).pipe(
      map((cols) => cols['result'])
    )
  }

  /**
   * Returns all Datasets and the current State
   *
   * @returns {Observable<fromApp.State>}
   * @memberof DatasetService
   */
  readDatasets(params: HttpParams): Observable<IAPIDataset[]> {
    //return this.store.pipe(select(fromApp.getCurrentState));
    return this.http.get<IAPIDatasetList>(`${environment.api_route}collections/ls`, {
      withCredentials: true,
      params
    }).pipe(
      map((cols: IAPIDatasetList) => cols.result)
    );
  }

  getDatasetStatus(ds: IAPIDataset) {
    let badRowsCount: number = 0;
    let totalRowsCount: number = 0;
    if (ds.validation_result && ds.validation_result.file_validations) {
      Object.keys(ds.validation_result.file_validations).forEach(k => {
        badRowsCount += parseInt(ds.validation_result.file_validations[k].bad_rows);
        totalRowsCount += parseInt(ds.validation_result.file_validations[k].total_rows);
      })
    }
    return {
      status: ds.validation_status,
      badRows: badRowsCount,
      totalRows: totalRowsCount
    }
  }

  /**
   * Checks if the Dataset removal is possible
   *
   * @param {string} uuid
   * @returns {Observable<boolean>}
   * @memberof DatasetService
   */
  canRemove(uuid: string): Observable<boolean> {
    let params = new HttpParams().set("just_check", "true");
    return this.http
      .delete(`${environment.api_route}collections/${uuid}`, {
        withCredentials: true,
        params: params
      })
      .pipe(map(response => response["success"]));
  }

  /**
   * Removes a specific Dataset
   *
   * @param {string} uuid
   * @returns
   * @memberof DatasetService
   */
  removeDataset(uuid: string) {
    return this.http
      .delete(`${environment.api_route}collections/${uuid}`, {
        withCredentials: true
      })
      .pipe(
        tap(data => {
          if (data["success"]) {
            // update dataset list
            this.updateDatasetList();
            // show success toastr
            this.toastr.success(
              "Dataset removed successfully.",
              "Dataset Remove"
            );
            return;
          }

          // if not successful...error
          this.toastr.error(
            "There was an error while trying to delete the File. Please try again",
            "File Remove"
          );
        })
      );
  }

  currentStatus() {
    return this.store.select(fromApp.getCurrentState)
  }

  /**
   * Triggers the Datset list update
   *
   * @memberof DatasetService
   */
  updateDatasetList() {
    this.store.dispatch(DatasetActions.fecthCols());
  }
}
