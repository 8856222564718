import { HttpParams } from '@angular/common/http';
import { ITableListState } from './table-list.model';

export default function tableChangesToAPIParams(changes): HttpParams {

    let params = new HttpParams;
    params = params.set("limit", changes.limit + 1);
    params = params.set("skip", (changes.offset * changes.limit).toString());
    if (changes.sort && changes.sort.length) {
        changes.sort.forEach(s => {
            if (params.has("sort_by")) {
                params = params.append("sort_by", `${s.by}:${s.dir.toUpperCase()}`)
            } else {
                params = params.set("sort_by", `${s.by}:${s.dir.toUpperCase()}`)
            }
        });
    }
    if (changes.filter && changes.filter.length > 0) {
        changes.filter.forEach(f => {
            if (params.has("filter_by")) {
                params = params.append("filter_by", `${f.column ? f.column : '*'}:${f.exact ? '=' : 'LIKE'}:${f.exact ? `'${f.value}'` : `'%${f.value}%'`}`)
            } else {
                params = params.set("filter_by", `${f.column ? f.column : '*'}:${f.exact ? '=' : 'LIKE'}:${f.exact ? `'${f.value}'` : `'%${f.value}%'`}`)
            }
        });
    }
    return params;
}
