<div class="row dialog-header">
  <div class="col-10">
    <h4 mat-dialog-title>{{data.subCategory}}</h4>
    <small>{{data.category | titlecase}}</small>
  </div>
  <div class="col-2 text-center mt-3 ml-1">

  </div>
</div>
<mat-dialog-content>
  <div class="col-12 mt-3">
    <table class="table table-striped">
      <thead class="thead-dark">
        <tr>
          <th *ngFor="let header of data.metadata.headers" scope="col">{{header}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of data.metadata.rows;">
          <td>{{row.name.text}} <em class="row-key">{{row.name.key}}</em></td>
          <td>
            <div class="row">
              <div class="col-5">
                {{row.format.text | convertYesNo}}
              </div>
              <div class="col-7">
                <em class="material-icons" matTooltip="{{row.format.tooltip}}" [matTooltipPosition]="'after'">info</em>
              </div>
            </div>
          </td>
          <td>{{row.mandatory.text | convertYesNo}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button (click)="close()" class="mat-raised-button close-button"> <em class="fa fa-close"></em> Close</button>
  <button (click)="downloadTemplate()" class="mat-raised-button ml-3 download-button"> <em class="fa fa-download"></em>
    Download</button>
</mat-dialog-actions>
