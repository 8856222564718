import * as tslib_1 from "tslib";
import * as CalculationActions from "./calculation.actions";
import { createReducer, on, createFeatureSelector, createSelector } from "@ngrx/store";
var initialState = {
    calculation: [],
    needsReload: false,
    loaded: false
};
var ɵ0 = function (state) { return (tslib_1.__assign({}, state, { needsReload: true, loaded: false })); }, ɵ1 = function (state, _a) {
    var calculation = _a.calculation;
    return tslib_1.__assign({}, state, { calculation: tslib_1.__spread([calculation], state.calculation) });
}, ɵ2 = function (state) { return (tslib_1.__assign({}, state, { loaded: true, needsReload: false })); }, ɵ3 = function (state, _a) {
    var calculation = _a.calculation;
    return (tslib_1.__assign({}, state, { calculation: tslib_1.__spread(state.calculation.filter(function (x) { return x !== calculation; })) }));
}, ɵ4 = function (state) { return (tslib_1.__assign({}, state, { calculation: undefined })); };
var calculationReducer = createReducer(initialState, on(CalculationActions.fecthCalculation, ɵ0), on(CalculationActions.addCalculation, ɵ1), on(CalculationActions.setCalculation, ɵ2), on(CalculationActions.deleteCol, ɵ3), on(CalculationActions.clearCalculation, ɵ4));
export var getCalculation = function (state) { return state.calculation; };
export function reducer(state, action) {
    return calculationReducer(state, action);
}
export var getCalculationState = createFeatureSelector("calculation");
var ɵ5 = function (state) { return state; };
export var getCurrentState = createSelector(getCalculationState, ɵ5);
export var getAllCalculation = createSelector(getCalculationState, getCalculation);
var ɵ6 = function (state, uuid) {
    return state.calculation.find(function (calculation) { return calculation.uuid === uuid; });
};
export var getCalculationByUUID = createSelector(getCalculationState, ɵ6);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
