import { Component } from "@angular/core";
/**
 * The wrapper structure for the File "page"
 *
 * @export
 * @class FileComponent
 */
@Component({
  selector: "app-file",
  templateUrl: "./file.component.html"
})
export class FileComponent {}
