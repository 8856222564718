import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { DynamicFormControlComponent, DynamicFormLayoutService, DynamicFormValidationService } from '@ng-dynamic-forms/core';
import { FormGroup } from '@angular/forms';
import { DynamicButtonModel } from './dynamic-button-model';
var AddToStepButtonComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddToStepButtonComponent, _super);
    function AddToStepButtonComponent(layoutService, validationService) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    AddToStepButtonComponent.prototype.ngOnInit = function () {
        console.log("THIS.MMODEL", this.model);
    };
    return AddToStepButtonComponent;
}(DynamicFormControlComponent));
export { AddToStepButtonComponent };
