/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./analytic-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./analytic-status.component";
var styles_AnalyticStatusComponent = [i0.styles];
var RenderType_AnalyticStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AnalyticStatusComponent, data: {} });
export { RenderType_AnalyticStatusComponent as RenderType_AnalyticStatusComponent };
function View_AnalyticStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"], mode: [1, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = 30; var currVal_4 = _co.mode; _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_AnalyticStatusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "em", [["class", "succeeded"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SUCCEEDED"]))], null, null); }
function View_AnalyticStatusComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "em", [["class", "failed"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["FAILED"]))], null, null); }
export function View_AnalyticStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i5.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalyticStatusComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalyticStatusComponent_2)), i1.ɵdid(5, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AnalyticStatusComponent_3)), i1.ɵdid(7, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.analyticStatus; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.analyticStates.started; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.analyticStates.succeeded; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.analyticStates.failed; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_AnalyticStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-analytic-status", [], null, null, null, View_AnalyticStatusComponent_0, RenderType_AnalyticStatusComponent)), i1.ɵdid(1, 114688, null, 0, i7.AnalyticStatusComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AnalyticStatusComponentNgFactory = i1.ɵccf("app-analytic-status", i7.AnalyticStatusComponent, View_AnalyticStatusComponent_Host_0, { analyticStatus: "analyticStatus" }, {}, []);
export { AnalyticStatusComponentNgFactory as AnalyticStatusComponentNgFactory };
