import * as FileActions from "./file.actions";
import { File, FileType } from "../../../shared/models/file.model";
import {
  createReducer,
  on,
  Action,
  createSelector,
  createFeatureSelector
} from "@ngrx/store";
export interface State {
  files: File[];
  fileTypes: FileType[];
  loaded: boolean;
  needReload: boolean;
}

const initialState = {
  files: [],
  fileTypes: [],
  loaded: false,
  needReload: false
};

const filesReducer = createReducer(
  initialState,
  on(FileActions.fetchFiles, state => ({
    ...state,
    loaded: false,
    needReload: true
  })),
  on(FileActions.fetchedFiles, state => ({
    ...state,
    loaded: true,
    needReload: false
  })),
  on(FileActions.fetchFileTypes, state => ({
    ...state,
    loaded: true
  })),
  on(FileActions.setFileTypes, (state, { fileTypes }) => ({
    ...state,
    fileTypes: fileTypes,
    loaded: true
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return filesReducer(state, action);
}

export const getFileState = createFeatureSelector("files");

export const getInputFileState = createSelector(
  getFileState,
  (state: State) => {
    if (state) {
      const files$ = state.files;
      files$.filter(file => file.type.categorySlug === "input");
      return files$;
    }
    return [];
  }
);

export const getFiles = (state: State) => state.files;

export const getCurrentState = createSelector(
  getFileState,
  (state: State) => state
);

export const getFileByUUID = createSelector(
  getFileState,
  (state: State, uuid: string) => state.files.find(col => col.uuid === uuid)
);

export const getListByCategory = createSelector(
  getFileState,
  (state: State, category) => {
    if (state.files && state.loaded) {
      return <State>{
        files: state.files.filter(file => {
          if (file.domain === "UPLOADS" && file.type.categorySlug === category) {
            return file;
          }
        }),
        fileTypes: state.fileTypes,
        loaded: true
      };
    }

    return <State>{ files: [], loaded: false };
  }
);

export const getFileTypeByCategory = (category: string) =>
  createSelector(getFileState, (state: State) => {
    if (state) {
      return state.fileTypes.filter(type => type.categorySlug === category);
    }
  });

export const getFileTypeBySubcategory = (subcategory: string) =>
  createSelector(getFileState, (state: State) => {
    if (state) {
      let foundType: FileType;
      state.fileTypes.forEach(type => {
        if (type.subcategorySlug == subcategory) {
          foundType = type;
        }
      });
      return foundType;
    }
  });

export const getFileTypes = createSelector(
  getFileState,
  (state: State) => state.fileTypes
);

export const isLoaded = createSelector(
  getFileState,
  (state: State) => state.loaded
);

export const needsReload = createSelector(
  getFileState,
  (state: State) => state.needReload
);
