import { IAPIFileSchema } from './API.model';

export interface IFileTemplate {
  headers: string[];
  rows: FileTemplateRow[];
}

export interface IFileTemplateRow {
  name: FileTemplateCell;
  format: FileTemplateCell;
  mandatory: FileTemplateCell;
}

export interface FileTemplateCell {
  text: string;
  key: string;
  tooltip: string;
}

export class FileTemplateRow implements IFileTemplateRow {
  constructor(
    public name: FileTemplateCell,
    public format: FileTemplateCell,
    public mandatory: FileTemplateCell
  ) {}
}

export class FileTemplate implements IFileTemplate {
  constructor(public headers: string[], public rows: FileTemplateRow[]) {}
}

export class FileTemplateAdapter {
  adapt(item: IAPIFileSchema): FileTemplate {
    const parsedRows: FileTemplateRow[] = [];

    item.result.rows.forEach(row => {
      parsedRows.push(
        new FileTemplateRow(
          { text: row[0], key: row[3], tooltip: "" },
          { text: row[1], key: "", tooltip: row[2] },
          { text: row[4], key: "", tooltip: "" }
        )
      );
    });
    return new FileTemplate(
      item.result.headers.filter(header => header !== "INFO" && header !== "KEY"),
      parsedRows
    );
  }
}
