import { FileType } from "./file.model";

/**
 * Variable definition is using Snake Casing to avoid additional
 * parsing when calling the Launch Endpoint
 *
 */

export class LaunchMeta {
  constructor(
    //public local_country: string,
    public reporting_date: string,
    public approach: string,
    public input_uuids: any,
    public filters: Filters,
    public col_uuid: string,
    public name: string
  ) {}
}
export class Filters {
  constructor(
    public country: string[],
    public counterparty_id: string[],
    public currency: string[]
  ) {}
}
