import { Type } from "@angular/core";
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
import "hammerjs";
// Template needed dependencies
import "popper.js";
import "jquery";
import "bootstrap";
import { DynamicFormControlModel, DynamicFormControl } from '@ng-dynamic-forms/core';
import { SelectFromTableFormControlComponent } from './modules/dataset/simulation/select-from-table-form-control/select-from-table-form-control.component';
import { AddToStepButtonComponent } from './modules/dataset/stress-test/add-to-step-button/add-to-step-button.component';
var ɵ0 = function (model) {
    switch (model.type) {
        case "SELECT_FROM_TABLE_FORM_CONTROL":
            return SelectFromTableFormControlComponent;
        case "BUTTON_FORM_CONTROL":
            return AddToStepButtonComponent;
    }
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
