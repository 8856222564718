<div class="btn-group" style="overflow: visible">
  <ng-container *ngIf="!options.item.isDisabled(element); else firstEnabled">
      <button mat-button [routerLink]="[].concat(options.item.link, element.uuid)" class="main-option noradius text-center" type="button"
      [ngClass]="options.item.color ? 'btn-' + options.item.color : ''">
      <mat-icon *ngIf="options.item.icon">{{ options.item.icon }}</mat-icon>
      {{options.item.title}}
    </button>
  </ng-container>
  <ng-template #firstEnabled>
    <ng-container *ngFor="let item of [getFirstEnabledItem()]">
        <button mat-button [routerLink]="[].concat(item.link, element.uuid)" class="btn-secondary main-option noradius text-center" type="button"
        [ngClass]="item.color ? 'btn-' + item.color : ''">
          <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
          {{item.title}}
      </button>
    </ng-container>
  </ng-template>
  <button 
    mat-button type="button"
    class="menu-opener noradius"
    [ngClass]="options.item.color ? 'btn-' + options.item.color : ''"
    data-cy="dropdown-arrow"
    [matMenuTriggerFor]="menu">
    <mat-icon>keyboard_arrow_up</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
      <div *ngFor="let item of options.items" data-cy="dropdown-options">
          <a mat-menu-item class="dropdown-item" (click)="clickedOption(item.slug)" [routerLink]="[].concat(item.link, element.uuid)"
            [ngClass]="[item.isDisabled(element) ? 'disabled' : '', item.separator ? 'dropdown-item-border' : '']"
            [hidden]="!item.isVisible(element)"><span class="sub-option"></span>
            <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
            {{item.title}}</a>
        </div>
  </mat-menu>
</div>
