import { Routes } from "@angular/router";
import { FileComponent } from "./file.component";
import { InspectComponent } from "./components/inspect/inspect.component";
import { FilesGuard } from "../../shared/guards/files.guard";
import { RemoveComponent } from "./components/remove/remove.component";
var ɵ0 = {
    title: "Files"
}, ɵ1 = {
    title: ""
}, ɵ2 = {
    title: "Remove"
}, ɵ3 = {
    title: "Inspect"
};
var routes = [
    {
        path: "",
        canActivate: [FilesGuard],
        data: ɵ0,
        children: [
            {
                path: "",
                component: FileComponent,
                data: ɵ1
            },
            {
                path: "remove/:uuid",
                component: RemoveComponent,
                data: ɵ2
            },
            {
                path: "inspect/:uuid/:type",
                canActivate: [FilesGuard],
                component: InspectComponent,
                data: ɵ3
            }
        ]
    }
];
var FileRoutingModule = /** @class */ (function () {
    function FileRoutingModule() {
    }
    return FileRoutingModule;
}());
export { FileRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
