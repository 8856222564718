export enum importedStates {
  pendingUpload,
  pendingProcess,
  imported,
  accepted,
  warning,
  error
}

export enum analyticStates {
  succeeded,
  started,
  failed
}

export enum AnalyticStatus {
  CREATED = "CREATED",
  PENDING = "PENDING",
  STARTED = "STARTED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED"
}

export const paramImportTablesHeaders = [
  "importDate",
  "subcategory",
  "filename",
  "status",
  "actions"
];

/**
 * Shared Componentes - DropDownOptions
 */
export interface DropdownOptions {
  item: DropDownOptionsItem;
  link?: string[];
  items: DropDownOptionsItem[];
}

export interface DropDownOptionsItem {
  color?: string;
  faIcon?: string; // assuming it uses font-awesome
  title?: string;
  link?: string[];
  slug?: string;
  isDisabled?: (e?: any) => boolean;
  separator?: boolean;
  isVisible?: (e?: any) => boolean;
}
