import { Component, OnInit, Input } from "@angular/core";
import { importedStates, AnalyticStatus } from '../../models/common';
import { IAPIAnalyticStatus } from '../../models/API.model';

/**
 * Shows a Status icon, regarding the importedStatus
 *
 * @export
 * @class StatusIconComponent
 * @implements {OnInit}
 */
@Component({
  selector: "app-status-icon",
  templateUrl: "./status-icon.component.html",
  styleUrls: ["./status-icon.component.scss"]
})
export class StatusIconComponent implements OnInit {
    mode = "determinate";
  value = 30;

  @Input('importedStatus') status: IAPIAnalyticStatus;
  analiticStatus = AnalyticStatus;

  percentege: number;

  ngOnInit(): void {
    this.percentege = this.status.badRows === 0 ? 100 :
      100 - Math.round(
        (this.status.badRows * 100) / this.status.totalRows
      );
    
    if (this.status.badRows === this.status.totalRows && 
      this.status.status === AnalyticStatus.SUCCEEDED) {
      this.status.status = AnalyticStatus.FAILED;
    }
  }

}
