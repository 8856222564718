import { NgModule } from "@angular/core";
import { Routes, RouterModule, NoPreloading } from "@angular/router";

// Import Containers
import { MainLayoutComponent } from "./core/main-layout/main-layout.component";
import { LoginPageComponent } from "./core/login/login/login.component";
import { AuthGuard } from "./shared/guards/auth.guard";
/*
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
 */
export const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  /*  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  }, */
  {
    path: "login",
    component: LoginPageComponent,
    data: {
      title: "Login Page"
    }
  },
  {
    path: "pages",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Home"
    },
    children: [
      {
        path: "calculation",
        loadChildren: () =>
          import("./modules/calculation/calculation.module").then(m => m.CalculationModule),
        canActivate: [AuthGuard]
      },
      {
        path: "files",
        loadChildren: () =>
          import("./modules/file/file.module").then(m => m.FileModule),
        canActivate: [AuthGuard]
      },
      {
        path: "datasets",
        loadChildren: () =>
          import("./modules/dataset/dataset.module").then(m => m.DatasetModule),
        canActivate: [AuthGuard]
      },
      {
        path: "simulations",
        loadChildren: () =>
          import("./modules/dataset/dataset.module").then(m => m.DatasetModule),
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: NoPreloading,
      useHash: true,
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
