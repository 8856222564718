import * as tslib_1 from "tslib";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
var RoutingState = /** @class */ (function () {
    function RoutingState(router) {
        this.router = router;
        this.history = [];
    }
    RoutingState.prototype.loadRouting = function () {
        var _this = this;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (_a) {
            var urlAfterRedirects = _a.urlAfterRedirects;
            _this.history = tslib_1.__spread(_this.history, [urlAfterRedirects]);
        });
    };
    RoutingState.prototype.getHistory = function () {
        return this.history;
    };
    RoutingState.prototype.getPreviousUrl = function () {
        return this.history[this.history.length - 2] || "/pages/files";
    };
    return RoutingState;
}());
export { RoutingState };
