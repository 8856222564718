import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-file-error',
  templateUrl: './file-error.component.html',
  styleUrls: ['./file-error.component.scss']
})
export class FileErrorComponent implements OnInit {
  @Input() data: any;
  errorsInfo: any;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const errArray = JSON.parse(this.data.ERRORS);
    this.route.queryParams.subscribe(queryParams => {
      if (Array.isArray(errArray)) {
        this.errorsInfo = {
          columnErrors: errArray.filter(x => x.column === queryParams.column),
          rowErrors: errArray.filter(x => x.column !== queryParams.column)
        }
      }
    });
  }

}
