import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromApp from "../../modules/calculation/store/calculation.reducers";
import { Observable, of } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import * as CalculationActions from "../../modules/calculation/store/calculation.actions";
import { Calculation } from "../models/calculation.model";

@Injectable()
export class CalculationsGuard implements CanActivate {
  constructor(private store: Store<fromApp.State>) {}

  /**
   * Returns the list of Calculations from the Store (if they exist) or from the API
   *
   * @returns {Observable<Calculation>}
   * @memberof CalculationsGuard
   */
  getFromStoreOrAPI(): Observable<Calculation> {
    return this.store.select("calculation").pipe(
      tap((data: any) => {
        if (!data || !data.calculation || !data.calculation.length) {
          this.store.dispatch(CalculationActions.fecthCalculation());
        }
      })
    );
  }

  /**
   * Checks if the route can be activated
   *
   * @returns {(Observable<boolean> | boolean)}
   * @memberof CalculationsGuard
   */
  canActivate(): Observable<boolean> | boolean {
    return this.getFromStoreOrAPI().pipe(switchMap(() => of(true)));
  }
}
