<div class="card core-card">
  <div class="card-header core-card-header">
    <button class="btn btn-link" data-toggle="collapse" data-target="#remove" aria-expanded="true"
      aria-controls="remove" data-cy="popupRemoveBtn">
      <em class="icon-equalizer"></em> Remove
    </button>
  </div>

  <div class="card-body core-card-body">
    <!-- Back Button-->
    <div class="row">
      <div class="col-8">
        <h4 mat-dialog-title>{{currentFile ? currentFile.type.subcategoryStr : ''}}</h4>
        <small>{{currentFile ? currentFile.filename: ''}}</small>
      </div>
      <div class="col-4 d-flex justify-content-end">
        <button mat-button class="back-button" value="back" [routerLink]=this.previousState><em
            class="fa fa-arrow-left"></em> Back
        </button>
      </div>

      <!-- Only show if all Datasets / Calculations have been deleted -->
      <div class="all-dataset-removed-msg" *ngIf="datasetsCount === 0 && !this.isLoading">
        <h4>All related Datasets / Calculations have been removed from the file. Do you want to delete it?</h4>
        <button class="btn btn-block btn-danger btn-sm mt-3" type="button" (click)="removeFile()"
          data-cy="removeFileBtn"><em class="fa fa-trash-o"></em>
          Yes, delete file</button>
      </div>
      <!-- Datasets Table-->
      <div *ngIf="dataSource && datasetsCount > 0" class="col-12 mt-5 table-container text-center">
        <!-- Table -->
        <h5 class="col-12 mt-5">Appears on {{this.datasetsCount}} Dataset(s)</h5>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 mb-4 text-left" matSort
          matSortDirection="desc" multiTemplateDataRows>

          <!-- Expand Column (to be used on the Rejected option) -->
          <ng-container matColumnDef="expandColumn">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell class="list-icon-cell" *matCellDef="let element">
              <button class="list-icon m-2" mat-button (click)="this.expandRow(element)" data-cy="expandDatasetBtn"><em
                  class="material-icons" [hidden]="element.in_results.length === 0">
                  {{expandedElement === element ? 'expand_less' : 'expand_more'}}
                </em></button>
            </td>
          </ng-container>

          <!-- Dataset Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.collection.col_name}} </td>
          </ng-container>

          <!-- Created Date Column -->
          <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef> Created Date </th>
            <td mat-cell *matCellDef="let element"> {{element.collection.uploaded_ts}} </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th class="actions-column" mat-header-cell *matHeaderCellDef> Actions </th>
            <td mat-cell *matCellDef="let element">
              <div class="row">
                <div class="col-12 mt-1 mb-1">
                  <button class="btn btn-block btn-danger btn-sm" type="button"
                    (click)="removeDatasetDiag(element.collection)" [disabled]="element.in_results.length > 0"
                    data-cy="removeDatasetBtn"><em class="fa fa-trash-o"></em>
                    Remove</button>
                </div>
              </div>
            </td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
          <tr *matRowDef="let row; columns: displayedColumns;" mat-row class="element-row"
            [class.expanded-row]="expandedElement === row">
          </tr>

          <!-- Only load and expandable Row if the load dataset is "Rejected" -->
          <tr mat-row *matRowDef="let element; columns: ['expandedDetail'];" class="detail-row"></tr>

          <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element; let i =index" [attr.colspan]="displayedColumns.length">
              <div class="element-detail text-center"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="row">
                  <h5 class="col-12 mt-3">Appears on {{element.in_results.length}} Calculation(s)</h5>
                  <table class="col-12 table table-results mt-1">
                    <thead class="thead-light">
                      <tr class="d-flex">
                        <th class="col-4" scope="col">Calculation Date</th>
                        <th class="col-4" scope="col">Reporting Date</th>
                        <th class="col-4 text-center" scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="d-flex" *ngFor="let result of element.in_results">
                        <td class="col-4">{{result.started_ts}}</td>
                        <td class="col-4">{{result.args.reporting_date}}</td>
                        <td class="col-4 align-middle">
                          <button class="btn btn-block btn-warning btn-sm align-middle details-btn" type="button"
                            [routerLink]="['/pages/calculation/details', result.uuid]"><em class="icon-grid"></em>
                            Details</button>

                          <button class="btn btn-block btn-danger btn-sm align-middle remove-btn" type="button"
                            (click)="removeCalcDiag(result)"><em class="fa fa-trash-o"
                              data-cy="removeCalculationBtn"></em>
                            Remove</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </ng-container>
        </table>
      </div>
    </div>
    <!-- SHOW SPINNER-->
    <div class="row" *ngIf="this.isLoading">
      <div class="col-12 text-center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row justify-content-end">
    </div>
  </div>
