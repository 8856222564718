import { MatTableDataSource } from '@angular/material/table';
import { Component } from '@angular/compiler/src/core';

export interface ITableListConfig {
    pageSize?: number;
    pageSizeOptions?: number[];
    columns: ITableListColumn[];
    state?: ITableListState;
    emptyMessage?: ITableListEmptyMessage;
    isRowHighlight?: (e: any) => boolean;
    hasPagination?: boolean;
    rowClick?: (r) => void;
    hasFilter?: boolean;
}

export interface ITableListDataSource<T> {
    data: MatTableDataSource<T>;
    loaded: boolean;
    hasNextPage?: boolean;
}

export const TableListConfigDefaults: ITableListConfig = {
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    columns: [],
    hasFilter: true,
    state: {
        limit: 10,
        offset: 0,
        sort: [],
        filter: []
    },
    emptyMessage: {
        title: "Nothing to show",
        desc: ""
    },
    hasPagination: true
}

export interface ITableListColumn {
    name: string,
    label: string,
    valueGetter: (row: any) => any,
    type: TableListColumnType
    cssClass?: string,
    actions?: any;
    expand?: ITableListExpandData
    minWidth?: string;
    maxWidth?: string;
    highlight?: boolean;
    noSort?: boolean;
    cellHighlight?: (row: any, i: number) => string
}

export enum TableListColumnType {
    String,
    Numeric,
    Date,
    Link,
    StatusIcon,
    Actions,
    DropdownActions,
    AnalyticStatus,
    ExpandableRow,
    Selection,
    DateTime
}

export interface ITableListAction {
    name: string,
    label: string,
    title?: (e: any) => string,
    icon: string,
    disabled?: (e: any) => boolean,
    click: (e: any) => void,
    cssClass?: string,
    intent?: string
}

export interface ITableListState {
    limit?: number;
    offset?: number
    sort?: ITableListOrder[];
    filter?: ITableListFilter[];
}

export interface ITableListOrder {
    by: string;
    dir: string;
}

export interface ITableListFilter {
    column?: string;
    value: string;
    exact: boolean;
}

export interface ITableListStateAction {
    type: String;
    payload: any;
}

export interface ITableListEmptyMessage {
    title: string;
    desc: string;
}

export interface ITableListExpandData {
    component: any
}