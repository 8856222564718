import { UploadStatus } from "./upload-status.model";
import { Observable } from "rxjs";
import { IAPIFile, IAPIAnalyticStatus } from './API.model';
import { importedStates, AnalyticStatus } from './common';

export class Metadata {
  constructor(
    public counterparties: number[],
    public countries: string[],
    public currencies: string[],
    public reporting_dates: string[]
  ) {}
}
export class File {
  public uuid: string;
  public importDate: string;
  public filename: string;
  public uploadStatus: IAPIAnalyticStatus;
  public canDelete: number = 1;
  public checked: number = 1;
  public domain: string;
  public type: FileType
  constructor(
    file: IAPIFile,
    fileType: FileType
  ) {
    this.uuid = file.uuid;
    this.importDate = file.created_ts;
    this.filename = file.filename;
    this.uploadStatus = {
      status: <AnalyticStatus>(file.validation_status ? file.validation_status : AnalyticStatus.PENDING),
      badRows: file.validation_result ? parseInt(file.validation_result.bad_rows) : 0,
      totalRows: file.validation_result ? parseInt(file.validation_result.total_rows) : 0,
    };
    this.canDelete= file.can_delete;
    this.checked = 1,
    this.domain = file.domain
    this.type = fileType;
  }
}

export class FileType {
  constructor(
    public categorySlug: string,
    public categoryStr: string,
    public subcategorySlug: string,
    public subcategoryStr: string,
    public order: number
  ) {}
}
