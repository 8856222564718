import { Component, Input, EventEmitter, Output } from "@angular/core";
import { DropdownOptions } from "../../models/common";

@Component({
  selector: "app-dropdown-options",
  templateUrl: "./dropdown-options.component.html",
  styleUrls: ["./dropdown-options.component.scss"]
})
export class DropdownOptionsComponent {
  @Input() options: DropdownOptions;
  @Input() element: any;
  @Output() option = new EventEmitter<string>();

  getFirstEnabledItem() {
    return this.options.items.filter(i => !i.isDisabled(this.element) && i.isVisible(this.element))[0];
  }

  /**
   * Emits an event when an options is clicked
   *
   * @param {string} optionSlug
   * @memberof DropdownOptionsComponent
   */
  clickedOption(optionSlug: string) {
    this.option.emit(optionSlug);
  }
}
