import { createAction, props } from "@ngrx/store";
import { Calculation } from "../../../shared/models/calculation.model";

export const setCalculation = createAction(
  "[Calculation] SET",
  props<{ calculation: Calculation[] }>()
);
export const addCalculation = createAction(
  "[Calculation] ADD",
  props<{ calculation: Calculation }>()
);
export const fecthCalculation = createAction("[Calculation] FETCH");
export const getCalculation = createAction(
  "[Calculation] GET",
  props<{ calculation: Calculation }>()
);
export const deleteCol = createAction(
  "[Calculation] DELETE",
  props<{ calculation: Calculation }>()
);
export const clearCalculation = createAction("[Calculation] CLEAR");
