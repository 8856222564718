/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list-remove-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/router";
import * as i9 from "./list-remove-dialog.component";
var styles_ListRemoveDialogComponent = [i0.styles];
var RenderType_ListRemoveDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListRemoveDialogComponent, data: {} });
export { RenderType_ListRemoveDialogComponent as RenderType_ListRemoveDialogComponent };
function View_ListRemoveDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_ListRemoveDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "alert alert-danger"], ["role", "danger"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "alert-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListRemoveDialogComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.errors; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ListRemoveDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["matdialogtitle", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "mat-dialog-content", [["class", "mt-3 mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListRemoveDialogComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 9, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "mat-raised-button mr-3 close-button"], ["matdialogclose", "cancel"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "em", [["class", "fa fa-close"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Close "])), (_l()(), i1.ɵeld(11, 0, null, null, 4, "button", [["class", "btn-success"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(13, 16384, null, 0, i8.RouterLink, [i8.Router, i8.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 0, "em", [["class", "fa fa-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, [" Solve"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.data.errors && (_co.data.errors.length > 0)); _ck(_v, 5, 0, currVal_1); var currVal_4 = _co.data.redirectRoute; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 1, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 12).disabled || null); var currVal_3 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_2, currVal_3); }); }
export function View_ListRemoveDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-remove-dialog", [], null, null, null, View_ListRemoveDialogComponent_0, RenderType_ListRemoveDialogComponent)), i1.ɵdid(1, 49152, null, 0, i9.ListRemoveDialogComponent, [i3.MatDialogRef, i3.MAT_DIALOG_DATA], null, null)], null, null); }
var ListRemoveDialogComponentNgFactory = i1.ɵccf("app-list-remove-dialog", i9.ListRemoveDialogComponent, View_ListRemoveDialogComponent_Host_0, {}, {}, []);
export { ListRemoveDialogComponentNgFactory as ListRemoveDialogComponentNgFactory };
