import { createAction, props } from "@ngrx/store";
import { File, FileType } from "../../../shared/models/file.model";

export const setFileTypes = createAction(
  "[Files] Set File Types",
  props<{ fileTypes: FileType[] }>()
);

export const fetchFiles = createAction("[Files] Fetch File");
export const fetchedFiles = createAction("[Files] Fetched Files");
export const fetchFileTypes = createAction("[Files] Fetch File Types");
