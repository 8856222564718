import { Component, Inject, Pipe, PipeTransform } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FileService } from "../../shared/file.service";

/**
 * Shows a Dialog with the File Template information
 *
 * @export
 * @class FileTemplateComponent
 */
@Component({
  selector: "app-file-template",
  templateUrl: "./file-template.component.html",
  styleUrls: ["./file-template.component.scss"]
})
export class FileTemplateComponent {
  constructor(
    private dialogRef: MatDialogRef<FileTemplateComponent>,
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      metadata: {
        headers: string[];
        rows: string[];
      };
      category: string;
      subCategory: string;
    }
  ) {}

  close() {
    this.dialogRef.close();
  }

  /**
   * Downloads a specific File Template
   *
   * @memberof FileTemplateComponent
   */
  downloadTemplate() {
    this.fileService.downloadTemplate(
      this.data.category,
      this.data.subCategory
    );
  }
}

@Pipe({ name: "convertYesNo" })
export class ConvertYesNoPipe implements PipeTransform {
  transform(value: boolean): string {
    return value === true ? "Y" : value === false ? "" : value;
  }
}
