<ng-container *ngIf="config && dataSource && dataSource.loaded; else loadingContainer">
    <div class="p-4" *ngIf="dataSource && dataSource.data.data.length > 0; else emptyContainer">
        <div class="row ml-2 mr-2 scrollable-pane">
            <mat-form-field *ngIf="config.hasFilter">
                <input matInput [formControl]="filterControl" placeholder="Filter" data-cy="tableFilter">
            </mat-form-field>
            <mat-checkbox *ngIf="config.hasFilter" [formControl]="filterMatchControl">Full match</mat-checkbox>
            <!-- <app-recipe-generator *ngIf="filterConfig" [config]="filterConfig"></app-recipe-generator> -->

            <table mat-table matSort multiTemplateDataRows
                [matSortActive]="config.state.sort && config.state.sort.length > 0 ? config.state.sort[0].by : ''"
                [matSortDirection]="config.state.sort && config.state.sort.length > 0 ? config.state.sort[0].dir : ''"
                (matSortChange)="sortChanged($event)" 
                [dataSource]="dataSource.data"
                class="mat-elevation-z2 mb-4" data-cy="tableList">

                <!-- COLUMN DEFINITIONS -->
                <ng-container *ngFor="let column of config.columns">
                    <ng-container matColumnDef="{{column.name}}">
                        <ng-container *ngIf="!column.noSort && column.type < 3;else noSort">
                            <th [class.highlight]="column.highlight" mat-header-cell
                                *matHeaderCellDef mat-sort-header>
                                {{ column.label }}
                            </th>
                        </ng-container>
                       <ng-template #noSort>
                            <th [class.highlight]="column.highlight" mat-header-cell *matHeaderCellDef>
                                {{ column.label }}
                            </th>
                       </ng-template>

                        <!-- COLUMN TEMPLATE -->
                        <ng-container [ngSwitch]="column.type">
                            <ng-container *ngSwitchCase="columnTypes.Link">
                                <td mat-cell *matCellDef="let element">
                                    <a [routerLink]="column.valueGetter(element).link">
                                        {{ column.valueGetter(element).label }}
                                        <small *ngIf="column.valueGetter(element).detail">
                                            {{ column.valueGetter(element).detail }}
                                        </small>
                                    </a>
                                </td>
                            </ng-container>
                            <ng-container *ngSwitchCase="columnTypes.ExpandableRow">
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-icon-button class="expand-button" aria-label="Expand Row" (click)="this.expandRow(element)">
                                                <mat-icon>{{ isElementExpanded(element) ? 'expand_less' : 'expand_more' }}</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                            <ng-container *ngSwitchCase="columnTypes.StatusIcon">
                                <td mat-cell *matCellDef="let element">
                                    <app-status-icon [importedStatus]="column.valueGetter(element)">
                                    </app-status-icon>
                                </td>
                            </ng-container>
                            <ng-container *ngSwitchCase="columnTypes.AnalyticStatus">
                                <td mat-cell *matCellDef="let element">
                                    <app-analytic-status [analyticStatus]=column.valueGetter(element)>
                                    </app-analytic-status>
                                </td>
                            </ng-container>
                            <ng-container *ngSwitchCase="columnTypes.Actions">
                                <td mat-cell *matCellDef="let element" [class.inline]="column.actions.inline">
                                    <ng-container *ngFor="let action of column.actions.list">
                                        <div
                                            [class]="action.cssClass ? 'table-actions ' + action.cssClass : 'table-actions'">
                                            <button mat-raised-button (click)="action.click(element)"
                                                [color]="action.intent ? action.intent : null"
                                                [title]="action.title ? action.title(element) : action.label"
                                                [disabled]="action.disabled ? action.disabled(element) : false">
                                                <ng-container *ngIf="action.icon">
                                                    <mat-icon>{{action.icon}}</mat-icon>
                                                </ng-container>
                                                {{action.label}}
                                            </button>
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container *ngSwitchCase="columnTypes.DropdownActions">
                                <td mat-cell *matCellDef="let element">
                                    <app-dropdown-options [options]="column.actions" [element]="element"
                                        (option)="column.valueGetter(element).optionClicked($event, element)">
                                    </app-dropdown-options>
                                </td>
                            </ng-container>
                            <ng-container *ngSwitchCase="columnTypes.Selection">
                                <td mat-cell *matCellDef="let element">
                                    <ng-container *ngFor="let selection of [isSelected(column.valueGetter(element))]">
                                        <mat-checkbox 
                                            color="selected"
                                            [(ngModel)]="selection"
                                            (click)="setSelection(column.valueGetter(element))"></mat-checkbox>
                                    </ng-container>
                                </td>
                            </ng-container>
                            <ng-container *ngSwitchCase="columnTypes.Numeric">
                                <td [ngStyle]="column.cellHighlight ? {backgroundColor: column.cellHighlight(element, idx)} : null"
                                    [style.min-width]="column.minWidth"
                                    [style.max-width]="column.maxWidth"
                                    [class.highlight]="column.highlight" mat-cell *matCellDef="let element;let idx = dataIndex;">
                                    <div [class]="column.cssClass ? column.cssClass : ''">
                                        <ng-container *ngIf="hasData(column.valueGetter(element)); else noNumber">
                                            {{ column.valueGetter(element) | number }}
                                        </ng-container>
                                        <ng-template #noNumber>
                                            {{ "" }}
                                        </ng-template>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container *ngSwitchCase="columnTypes.Date">
                                <td  [style.min-width]="column.minWidth" [style.max-width]="column.maxWidth" [class.highlight]="column.highlight" mat-cell *matCellDef="let element">
                                    <div [class]="column.cssClass ? column.cssClass : ''">
                                        <ng-container *ngIf="hasData(column.valueGetter(element)); else noDate">
                                            {{ column.valueGetter(element) === 'NaT' ? null : (column.valueGetter(element) + 'Z' | date:'yyyy-MM-dd') }}
                                        </ng-container>
                                        <ng-template #noDate>
                                            {{ "" }}
                                        </ng-template>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container *ngSwitchCase="columnTypes.DateTime">
                                <td  [style.min-width]="column.minWidth" [style.max-width]="column.maxWidth" [class.highlight]="column.highlight" mat-cell *matCellDef="let element">
                                    <div [class]="column.cssClass ? column.cssClass : ''">
                                        <ng-container *ngIf="hasData(column.valueGetter(element)); else noDate">
                                            {{ column.valueGetter(element) + 'Z' | date:'yyyy-MM-dd HH:mm' }}
                                        </ng-container>
                                        <ng-template #noDate>
                                            {{ "" }}
                                        </ng-template>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <td [ngStyle]="column.cellHighlight ? {backgroundColor: column.cellHighlight(element, idx)} : null"
                                    [style.min-width]="column.minWidth" [style.max-width]="column.maxWidth"
                                    [class.highlight]="column.highlight" mat-cell *matCellDef="let element;let idx = dataIndex;">
                                    <div [class]="column.cssClass ? column.cssClass : ''">
                                        {{ column.valueGetter(element) }}
                                    </div>
                                </td>
                            </ng-container>
                        </ng-container>
                        <!-- END OF COLUMN TEMPLATE-->

                    </ng-container>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                <tr mat-row
                    [class.highlight]="config.isRowHighlight && config.isRowHighlight(row)"
                    [class.selected]="isSelected(row.uuid)"
                    [class.clickable]="config.rowClick"
                    (click)="config.rowClick && config.rowClick(row)"
                    *matRowDef="let row; columns: tableColumns;"></tr>

                <!-- Only load and expandable Row if it's defined on schema. -->
                <div *ngIf="hasExpandableColumns">
                    <tr mat-row *matRowDef="let expandableDetails; columns: ['expandedDetail'];" class="detail-row"></tr>
                </div>
                <!-- Expandable row template -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element; let i =index"
                        [attr.colspan]="this.config.columns.length">
                        <div *ngIf="isElementExpanded(element)" class="element-detail" 
                            [@detailExpand]="isElementExpanded(element) ? 'expanded' : 'collapsed'">
                            <div #expandComponent></div>
                        </div>
                    </td>
                </ng-container>            

            </table>
        </div>
        <div class="paginator-container" *ngIf="config.hasPagination">
            <mat-paginator [pageSize]="state.value.limit" [pageIndex]="state.value.offset"
                [pageSizeOptions]="config.pageSizeOptions"
                [length]="dataSource.hasNextPage || state.value.offset > 0 ? 999999999 : dataSource.data.data.length"
                (page)="this.pagingChanged($event)" data-cy="tablePaginator">
            </mat-paginator>
        </div>
    </div>
    <ng-template #emptyContainer>
        <div class="table-loading-empty text-center" data-cy="emptyTableMsg">
            <h1>{{ config.emptyMessage.title }}</h1>
            <h4 *ngIf="config.emptyMessage.desc">{{ config.emptyMessage.desc }}</h4>
        </div>>
    </ng-template>
</ng-container>
<ng-template #loadingContainer>
    <div class="table-loading-empty">
        <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </div>
</ng-template>