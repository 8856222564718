export const navItems = [
  {
    name: "FILES",
    url: "/pages/files",
    icon: "icon-equalizer"
  },
  {
    name: "DATASETS",
    url: "/pages/datasets/list/collections",
    icon: "icon-calculator"
  },
  {
    name: "CALCULATION",
    url: "/pages/calculation/list/collections",
    icon: "icon-calculator"
  }
];
