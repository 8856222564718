<div class="card core-card">
  <div class="card-header core-card-header">
    <button class="btn btn-link" data-toggle="collapse" data-target="#calculation-list" aria-expanded="true"
      aria-controls="calculation-list">
      <em class="icon-equalizer"></em> Inspect
    </button>
  </div>
  <div id="calculation-list" class="collapse show" aria-labelledby="calculation-list-heading"
    data-parent="#calculation-list-accordion">
    <div class="card-body core-card-body">
      <div class="row">
        <div class="col-4">
          <h4 mat-dialog-title>{{currentSubCategory}}</h4>
          <small>{{currentFilename}}</small>
        </div>
        <div class="col-8 d-flex justify-content-end right-actions">
            <button mat-raised-button class="back" [routerLink]="this.previousState" data-cy="backBtn">
              <mat-icon>keyboard_backspace</mat-icon> Back
            </button>
            <ng-container *ngIf="!currentSource">
              <ng-container *ngFor="let navButton of currentLinks">
                  <button [ngClass]="navButton.tag.toLowerCase()"
                          mat-raised-button 
                          (click)="navigateToType(navButton.tag)"
                          data-cy="rawBtn">
                      <ng-container [ngSwitch]="navButton.tag">
                        <mat-icon *ngSwitchCase="'VALID'">check</mat-icon>
                        <mat-icon *ngSwitchCase="'REJECTED'">close</mat-icon>
                        <mat-icon *ngSwitchDefault>format_align_justify</mat-icon>
                        {{navButton.tag}}
                      </ng-container>
                  </button>
              </ng-container>
            </ng-container>
        </div>

      </div>
    </div>
    <app-table-list #table [dataSource]="dataSource" [config]="tableConfig"
      (tableStateChanged)="this.tableStateChanged($event)"></app-table-list>
  </div>
</div>
