import { Observable, Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { FileService } from "../../shared/file.service";
import { File } from "../../../../shared/models/file.model";
import { ConfirmDialogComponent } from "../../../../shared/components/confirm-dialog/confirm-dialog.component";
import { ListRemoveDialogComponent } from "../../../../shared/components/list-remove-dialog/list-remove-dialog.component";
import {
  paramImportTablesHeaders,
  importedStates,
  AnalyticStatus
} from "../../../../shared/models/common";
import { TableListColumnType, ITableListState, ITableListDataSource } from '../../../../shared/components/table-list/table-list.model';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IAPIFileList } from '../../../../shared/models/API.model';
import { environment } from '../../../../../environments/environment';
import tableChangesToAPIParams from '../../../../shared/components/table-list/table-changes-to-api';
import { Store } from '@ngrx/store';
import * as fromFiles from "../../store/file.reducers";
import { first } from 'rxjs/operators';

/**
 * Shows the List of Files, by the given category
 *
 * @export
 * @class ListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"]
})
export class ListComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() category: string;
  @Input() fileList: Observable<File[]>;
  dataSource: ITableListDataSource<File>;
  isLoaded = false;
  fileService$: Subscription;
  store$: Subscription;
  displayedColumns = paramImportTablesHeaders;
  _changes: ITableListState;
  reload$: Subscription;
  tableListConfig = {
    columns: [
      {
        name: "created_ts",
        label: "Import Date",
        valueGetter: (e) => e.importDate,
        type: TableListColumnType.DateTime,
        minWidth: "177px"
      },
      {
        name: "subcategory",
        label: "Type",
        valueGetter: (e) => e.type.subcategoryStr,
        type: TableListColumnType.String
      },
      {
        name: "filename",
        label: "Filename",
        valueGetter: (e) => e.filename,
        type: TableListColumnType.String,
        cssClass: "mat-column-filename pr-5",
        maxWidth: "300px"
      },
      {
        name: "status",
        label: "Status",
        valueGetter: (e) => e.uploadStatus,
        type: TableListColumnType.StatusIcon,
        cssClass: "mat-column-status"
      },
      {
        name: "actions",
        label: "Actions",
        valueGetter: (e) => e,
        type: TableListColumnType.Actions,
        actions: {
          list: [
            {
              name: "inspectData",
              label: "Inspect Data",
              icon: "table_rows",
              click: (e: any) => this.router.navigate(["pages", "files", "inspect", e.uuid, "raw"], {
                queryParams: {
                  subcategory: e.type.subcategoryStr,
                  filename: e.filename
                }
              }),
              intent: "success",
              cssClass: "full-width-buttons"
            },
            {
              name: "remove",
              title: (e) => e.canDelete === 0 ?
                'This item cannot be deleted since it was already used on an Analytic Result' :
                'Remove uploaded File',
              label: "Remove",
              icon: "delete_outline",
              click: (e: any) => this.openRemoveDialog(e),
              disabled: (e: any) => this.isDisabled(e) || e.canDelete === 0 || true,
              intent: "warn",
              cssClass: "full-width-buttons"
            }
          ]
        }
      },
    ],
    state: {
      sort: [{
        by: 'created_ts',
        dir: 'desc'
      }],
      limit: 5
    },
    emptyMessage: {
      title: "You haven't imported any File yet",
      desc: "Use the functionality above to import new Files"
    }
  }

  /**
   * Creates an instance of ListComponent.
   * @param {FileService} fileService
   * @param {MatDialog} dialog
   * @memberof ListComponent
   */
  constructor(
    public fileService: FileService,
    public dialog: MatDialog,
    public router: Router,
    private http: HttpClient,
    private store: Store<fromFiles.State>,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.reload$ = this.store.select(fromFiles.needsReload).subscribe(reload => {
      if (reload) {
        this.tableStateChanged(this._changes)
      }
    })
  }

  tableStateChanged(changes: ITableListState) {
    this._changes = changes;
    const params = tableChangesToAPIParams(
      {
        ...changes,
        filter: [].concat(changes.filter.map(f => {
          return {
            ...f,
            column: 'filename'
          }
        }), [{
          column: "category",
          value: this.category,
          exact: true
        },
        {
          column: "domain",
          value: "UPLOADS",
          exact: true
        }])
      }
    )

    this.store.select(fromFiles.getFileTypes).pipe(first()).subscribe(subcats => {
      this.http.get<IAPIFileList>(`${environment.api_route}files/ls`, {
        withCredentials: true,
        params: params
      }).pipe(first()).subscribe(res => {
        if (res.success) {
          let hasNextPage = false;
          if (res.result.length > changes.limit) {
            res.result.pop();
            hasNextPage = true;
          }
          this.dataSource = {
            data: new MatTableDataSource(res.result.map(file => new File(file, subcats.find(
              type => type.subcategorySlug === file.subcategory
            )))),
            loaded: true,
            hasNextPage: hasNextPage
          };
        } else {
          this.dataSource = {
            data: new MatTableDataSource([]),
            loaded: true
          }
        }
        setTimeout(() => this.changeDetection.detectChanges(), 0);
      });
    })
  }

  /**
   * Opens the removal confirm dialog
   *
   * @param {File} element
   * @memberof ListComponent
   */
  openRemoveDialog(element: File) {
    this.fileService.canRemove(element.uuid).subscribe(
      response => {
        if (response) {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: "350px",
            data: {
              title: "Remove Imported File",
              text: "Do you confirm the file removal?",
              btnOk: "Remove",
              btnCancel: "Cancel"
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            // confirmed removal
            if (result) {
              this.fileService.removeFile(element.uuid).subscribe();
            }
          });
        }
      },
      error => {
        this.dialog.open(ListRemoveDialogComponent, {
          data: {
            title: "Remove Imported File",
            uuid: element.uuid,
            errors: error.error.errors,
            redirectRoute: ["/pages/files/remove", element.uuid]
          }
        });
      }
    );
  }

  /**
   * If File has not yet been completly upload or processed,
   * the "Remove" and "Inspect" buttons on the list should be disabled
   * @param {File} element
   * @returns
   * @memberof ListComponent
   */
  isDisabled(element: File) {
    return (
      element.uploadStatus.status === AnalyticStatus.CREATED ||
      element.uploadStatus.status === AnalyticStatus.PENDING ||
      element.uploadStatus.status === AnalyticStatus.STARTED
    );
  }

  ngOnDestroy() {
    if (this.fileService$) {
      this.fileService$.unsubscribe();
      this.reload$.unsubscribe();
    }
  }
}
