import { Injectable } from "@angular/core";

const TOKEN = "TOKEN";

@Injectable({
  providedIn: "root"
})
export class UserService {
  /**
   * Returns the user token from local storage
   *
   * @returns {string}
   * @memberof UserService
   */
  getToken(): string {
    return localStorage.getItem(TOKEN);
  }

  /**
   * Sets the user token on local storage
   *
   * @param {string} token
   * @memberof UserService
   */
  setToken(token: string): void {
    localStorage.setItem(TOKEN, token);
  }

  /**
   * Deletes the user token from local storage
   *
   * @memberof UserService
   */
  deleteToken() {
    localStorage.removeItem(TOKEN);
  }

  /**
   * Checks if the user has a stored token
   *
   * @returns
   * @memberof UserService
   */
  isLogged() {
    return localStorage.getItem(TOKEN) != null;
  }
}
