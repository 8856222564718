import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromApp from "../../modules/dataset/store/dataset.reducers";
import { Observable, of } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import * as DatasetsActions from "../../modules/dataset/store/dataset.actions";
import { Dataset } from "../models/dataset.model";

@Injectable()
export class DatasetsGuard implements CanActivate {
  constructor(private store: Store<fromApp.State>) {}

  /**
   * Checks if the route can be activated
   *
   * @returns {(Observable<boolean> | boolean)}
   * @memberof DatasetsGuard
   */
  canActivate(): Observable<boolean> | boolean {
    return of(true);
  }
}
