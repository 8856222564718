import { Injectable } from "@angular/core";
import { Adapter } from "../adapter";

export class Notification {
  constructor(
    public id: number,
    public from_srv: string,
    public to_user_id: number,
    public payload: any,
    public sent_at: string,
    public acked_at: string,
    public data?: any
  ) {}
}

@Injectable({
  providedIn: "root"
})
export class NotificationAdapter {
  adapt(item: any[], headers: any[]): Notification {
    const newNotification = {};
    headers.forEach((h, i) => {
      newNotification[h] = item[i];
    });
    return <Notification>newNotification;
  }
}
