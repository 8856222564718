<div [ngSwitch]="status.status">
  <div class="succeeded-container" *ngSwitchCase=analiticStatus.SUCCEEDED>
      <div><em class="icon-check"></em></div>
      <div [class]="percentege === 100 ? 'percentege-full' : 'percentege'">{{percentege}}%</div>
  </div>
  <span *ngSwitchCase=analiticStatus.CANCELLED><em  matTooltip="{{status.status}}" class="icon-close"></em></span>
  <span *ngSwitchCase=analiticStatus.FAILED><em  matTooltip="{{status.status}}" class="icon-close"></em></span>
  <span *ngSwitchDefault>
      <mat-spinner [ngClass]="status.status" [diameter]="30" mode="indeterminate" value="50"></mat-spinner>
  </span>
</div>
