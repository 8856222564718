import * as fromFiles from "../../modules/file/store/file.reducers";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { analyticStates } from "./common";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
var Dataset = /** @class */ (function () {
    function Dataset(uuid, name, createdDate, inputFiles, paramFiles, validation, status) {
        this.uuid = uuid;
        this.name = name;
        this.createdDate = createdDate;
        this.inputFiles = inputFiles;
        this.paramFiles = paramFiles;
        this.validation = validation;
        this.status = status;
    }
    return Dataset;
}());
export { Dataset };
var DatasetAdapter = /** @class */ (function () {
    function DatasetAdapter(store) {
        this.store = store;
    }
    DatasetAdapter.prototype.adapt = function (item) {
        var inputFiles = [];
        var paramFiles = [];
        /** Parse the different analyses files (dataset, ingestion, shadowed) **/
        var datasetFiles = this.parseCalculationsFiles(item.validation_details.collection_analyses);
        var ingestionFiles = this.parseCalculationsFiles(item.validation_details.ingestion_analyses);
        var shadowedFiles = this.parseCalculationsFiles(item.validation_details.shadowed_analyses);
        /** Iterate over the Files store to find the matching Dataset Files */
        this.store
            .select(fromFiles.getFileState)
            .subscribe(function (state) {
            if (!state || !state.files) {
                return;
            }
            state.files.forEach(function (paramInput) {
                var inputKeys = Object.keys(item.args.input_uuids);
                var paramKeys = Object.keys(item.args.param_uuids);
                inputKeys.forEach(function (key) {
                    item.args.input_uuids[key].forEach(function (uuid) {
                        if (paramInput.uuid === uuid) {
                            // Avoiding duplicates
                            if (!inputFiles.find(function (file) { return file.uuid === paramInput.uuid; })) {
                                inputFiles.push(paramInput);
                            }
                        }
                    });
                });
                paramKeys.forEach(function (key) {
                    item.args.param_uuids[key].forEach(function (uuid) {
                        if (paramInput.uuid === uuid) {
                            // Avoiding duplicates
                            if (!paramFiles.find(function (file) { return file.uuid === paramInput.uuid; })) {
                                paramFiles.push(paramInput);
                            }
                        }
                    });
                });
            });
        });
        return new Dataset(item.uuid, item.col_name, item.uploaded_ts, of(inputFiles), of(paramFiles), {
            status: item.validation_details.status,
            datasetFiles: datasetFiles,
            ingestionFiles: this.combineSortIngestionShadowed(ingestionFiles, shadowedFiles)
        }, item.processed_ts
            ? this.getStatus(item.validation_details.status)
            : analyticStates.started);
    };
    DatasetAdapter.prototype.getStatus = function (status) {
        switch (status) {
            case "SUCCESS":
                return analyticStates.succeeded;
            case "FAILURE":
                return analyticStates.failed;
        }
    };
    DatasetAdapter.prototype.parseCalculationsFiles = function (analyses) {
        var _this = this;
        var parsedCalculations = [];
        Object.keys(analyses).forEach(function (key) {
            // we need to get the FileType Object from the store to determine the subcategory string
            _this.store
                .select(fromFiles.getFileTypeBySubcategory(analyses[key].subcategory))
                .subscribe(function (type) {
                parsedCalculations.push({
                    badRows: analyses[key].bad_rows,
                    category: analyses[key].category,
                    filename: analyses[key].filename,
                    status: analyses[key].status,
                    subcategory: type.subcategoryStr,
                    totalRows: analyses[key].total_rows,
                    goodRows: analyses[key].total_rows - analyses[key].bad_rows,
                    uuid: analyses[key].uuid
                });
            });
        });
        // Sorts the arr by category 'input' and subcategory DESC
        parsedCalculations.sort(function (a, b) { return (a.subcategory < b.subcategory ? 1 : -1); });
        parsedCalculations.sort(function (a, b) { return (a.category === "input" ? -1 : 1); });
        return parsedCalculations;
    };
    DatasetAdapter.prototype.combineSortIngestionShadowed = function (ingestionFiles, shadowedFiles) {
        Array.prototype.push.apply(ingestionFiles, shadowedFiles);
        // Sorts the arr by category 'input' and subcategory DESC
        ingestionFiles.sort(function (a, b) { return (a.subcategory < b.subcategory ? 1 : -1); });
        return ingestionFiles.sort(function (a, b) { return (a.category === "input" ? -1 : 1); });
    };
    DatasetAdapter.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DatasetAdapter_Factory() { return new DatasetAdapter(i0.ɵɵinject(i1.Store)); }, token: DatasetAdapter, providedIn: "root" });
    return DatasetAdapter;
}());
export { DatasetAdapter };
