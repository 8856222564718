import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { FileRoutingModule } from "./file-routing.module";
import { MaterialModule } from "../../material.module";
import { UploadComponent } from "./components/upload/upload.component";
import { ListComponent } from "./components/list/list.component";
import { FileComponent } from "./file.component";
import { reducer } from "./store/file.reducers";
import { FileEffects } from "./store/file.effects";
import {
  FileTemplateComponent,
  ConvertYesNoPipe
} from "./components/file-template/file-template.component";
import { InspectComponent } from "./components/inspect/inspect.component";
import { NgxDropzoneModule } from "ngx-dropzone";
import { RemoveComponent } from "./components/remove/remove.component";
import { ListRemoveDialogComponent } from "../../shared/components/list-remove-dialog/list-remove-dialog.component";
import { SharedModule } from "../../shared/components/shared.module";
import { FileErrorComponent } from './components/inspect/file-error/file-error.component';

@NgModule({
  declarations: [
    FileComponent,
    UploadComponent,
    ListComponent,
    UploadComponent,
    FileTemplateComponent,
    ConvertYesNoPipe,
    InspectComponent,
    RemoveComponent,
    FileErrorComponent
  ],
  imports: [
    CommonModule,
    FileRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    SharedModule,
    StoreModule.forFeature("files", reducer),
    EffectsModule.forFeature([FileEffects])
  ],
  entryComponents: [FileTemplateComponent, ListRemoveDialogComponent, FileErrorComponent],
  exports: []
})
export class FileModule {}
