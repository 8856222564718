/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/table-list/table-list.component.ngfactory";
import * as i3 from "../../../../shared/components/table-list/table-list.component";
import * as i4 from "./list.component";
import * as i5 from "../../shared/file.service";
import * as i6 from "@angular/material/dialog";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common/http";
import * as i9 from "@ngrx/store";
var styles_ListComponent = [i0.styles];
var RenderType_ListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListComponent, data: {} });
export { RenderType_ListComponent as RenderType_ListComponent };
export function View_ListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "animated fadeIn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "card core-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "card-header core-card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "ml-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "em", [["class", "icon-equalizer"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" Imported ", " Files"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "card-body core-card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-table-list", [], null, [[null, "tableStateChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tableStateChanged" === en)) {
        var pd_0 = (_co.tableStateChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TableListComponent_0, i2.RenderType_TableListComponent)), i1.ɵdid(8, 770048, null, 0, i3.TableListComponent, [i1.ComponentFactoryResolver, i1.ChangeDetectorRef], { dataSource: [0, "dataSource"], config: [1, "config"] }, { tableStateChanged: "tableStateChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.dataSource; var currVal_2 = _co.tableListConfig; _ck(_v, 8, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 5, 0, currVal_0); }); }
export function View_ListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list", [], null, null, null, View_ListComponent_0, RenderType_ListComponent)), i1.ɵdid(1, 245760, null, 0, i4.ListComponent, [i5.FileService, i6.MatDialog, i7.Router, i8.HttpClient, i9.Store, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListComponentNgFactory = i1.ɵccf("app-list", i4.ListComponent, View_ListComponent_Host_0, { title: "title", category: "category", fileList: "fileList" }, {}, []);
export { ListComponentNgFactory as ListComponentNgFactory };
