import * as tslib_1 from "tslib";
import { EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFormControlComponent, DynamicFormControlCustomEvent, DynamicFormLayoutService, DynamicFormValidationService, DynamicFormControlLayout, } from "@ng-dynamic-forms/core";
import { DynamicSelectFromTableModel } from './select-from-table-model';
var SelectFromTableFormControlComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SelectFromTableFormControlComponent, _super);
    function SelectFromTableFormControlComponent(layoutService, validationService) {
        var _this = _super.call(this, layoutService, validationService) || this;
        _this.layoutService = layoutService;
        _this.validationService = validationService;
        _this.blur = new EventEmitter();
        _this.change = new EventEmitter();
        _this.customEvent = new EventEmitter();
        _this.focus = new EventEmitter();
        return _this;
    }
    return SelectFromTableFormControlComponent;
}(DynamicFormControlComponent));
export { SelectFromTableFormControlComponent };
