import * as i0 from "@angular/core";
var Notification = /** @class */ (function () {
    function Notification(id, from_srv, to_user_id, payload, sent_at, acked_at, data) {
        this.id = id;
        this.from_srv = from_srv;
        this.to_user_id = to_user_id;
        this.payload = payload;
        this.sent_at = sent_at;
        this.acked_at = acked_at;
        this.data = data;
    }
    return Notification;
}());
export { Notification };
var NotificationAdapter = /** @class */ (function () {
    function NotificationAdapter() {
    }
    NotificationAdapter.prototype.adapt = function (item, headers) {
        var newNotification = {};
        headers.forEach(function (h, i) {
            newNotification[h] = item[i];
        });
        return newNotification;
    };
    NotificationAdapter.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationAdapter_Factory() { return new NotificationAdapter(); }, token: NotificationAdapter, providedIn: "root" });
    return NotificationAdapter;
}());
export { NotificationAdapter };
