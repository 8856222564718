import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ICalculationAPIResult } from './API.model';
import { objectTypeAnnotation } from '@babel/types';

export class Calculation {
  constructor(
    public uuid: string,
    public started: string,
    public finished: string,
    public status: number,
    public args: { input_uuids: string[]; param_uuids: string[] },
    public colName: string,
    public colCreatedDate: string,
    public countryCCY: Observable<string>,
    public reportId: string,
    public result?: Result
  ) {}
}

export class Result {
  public dashboard: Dashboard;
  public chartBL: BehaviorSubject<Chart>; // Business Mapping Chart
  public chartCntrpty: BehaviorSubject<Chart>; // Counterparty Chart
  public chartRegGrp: BehaviorSubject<Chart>; // Regulatory Group Chart
  public chartRegSubGrp: BehaviorSubject<Chart>; // Regulatory Sub Group Chart
  public chartCountry: BehaviorSubject<Chart>; // Country Chart
  public tableBL: BehaviorSubject<Table>; // Business Mapping Table
  public tableCntrpty: BehaviorSubject<Table>; // Counterparty Table
  public tableRegGrp: BehaviorSubject<Table>; // Regulatory Group Table
  public tableCountry: BehaviorSubject<Table>; // Country Table
  public tableRaw: BehaviorSubject<Table> | null; // Raw Table
  public filterBL: BehaviorSubject<string[]>; // BL Filters
  public metaBL?: Meta; // Business Mapping Metadata
  public metaCntrpty?: Meta; // Counterparty Metadata
  public metaRegGrp?: Meta; // Regulatory Group Metadata
  public metaRegSubGrp?: Meta; // Regulatory Sub Group Metadata
  public metaCountry?: Meta; // Country Metadata

  constructor(
    dashboard: Dashboard,
    chartBL: BehaviorSubject<Chart>,
    chartCntrpty: BehaviorSubject<Chart>,
    chartRegGrp: BehaviorSubject<Chart>,
    chartRegSubGrp: BehaviorSubject<Chart>,
    chartCountry: BehaviorSubject<Chart>,
    tableBL: BehaviorSubject<Table>,
    tableCntrpty: BehaviorSubject<Table>,
    tableRegGrp: BehaviorSubject<Table>,
    tableCountry: BehaviorSubject<Table>,
    tableRaw: BehaviorSubject<Table>,
    filterBL: BehaviorSubject<string[]>
  ) {
    this.dashboard = dashboard;
    this.chartBL = chartBL;
    this.chartCntrpty = chartCntrpty;
    this.chartRegGrp = chartRegGrp;
    this.chartRegSubGrp = chartRegSubGrp;
    this.chartCountry = chartCountry;
    this.tableBL = tableBL;
    this.tableCntrpty = tableCntrpty;
    this.tableRegGrp = tableRegGrp;
    this.tableCountry = tableCountry;
    this.tableRaw = tableRaw;
    this.filterBL = filterBL;
    this.metaBL = {
      id: "chartBusinessMapping",
      title: "RANKING BY BUSINESS LINE",
      label: "Business Line value"
    };
    this.metaCntrpty = {
      id: "chartCounterparty",
      title: "RANKING BY COUNTERPARTY",
      label: "Counterparty value"
    };
    this.metaRegGrp = {
      id: "chartRegulatoryGroup",
      title: "RANKING BY REGULATORY GROUP",
      label: "Regulatory Group value"
    };
    this.metaRegSubGrp = {
      id: "chartRegulatorySubGroup",
      title: "RANKING BY REGULATORY GROUP",
      label: "Regulatory Group value"
    };
    this.metaCountry = {
      id: "chartCountry",
      title: "RANKING BY COUNTRY",
      label: "Population (millions)"
    };
  }
}

export class Dashboard {
  public presentCurrency: string;
  public reportingDate: string;
  public calculationAmount: number;
  public eadAmount: number;

  constructor(
    presentCurrency: string,
    reportingDate: string,
    calculationAmount: number,
    eadAmount: number
  ) {
    this.presentCurrency = presentCurrency;
    this.reportingDate = reportingDate;
    this.calculationAmount = calculationAmount;
    this.eadAmount = eadAmount;
  }
}

export class Chart {
  public labels: any[];
  public rows: any[];

  constructor(labels: any[], rows: any[]) {
    this.labels = labels;
    this.rows = rows;
  }
}

export class Table {
  public headers: any[];
  public headersLong: any[];
  public rows: any[];
  public types: any[];

  constructor(headers: any[], headersLong: any[], rows: any[], types: any[]) {
    this.headers = headers;
    this.headersLong = headersLong;
    this.rows = rows;
    this.rows = rows;
    this.types = types;
  }
}

export class Meta {
  public id: string;
  public title: string;
  public label: string;
}

export class MarketRiskResult {
  public ir_specific: BehaviorSubject<Table> = new BehaviorSubject<Table>(null);
  public fe_charge: BehaviorSubject<Table> = new BehaviorSubject<Table>(null);
  public fet_charge: BehaviorSubject<Table> = new BehaviorSubject<Table>(null);
  public eq_charge: BehaviorSubject<Table> = new BehaviorSubject<Table>(null);
  public co_charge: BehaviorSubject<Table> = new BehaviorSubject<Table>(null);
  public cot_charge: BehaviorSubject<Table> = new BehaviorSubject<Table>(null);
  public ir_general: any = {};

  constructor(calc: ICalculationAPIResult) {
    this.ir_specific.next(this.convertToTable(calc.market_risk['market_risk/csv/IR_SPECIFIC.csv']));
    this.fe_charge.next(this.convertToTable(calc.market_risk['market_risk/csv/FE_CHARGE.csv']));
    this.fet_charge.next(this.convertToTable(calc.market_risk['market_risk/csv/FE_CHARGE_TOTAL.csv']));
    this.eq_charge.next(this.convertToTable(calc.market_risk['market_risk/csv/EQ_CHARGE.csv']));
    this.co_charge.next(this.convertToTable(calc.market_risk['market_risk/csv/CO_CHARGE.csv']));
    this.cot_charge.next(this.convertToTable(calc.market_risk['market_risk/csv/CO_CHARGE_TOTAL.csv']));

    Object.keys(calc.market_risk).filter(x => x.startsWith('market_risk/csv/ir_generic/')).forEach(key => {
      const [currency, file] = key.replace('market_risk/csv/ir_generic/', '').split('/');
      if (!this.ir_general[currency]) {
        this.ir_general[currency] = {}
      }
      this.ir_general[currency][file] = new BehaviorSubject(
        this.convertToTable(calc.market_risk[key])
      )
    });
  }

  protected convertToTable(data: any) {
    const dataRows: Table = this.filterByHeadersToAvoid(data, [
      "SUM_RWA, SUM_EAD_AMOUNT"
    ]);

    dataRows.headersLong = data.headers;
    dataRows.headers = data.headers_print;

    // add the types
    dataRows.types = data.dtypes;

    return dataRows;
  }

  protected filterByHeadersToAvoid(data: any, headersToAvoid: string[]) {
    // will store the rows in {column: value} manner
    const dataRows = new Table([], [], [], []);
    data.rows.forEach(row => {
      const newLine = {};
      for (let i = 0; i < data.headers.length; i++) {
        if (!headersToAvoid.includes(data.headers[i])) {
          newLine[data.headers_print[i]] = row[i];
        }
      }
      dataRows.rows.push(newLine);
    });

    return dataRows;
  }
}

@Injectable({
  providedIn: "root"
})
export class CreditRiskResultAdapter {
  adapt(item: ICalculationAPIResult, useRAW: boolean): Result {
    let result: Result;
    const chartBL$ = new BehaviorSubject(null);
    const chartCntrpty$ = new BehaviorSubject(null);
    const chartRegGrp$ = new BehaviorSubject(null);
    const chartRegSubGrp$ = new BehaviorSubject(null);
    const chartCountry$ = new BehaviorSubject(null);
    const tableBL$ = new BehaviorSubject(null);
    const tableCntrpty$ = new BehaviorSubject(null);
    const tableRegGrp$ = new BehaviorSubject(null);
    const tableCountry$ = new BehaviorSubject(null);
    const filterBL$ = new BehaviorSubject(null);

    // create Dashboard data
    const dashboard = new Dashboard(
      item.job_metadata.credit_risk.local_ccy,
      item.calculation_details.args.reporting_date,
      Number(item.credit_risk.totals.RWA.toFixed()),
      Number(item.credit_risk.totals.EAD_AMOUNT.toFixed())
    );

    // create Chart data
    chartBL$.next(
      this.convertToChart(item.credit_risk.by_business_line, "BUSINESS LINE")
    );

    chartCntrpty$.next(
      this.convertToChart(
        item.credit_risk.by_counterparty_tranche,
        "COUNTERPARTY TRANCHE"
      )
    );

    chartCountry$.next(this.convertToChart(item.credit_risk.by_country, "COUNTRY"));

    // create Table data
    tableBL$.next(this.convertToTable(item.credit_risk.by_business_line));
    tableCntrpty$.next(this.convertToTable(item.credit_risk.by_counterparty_tranche));
    tableCountry$.next(this.convertToTable(item.credit_risk.by_country));

    // create Regulatory Group Tables and Charts
    if (item.credit_risk.by_regulatory_group_long_name_tranche) {
      chartRegGrp$.next(
        this.convertToChart(
          item.credit_risk.by_regulatory_group_long_name_tranche,
          "REGULATORY GROUP LONG NAME TRANCHE"
        )
      );
      tableRegGrp$.next(
        this.convertToTable(item.credit_risk.by_regulatory_group_long_name_tranche)
      );
    }

    // create BL filter list
    filterBL$.next(
      this.getBusinessMappingFilters(item.credit_risk.by_business_line.rows)
    );

    // create the Result
    result = new Result(
      dashboard,
      chartBL$,
      chartCntrpty$,
      chartRegGrp$,
      chartRegSubGrp$,
      chartCountry$,
      tableBL$,
      tableCntrpty$,
      tableRegGrp$,
      tableCountry$,
      this.generateRAWTable(item, useRAW),
      filterBL$
    );

    return result;
  }

  private generateRAWTable(item: ICalculationAPIResult, useRAW: boolean) {
    return useRAW
      ? new BehaviorSubject(this.convertToTable(item.credit_risk.by_regulatory_group_long_name_tranche))
      : null;
  }

  protected convertToTable(data: any) {
    const dataRows: Table = this.filterByHeadersToAvoid(data, [
      "SUM_RWA, SUM_EAD_TRANCHE"
    ]);

    dataRows.headersLong = data.headers;
    dataRows.headers = data.headers_print;

    // add the types
    dataRows.types = data.dtypes;

    return dataRows;
  }

  protected convertToChart(data: any, rowId: string) {
    const chart = new Chart([], []);
    const dataRows: Table = this.filterByHeadersToAvoid(data, []);

    dataRows.rows.forEach(row => {
      chart.labels.push(row[rowId]);
      chart.rows.push(row['SUM RWA']);
    });

    return chart;
  }

  protected getBusinessMappingFilters(data: string[]) {
    const filters: string[] = [];
    data.forEach(row => filters.push(row[0]));
    return filters;
  }

  protected filterByHeadersToAvoid(data: any, headersToAvoid: string[]) {
    // will store the rows in {column: value} manner
    const dataRows = new Table([], [], [], []);
    data.rows.forEach(row => {
      const newLine = {};
      for (let i = 0; i < data.headers.length; i++) {
        if (!headersToAvoid.includes(data.headers[i])) {
          newLine[data.headers_print[i]] = row[i];
        }
      }
      dataRows.rows.push(newLine);
    });

    return dataRows;
  }
}
